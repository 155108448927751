import React from "react";
import {motion} from 'framer-motion';
import {styled} from '@mui/material/styles';
import {useSnackbar} from "notistack";
// import {useNavigate} from "react-router-dom";
import {Stack, Container, Typography, Card} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {MHidden} from '../external/minimal/components/@material-extend';
import {MotionContainer, varBounceIn} from '../external/minimal/components/animate';
import Page from '../components/Page';
import LogoWithText from '../components/LogoWithText';
import {useLogoutMutation} from "../api/User/User";
// import {PATH_DASHBOARD} from "../routes/path";


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));


const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: '90%',
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

const Page403: React.FC = () => {
  // const { method } = useAuth();
  const {enqueueSnackbar} = useSnackbar();
  // const [skipGetUserReq, setSkipGetUserReq] = useState(true)
  // const navigate = useNavigate();
  const [
    logoutRequest,
    {isLoading: isLogoutLoading}
  ] = useLogoutMutation()
  // const {
  //   refetch: refetchUserData
  // } = useGetUserQuery(
  //   undefined,
  //   {
  //     refetchOnMountOrArgChange: false,
  //     skip: skipGetUserReq,
  //   }
  // )

  const handleButtonClick = async () => {
    const returnedFromAPIVal = await logoutRequest()
    console.log('logout.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      enqueueSnackbar('Произошла ошибка при выходе', {variant: 'error'});
      console.log('logout.onSubmit.error')
    } else {
      console.log('logout.onSubmit.success')
      // await setSkipGetUserReq(false)
      // await refetchUserData()
      // navigate(PATH_DASHBOARD.general.dashboard, {replace: true})
      document.location.reload()
    }
  }

  return (
    <RootStyle title="403">
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            {/*<img src="/green_logo_netegis.png" width="95%" alt="netegis logo"/>*/}
            <LogoWithText sx={{width: '95%'}}/>
          </Stack>

        </SectionStyle>
      </MHidden>


      <Container maxWidth="lg">
        <MotionContainer initial="initial" open>
          <ContentStyle>
            <MHidden width="mdUp">
              <Stack direction="row" alignItems="center" justifyContent="center">
                <LogoWithText sx={{width: '80%', mb: 3}}/>
              </Stack>
            </MHidden>
            <Container sx={{width: 1}}>
              <Card sx={{p: 3}}>
                <Stack direction="column" alignItems="center" justifyContent="center" sx={{mb: 0, width: 1}}>
                  <motion.div variants={varBounceIn}>
                    <Typography variant="body1" sx={{mb: 1, width: 1}}>
                      Данная страница вам недоступна, вы пытаетесь войти в Личный кабинет Клиента с учетными данными
                      Партнера.
                    </Typography>
                    <Typography variant="body1" sx={{mb: 1, width: 1}}>
                      Хотите авторизоваться как Клиент?
                    </Typography>
                  </motion.div>
                  <LoadingButton loading={isLogoutLoading} sx={{textTransform: 'none'}} onClick={handleButtonClick}
                          size="medium"
                          variant="contained">
                    Авторизоваться как Клиент
                  </LoadingButton>
                </Stack>
              </Card>
            </Container>
          </ContentStyle>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
export default Page403
