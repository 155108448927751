import React from "react";
import {styled} from '@mui/material/styles';
import {Box, Stack, Container, Typography, Card} from '@mui/material';
import Page from '../../../components/Page';
import {MHidden} from '../../../external/minimal/components/@material-extend';
import LoginForm from './LoginForm';
import LogoWithText from '../../../components/LogoWithText';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));


const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

const Login: React.FC = () => {
  // const { method } = useAuth();

  return (
    <RootStyle title="Вход">
      <MHidden width="mdDown">
        <SectionStyle>
          {/*<Typography variant="h3" sx={{px: 5, mt: 10, mb: 5}}>*/}
          {/*  [NÉTEGIS]*/}
          {/*</Typography>*/}
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            {/*<img src="/green_logo_netegis.png" width="95%" alt="netegis logo"/>*/}
            <LogoWithText sx={{width: '95%'}}/>
          </Stack>

        </SectionStyle>
      </MHidden>


      <Container maxWidth="sm">
        <ContentStyle>
          <MHidden width="mdUp">
            <Stack direction="row" alignItems="center" justifyContent="center">
              <LogoWithText sx={{width: '80%', mb: 3}}/>
            </Stack>
          </MHidden>
          <Container maxWidth="sm">
            <Stack direction="row" alignItems="center" sx={{mb: 2}}>
              <Box sx={{flexGrow: 1}}>
                <Typography variant="h4" gutterBottom>
                  Вход
                </Typography>
                {/*<Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>*/}
              </Box>
            </Stack>

            <LoginForm/>
          </Container>


        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
export default Login
