import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField} from "mui-rff";
import * as Yup from 'yup';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Stack,
  Box, Alert
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from "notistack";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../defs/forms";
import {FieldName, FormData} from "../../../containers/Auth/OTPEnter/types";
import {canStepNextInWizard} from "../../../utils/forms";
import {useOtpCodeEnterMutation} from "../../../api/User/User";
import {PATH_DASHBOARD} from '../../../routes/path'
import {getErrorMsgByErrorForEnterOTPCode} from "../../../containers/Auth/utils";
import GoBackLink from "../../../components/GoBackLink";


Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.code]: Yup.string().required()
})

const validate = makeValidate(schema)
const required = makeRequired(schema)

const OTPEnterForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [
    otpCodeEnter,
    {
      error: otpCodeEnterError,
      isError: otpCodeEnterIsError,
      isLoading: otpCodeEnterIsLoading
    }
  ] = useOtpCodeEnterMutation()
  const navigate = useNavigate();
  const [isEnteredOtpValid, setIsEnteredOtpValid] = useState(true);

  const onSubmit =  async (values: FormData) => {
    setIsEnteredOtpValid(true)
    // navigate(PATH_DASHBOARD.general.dashboard, {replace: false})
    console.log('otpCodeEnter req')
    // navigate(PATH_AUTH.otpEnter, { replace: true })
    console.log('otpCodeEnter.onSubmit', values)
    // otpCodeEnter(values).then((val) => {
    //   console.log('otpCodeEnter.obSubmit.result', val)
    //   if ('error' in val) {
    //     console.log('otpCodeEnter.onSubmit.error')
    //   } else {
    //     console.log('otpCodeEnter.onSubmit.success')
    //     if (val.data.data.valid === false) {
    //       setIsEnteredOtpValid(false)
    //     } else {
    //       enqueueSnackbar('Учетная запись успешно подтверждена. Войдите используя свой Email и пароль', {variant: 'success', autoHideDuration: 5000});
    //       navigate(PATH_DASHBOARD.general.dashboard, {replace: false})
    //     }
    //   }
    // })
    const returnedFromAPIVal = await otpCodeEnter(values);
    console.log('otpCodeEnter.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      console.log('otpCodeEnter.onSubmit.error')
    } else {
      console.log('otpCodeEnter.onSubmit.success')
      if (returnedFromAPIVal.data.data.valid === false) {
        setIsEnteredOtpValid(false)
      } else {
        enqueueSnackbar('Учетная запись успешно подтверждена. Войдите используя свой Email и пароль', {
          variant: 'success',
          autoHideDuration: 5000
        });
        navigate(PATH_DASHBOARD.general.dashboard, {replace: false})
      }
    }
  }

  const fields = [
    <TextField
      fullWidth
      label='Код'
      name={FieldName.code}
      required={required.code}
      size='small'
      showError={showErrorOnBlur}
    />,
  ]

  return <Form<FormData>
    onSubmit={onSubmit}
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      return <form
        onSubmit={props.handleSubmit}
        noValidate
      >
        <div>
          <Stack spacing={3}>
            {(otpCodeEnterError || otpCodeEnterIsError) && <Alert severity="error">{getErrorMsgByErrorForEnterOTPCode(otpCodeEnterError)}</Alert>}
            {!isEnteredOtpValid && <Alert severity="error">Введен неверный код</Alert>}
            {fields.map((field) => (
              <div
                key={field.props.name}
                // xs={12}
              >
                {field}
              </div>
            ))}
          </Stack>
        </div>
        <Box  sx={{my: 2}}>
          <GoBackLink/>
        </Box>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={otpCodeEnterIsLoading}
          disabled={
            !canStepNextInWizard(props)
          }

        >
          Ввести код
        </LoadingButton>
      </form>
    }}
  />

}
export default OTPEnterForm
