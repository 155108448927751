import React, {useState} from "react";
import {Skeleton, Grid, Box, Typography, Card} from "@mui/material";

import {useGetDashboardDataCpesQuery} from 'src/api/ClientCpe/ClientCpe'
import OpenStreetMapCard from "src/components/Maps/OpenStreetMapCard/OpenStreetMapCard";

import {StatusesOfDevices} from "../Devices/types";
import CpeStatusCard from "./CpeStatusCard/CpeStatusCard";

interface ICpeCardProps {

}

const CpeCard: React.FC<ICpeCardProps> = () => {

  const {
    data,
    error,
    isFetching,
    isError,
  } = useGetDashboardDataCpesQuery(
    undefined,
    {refetchOnMountOrArgChange: true},
  )
  const statisticsCpes = data?.data

  const [selectedTypeOfDevicesStatus, setSelectedTypeOfDevicesStatus] = useState<StatusesOfDevices>(StatusesOfDevices.ALL);
  const [selectedCardType, setSelectedCardType] = useState<StatusesOfDevices>();

  const selectTypeOfDevicesStatusCallback = (typeOfSelectedTypeOfDevicesStatus: StatusesOfDevices) => {
    if (typeOfSelectedTypeOfDevicesStatus === selectedTypeOfDevicesStatus || typeOfSelectedTypeOfDevicesStatus === undefined) {
      setSelectedTypeOfDevicesStatus(StatusesOfDevices.ALL)
    } else {
      setSelectedTypeOfDevicesStatus(typeOfSelectedTypeOfDevicesStatus)
    }
  }

  const cardClickHandler = (typeOfClickedCard: StatusesOfDevices)=>{
    if (typeOfClickedCard === selectedCardType) {
      setSelectedCardType(undefined)
      //@ts-ignore
      selectTypeOfDevicesStatusCallback(undefined)
    } else {
      setSelectedCardType(typeOfClickedCard)
      selectTypeOfDevicesStatusCallback (typeOfClickedCard)
    }
  }

  return (
    <>
      {
        isError
          ? <Box>Произошла ошибка загрузки данных устройств: <pre>{JSON.stringify(error)}</pre></Box>
          : <Card sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div style={{display:"flex"}}>
                    <Typography variant="h4">
                      Устройства&nbsp;
                    </Typography>
                    <Typography variant="h4" sx={{ color: 'text.secondary'}}>
                      {
                        isFetching
                            ? <Skeleton variant='text' width='80%' style={{minWidth:'100px'}}/>
                            : <div style={{fontWeight: 700}}>
                              {statisticsCpes?.all}
                            </div>
                      }
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} container spacing={3}>
                  <Grid item xs={12} md={8} style={{minHeight: '350px'}}>
                    <OpenStreetMapCard showLocationsWithNoCpesAsAllOk />
                  </Grid>
                  <Grid item container xs={12} md={4} spacing={3}>
                    <Grid item xs={12}>
                      <CpeStatusCard clicked={selectedCardType === StatusesOfDevices.WORKING} cardClickCallback={cardClickHandler}
                                  isLoadingData={isFetching} isError={isError} error={error} type={StatusesOfDevices.WORKING}
                                  amount={statisticsCpes?.free}
                                  captionText={'Работают'}/>
                    </Grid>
                    <Grid item xs={12}>
                      <CpeStatusCard clicked={selectedCardType === StatusesOfDevices.NOT_WORKING} cardClickCallback={cardClickHandler}
                                  isLoadingData={isFetching} isError={isError} error={error} type={StatusesOfDevices.NOT_WORKING}
                                  captionText={'Не работают'}
                                  amount={statisticsCpes?.withProblems}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
      }
    </>
  )
}

export default CpeCard;