import React from 'react';
import {duration, Fade, LinearProgress} from "@mui/material";

import {useTimeDelay} from "src/utils/time";
import {ANIMATION_UPDATE_TICK_MS, API_TIMEOUT_MS, WAIT_UNTIL_SHOW_PAGE_LOADER_MS} from "src/defs/ui";

interface LoaderProps {
    isLoading: boolean,
    loaderType: 'determinate' | 'indeterminate',
    startedTimeStamp: number | undefined,
}

export const Loader: React.FC<LoaderProps> = ({
  isLoading,
  loaderType,
  startedTimeStamp,
}) => {

    const [progress, setProgress] = React.useState(0);
    const [startDate, setStartDate] = React.useState(Date.now())
    const [isProgress100, setIsProgress100] = React.useState(false)

    let showLoader = useTimeDelay(isLoading, WAIT_UNTIL_SHOW_PAGE_LOADER_MS)

    React.useEffect(() => {
        if (isLoading){
            setStartDate(Date.now())
        }
    }, [isLoading])

    React.useEffect(() => {
        let timer: NodeJS.Timeout | undefined
        const resetTimer = () => {
            if (timer) {
                clearInterval(timer)
                timer = undefined
            }
        }
        if(isLoading){
            timer = setInterval(() => {
                setProgress((oldProgress) => {
                    if (oldProgress >= 100) {
                        resetTimer()
                        setIsProgress100(true)
                    } else {
                        setIsProgress100(false)
                    }
                    const date = Date.now()
                    if(startedTimeStamp) {
                        return ((date - startedTimeStamp) / API_TIMEOUT_MS) * 100;
                    }
                    else {
                        return ((date - startDate!) / API_TIMEOUT_MS) * 100;
                    }
                });
            }, ANIMATION_UPDATE_TICK_MS);
            return () => {
                resetTimer()
                setProgress(0)
            };
        }
        else {
            resetTimer()
            setProgress(0)
        }
    }, [startedTimeStamp, isLoading, startDate]);

    return(
        <Fade timeout={{exit: 0, enter:duration.enteringScreen}} in={showLoader && !isProgress100}>
            <LinearProgress
                variant={loaderType}
                value={progress}
            />
        </Fade>
    )
}