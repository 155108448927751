import {TTaskId} from "../../types/entityIds";
import {IClient} from "../Client/types";
import {RequestsType, RequestsPriority}  from '../../containers/DashboardLayout/Requests/types'

export interface IDashboardDataTickets {
    inQueue: number
    inProgress: number
    done: number
}

export enum TaskStatusEnum {
    CREATED = 'CREATED',
    IN_WORK = 'IN_WORK',
    CLOSED = 'CLOSED',
}

// export interface ITaskInApi {
//     id: TTaskId
//     title: string
//     text: string
//     status: string
//     assignedToPartnerId: number
//     createdByClientUserId: number
//     client?: IClient
//     createdAt: string // ISO date
//     updatedAt: string | null // ISO date
// }

export interface ITaskInApi {
    id: TTaskId
    title: string
    text: string
    status: string
    assignedToPartnerId: number
    createdByClientUserId: number
    client?: IClient
    createdAt: string // ISO date
    updatedAt: string | null // ISO date
    requestType: RequestsType,
    priority: RequestsPriority,
    assignedToCpeId: number | null
    // created_at: string // ISO date
    // updated_at: string | null // ISO date
}

export interface IChangeStatusProps {
    taskId: TTaskId
    newStatus: TaskStatusEnum
}


export interface ITaskCreatingProps {
    title: string
    text: string
    priority: RequestsPriority,
    assignedToCpeId: number | undefined,
    requestType: RequestsType
}