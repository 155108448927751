import {styled} from '@mui/material/styles';
import {Stack, Container, Card} from '@mui/material';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";
import LogoWithText from '../../components/LogoWithText';
import {PATH_DASHBOARD, PATH_AUTH} from "../../routes/path";
import Page from '../../components/Page';
import {MHidden} from '../../external/minimal/components/@material-extend';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));


const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

const AuthLayout: React.FC = () => {
  const { pathname } = useLocation();
  if (pathname === PATH_AUTH.root) {
    return <Navigate to={PATH_DASHBOARD.general.dashboard}/>;
  }
  return (
    <RootStyle title="">
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            {/*<img src="/green_logo_netegis.png" width="95%" alt="netegis logo"/>*/}
            <LogoWithText sx={{width: '95%'}}/>
          </Stack>

        </SectionStyle>
      </MHidden>
      <Container maxWidth="sm">
        <ContentStyle>
          <MHidden width="mdUp">
            <Stack direction="row" alignItems="center" justifyContent="center">
              <LogoWithText sx={{width: '80%', mb: 3}}/>
            </Stack>
          </MHidden>
          <Outlet/>
        </ContentStyle>
      </Container>

    </RootStyle>
  );
}
export default AuthLayout
