import {Droppable} from 'react-beautiful-dnd';
import {Typography, Skeleton} from "@mui/material/";
import FlipMove from "react-flip-move";
import {useTheme} from "@mui/material/styles";
import {Stack, Card, CardActionArea, Box} from '@mui/material';
import {ITaskInApi, TaskStatusEnum} from "../../../../api/Task/types";
import RequestsBoardViewCard from "./RequestsBoardViewCard";
import RequestsBoardViewCardSkeleton from "./RequestsBoardViewCardSkeleton";


type RequestsBoardViewColumnProps = {
  tasks: ITaskInApi[],
  type: TaskStatusEnum,
  isDropDisabled: boolean
  showAddNewModal?: Function
  isLoadingData?: boolean,
  showDisplayRequestModal? : Function,

};

export default function RequestsBoardViewColumn({tasks, type, isDropDisabled,  showAddNewModal, isLoadingData, showDisplayRequestModal}: RequestsBoardViewColumnProps) {
  const theme = useTheme()
  const handleAddButtonClick = () => {
    if (showAddNewModal) {
      showAddNewModal()
    }
  }

  const getColumnTitle = (status: TaskStatusEnum) => {
    if (status === TaskStatusEnum.CREATED) {
      return 'Новые'
    } else if (status === TaskStatusEnum.IN_WORK) {
      return 'В работе'
    } else if (status === TaskStatusEnum.CLOSED) {
      return 'Закрытые'
    }
  }

  return (
    <Box sx={{width: '100%', minWidth: '200px', px: 2}}>
      <Stack direction={'row'} alignItems={'center'}>
        <Typography variant={'h6'} sx={{mb: '1.2em', ml: '1em'}}>
          {getColumnTitle(type)}
        </Typography>
        <Typography variant={'body1'} color={theme.palette.text.disabled} sx={{mb: '1.2em', ml: '1em'}}>
          {!isLoadingData ?
            tasks.length
            :
            <Skeleton width={'2em'}/>
          }
        </Typography>
      </Stack>

      <Stack spacing={3}>
        {type === TaskStatusEnum.CREATED &&
          <Card><CardActionArea onClick={handleAddButtonClick}><Stack height={'100%'} direction={'column'}
                                                                      justifyContent={'center'}
                                                                      alignItems={'center'}
                                                                      textAlign={'center'}>
            <Typography height={'100%'} variant={'h3'}>
              +
            </Typography>
          </Stack></CardActionArea>
          </Card>
        }
        <Droppable isDropDisabled={isDropDisabled} droppableId={type} type="task">
          {(provided) => (
            <Stack sx={{minHeight: '40vh', width: 1}} ref={provided.innerRef} {...provided.droppableProps} spacing={2}>

              {!isLoadingData ?
                <FlipMove>
                  {tasks.map((task, index) => {
                    return (
                      <Box sx={{mb: 1}} key={task.id}>
                        <RequestsBoardViewCard showDisplayRequestModal={showDisplayRequestModal} task={task}
                                               index={index} key={task.id}/>
                      </Box>
                    );
                  })}
                </FlipMove>
                :
                Array.from(Array(tasks.length ? tasks.length : 5).keys()).map((count) => {
                  return (
                    <RequestsBoardViewCardSkeleton key={count}/>
                  );
                })}
              {provided.placeholder}
            </Stack>
          )}
        </Droppable>
      </Stack>
    </Box>
  );
}
