import React from 'react';
import {ITaskInApi } from "../../../api/Task/types";
import DisplayRequestBlock from "./DisplayRequestBlock";
import StyledDrawer from "../../../components/StyledDrawer";


interface IDisplayRequestModalProps {
  task: ITaskInApi | undefined,
  showingModal: boolean,
  modalClose: Function
  refetchRequestsData?: Function
}


const DisplayRequestModal: React.FC<IDisplayRequestModalProps> = ({task, showingModal, modalClose, refetchRequestsData}) => {
  return (
    <StyledDrawer showingModal={showingModal} modalClose={modalClose}>
      <DisplayRequestBlock task={task} refetchRequestsData={refetchRequestsData ? refetchRequestsData : undefined}
                           modalClose={modalClose}/>
    </StyledDrawer>
  )

}
export default DisplayRequestModal
