import {IHttpResponseBase} from "../types";

export enum BalanceActionTypeEnum {
    ADD = 'ADD',
    SUB = 'SUB'
}

export interface BalanceHistoryItem{
    createdAt: string,
    id: number,
    action: BalanceActionTypeEnum,
    money: number
}

export interface IBalanceHistoryApiResponse extends IHttpResponseBase {
  data: BalanceHistoryItem[],
}

export  interface IGetBalanceHistoryProps{
    timeStampFromSec: number
    timeStampToSec: number
}



