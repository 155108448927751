import React, {useState, useMemo} from "react";
import {Box, Stack} from "@mui/material/";
import {DataGrid, GridColDef, GridValueGetterParams, ruRU} from '@mui/x-data-grid';
import DatePickerForBalanceHistory from './DatePickerForBalanceHistory'
import {useGetBalanceHistoryQuery} from 'src/api/BalanceHistory/BalanceHistory'
import {BalanceHistoryItem} from 'src/api/BalanceHistory/types'
import {
  getBalanceHistoryActionForDisplay,
  formatDate,
  getDateWithHoursSetToBeginOfTheDay,
  getDateWithHoursSetToEndOfTheDay
} from './utils'
import {isDefined} from '../../../../../utils/object'

interface IBalanceHistoryTableProps {

}

const CustomGridNoRowsOverlay = () => {
  return (
    <Stack direction={"row"} justifyContent={"center"} sx={{width: 1, height: 1, mt: 5}}>
      <Box>
        Нет данных об истории баланса за выбранные даты
      </Box>
    </Stack>
  );
}


const BalanceHistoryTable: React.FC<IBalanceHistoryTableProps> = () => {
  const currentDateEndOfDay = getDateWithHoursSetToEndOfTheDay(new Date())
  const previousDate = new Date(currentDateEndOfDay)
  previousDate.setMonth(currentDateEndOfDay.getMonth() - 6)
  const previousDateBeginOfDay = getDateWithHoursSetToBeginOfTheDay(previousDate)
  // console.log('previousDateBeginOfDay', previousDateBeginOfDay)
  const [endDate, setEndDate] = useState<Date>(new Date(currentDateEndOfDay))
  const [startDate, setStartDate] = useState<Date>(new Date(previousDateBeginOfDay))
  const {
    data: balanceHistoryData,
    isFetching: balanceHistoryIsFetching,
    isError: balanceHistoryIsError,
    error: balanceHistoryError
  } = useGetBalanceHistoryQuery({
    timeStampFromSec: Math.floor(startDate.getTime() / 1000),
    timeStampToSec: Math.floor(endDate.getTime() / 1000)
  }, {refetchOnMountOrArgChange: true})

  const datepickerSetStartDateHandler = (date: Date) => {
    const selectedDateBeginOfDay = getDateWithHoursSetToBeginOfTheDay(date)
    setStartDate(selectedDateBeginOfDay)
  }
  const datepickerSetEndDateHandler = (date: Date) => {
    const selectedDateEndOfDay = getDateWithHoursSetToEndOfTheDay(date)
    setEndDate(selectedDateEndOfDay)
  }

  const columns: GridColDef[] = [
    {
      field: 'createdAt',
      sortable: true,
      headerName: 'Дата',
      flex: 1.5,
      align: "left",
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) => {
        return isDefined(params.row.createdAt) ? formatDate(params.row.createdAt) : 'Неопределено'
      }
    },
    {
      field: 'action',
      sortable: true,
      headerName: 'Действие',
      flex: 1,
      align: "right",
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridValueGetterParams) =>
        `${getBalanceHistoryActionForDisplay(params.row.action)}`,
    },
    {
      field: 'money',
      sortable: true,
      headerName: 'Сумма',
      flex: 1,
      align: "right",
      filterable: false,
      disableColumnMenu: true
    },
  ];

  // const rows = [
  //   {id: 1, created_at: 'дата 1', action: 'пополнение', money: 1000},
  //   {id: 2, created_at: 'дата 2', action: 'списание', money: 1000},
  //   {id: 3, created_at: 'дата 3', action: 'пополнение', money: 1000},
  //
  // ];

  const rows = useMemo(() => {
      const rows = [] as BalanceHistoryItem[]
      balanceHistoryData?.data?.forEach((balanceHistoryItem) => {
        rows.push({
          id: balanceHistoryItem.id,
          createdAt: balanceHistoryItem.createdAt,
          action: balanceHistoryItem.action,
          money: balanceHistoryItem.money
        })
      })
      return rows
    },
    [balanceHistoryData])

  return (
    <>
      <Box sx={{width: 1}}>
        <Box sx={{mb: 1}}>
          <DatePickerForBalanceHistory isLoading={balanceHistoryIsFetching} startDate={startDate} endDate={endDate}
                                       setStartDate={datepickerSetStartDateHandler}
                                       setEndDate={datepickerSetEndDateHandler}/>
        </Box>
        {
          balanceHistoryIsError ?
            <Stack direction={'column'} sx={{width: 1, height: 1}}>
              <Box>Ошибка при загрузке данных: "{balanceHistoryError}"</Box>
            </Stack>
            :
            <DataGrid
              components={{NoRowsOverlay: CustomGridNoRowsOverlay}}
              loading={balanceHistoryIsFetching}
              initialState={{
                sorting: {
                  sortModel: [{field: 'createdAt', sort: 'desc'}],
                },
              }}
              density='compact'
              disableColumnSelector
              disableSelectionOnClick
              autoHeight
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
            />
        }
      </Box>
    </>
  )
}


export default BalanceHistoryTable