import React, {useCallback, useEffect, useMemo, useState} from "react";
import ReactDOMServer from 'react-dom/server'
import {useCounter} from "react-use";
import {withStyles, WithStyles} from '@mui/styles';
import {Container, Paper, Stack, Theme, useTheme, Card, Box, Typography} from "@mui/material";
import {Skeleton} from "@mui/material";
import {MapContainer, Marker, Popup, TileLayer, useMapEvents} from 'react-leaflet'
import L, {divIcon, LatLngTuple, point} from 'leaflet'
import {CheckCircle, Error} from "@mui/icons-material";
import 'leaflet/dist/leaflet.css'
import leafletDefaultMarker from 'leaflet/dist/images/marker-icon.png'
import leafletDefaultMarker2x from 'leaflet/dist/images/marker-icon-2x.png'
import leafletDefaultMarkerShadow from 'leaflet/dist/images/marker-shadow.png'
// import {useNavigate} from "react-router-dom";

import {useGetLocationsWithCountersQuery} from "src/api/ClientLocation/ClientLocation";
import {useGetCpesQuery} from "src/api/ClientCpe/ClientCpe"
import {AssignedFilter, ICpeWithCounters} from "src/api/ClientCpe/types";
import {getCombinedParamsFromRtkqHook} from "src/utils/rtkq";
import {extractIdMakeRecordset, isDefined} from "src/utils/object";
import {TLocationId} from "src/types/entityIds";
import {defaultIfUndef} from "src/utils/func";
import CreateNewRequestModal from '../../../containers/DashboardLayout/Requests/CreateNewRequestModal'

import {
    MAP_DEFAULT_CENTER_LAT,
    MAP_DEFAULT_CENTER_LON,
    MAP_DEFAULT_ZOOM,
    MAP_MARKER_ICON_SIZE_PX,
    MAP_ZOOM_THRESHOLD_FOR_DIFFERENT_MARKERS,
    MAP_MIN_ICON_CARD_WIDTH_FOR_AFTER_THRESHOLD_FOR_DIFFERENT_MARKERS
} from "./consts";
import style from "./styles";
import {IClientLocationAggStatus} from "../../../api/ClientLocation/types";
import {AggStatusSeverity, formatAggStatusCpe, formatAggStatusLocation} from "../../../utils/format";
import OkIcon from '../OkIcon'
import AlertIcon from '../AlertIcon'
import {StatusesOfDevices} from "../../../containers/DashboardLayout/Devices/types";

// Hack to proper show default markers image: https://github.com/PaulLeCam/react-leaflet/issues/255#issuecomment-388492108
// @ts-ignore TS2339: Property '_getIconUrl' does not exist on type 'Default'.
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: leafletDefaultMarker2x,
    iconUrl: leafletDefaultMarker,
    shadowUrl: leafletDefaultMarkerShadow,
});

interface OpenStreetMapCardProps {
    classes: WithStyles<typeof style>['classes'],
    selectedDevicesStatus?: StatusesOfDevices,
    showLocationsWithNoCpesAsAllOk?: boolean
}

interface IOpenStreetMapCardZoomLevelWatcherProps {
    zoomLevelChangedCallback: Function
}


interface Ilocation {
    // clientName: string,
    aggStatus: IClientLocationAggStatus,
    name?: string,
    // FIXME: по идее не может быть undefined, но в api написано, что может
    address: string | undefined,
    lat: number | undefined,
    lon: number | undefined,
    id: number,
}

const preparedBounds = (locations: Ilocation[] | undefined): LatLngTuple[] => {
    const locationsWithCoords = (locations || [])
        .filter(
            (loc) => isDefined(loc.lat) && isDefined(loc.lon)
        )
    // можно так и оставить, просто сделать isDefined правильно, чтобы ts не ругался
    // @ts-ignore TS2322: Type 'undefined' is not assignable to type 'number'. (из-за : LatLngTuple[])
    return locationsWithCoords.map((loc) => ([loc.lat, loc.lon]))
};

// const getMarkerColor = (aggStatus: IClientLocationAggStatus | null, theme: Theme): PaletteColor => {
//     if(aggStatus === null){
//         return theme.palette.primary
//     }
//     const formattedAggStatus = formatAggStatusLocation(aggStatus)
//     if(formattedAggStatus?.isOk!){
//         return theme.palette.success
//     } else if(formattedAggStatus?.severity! === AggStatusSeverity.blocker || formattedAggStatus?.severity! === AggStatusSeverity.critical){
//         return theme.palette.error
//     } else if(formattedAggStatus?.severity! === AggStatusSeverity.major || formattedAggStatus?.severity! === AggStatusSeverity.normal){
//         return theme.palette.warning
//     } else if(formattedAggStatus?.severity! === AggStatusSeverity.minor){
//        return theme.palette.info
//     }
//     return theme.palette.primary
// }

enum LocationCpesStatusTypes {
    ALL_OK = 'allOk',
    HAS_ERRORS = 'hasError',
    NO_CPES = 'noCpes'
}

interface IMarkerIconType {
    type: LocationCpesStatusTypes
}

const getMarkerIconType = (aggStatus: IClientLocationAggStatus): IMarkerIconType => {
    if (aggStatus === null) {
        return {type: LocationCpesStatusTypes.NO_CPES}
    }
    const formattedAggStatus = formatAggStatusLocation(aggStatus)
    if (formattedAggStatus?.isOk!) {
        return {type: LocationCpesStatusTypes.ALL_OK}
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.blocker || formattedAggStatus?.severity! === AggStatusSeverity.critical) {
        return {type: LocationCpesStatusTypes.HAS_ERRORS}
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.major || formattedAggStatus?.severity! === AggStatusSeverity.normal) {
        return {type: LocationCpesStatusTypes.HAS_ERRORS}
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.minor) {
        return {type: LocationCpesStatusTypes.HAS_ERRORS}
    }
    return {type: LocationCpesStatusTypes.NO_CPES}
}

const getLocationCpesStatusType = (aggStatus: IClientLocationAggStatus): LocationCpesStatusTypes => {
    if (aggStatus === null) {
        return LocationCpesStatusTypes.NO_CPES
    }
    const formattedAggStatus = formatAggStatusLocation(aggStatus)
    if (formattedAggStatus?.isOk!) {
        return LocationCpesStatusTypes.ALL_OK
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.blocker || formattedAggStatus?.severity! === AggStatusSeverity.critical) {
        return LocationCpesStatusTypes.HAS_ERRORS
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.major || formattedAggStatus?.severity! === AggStatusSeverity.normal) {
        return LocationCpesStatusTypes.HAS_ERRORS
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.minor) {
        return LocationCpesStatusTypes.HAS_ERRORS
    }
    return LocationCpesStatusTypes.NO_CPES
}


const getPopupStatus = (aggStatus: IClientLocationAggStatus | null, classes: any): string | JSX.Element => {
    if (aggStatus === null) {
        return 'Устройства отсутствуют'
    }
    const formattedAggStatus = formatAggStatusLocation(aggStatus)
    if (formattedAggStatus?.isOk!) {
        return <>
            Работает&nbsp;
            <CheckCircle fontSize="small" className={classes.popupCircleGreen}/>
        </>
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.blocker || formattedAggStatus?.severity! === AggStatusSeverity.critical) {
        return <>
            Проблема&nbsp;
            <Error fontSize="small" className={classes.popupCircleRed}/>
        </>
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.major || formattedAggStatus?.severity! === AggStatusSeverity.normal) {
        return <>
            Проблема&nbsp;
            <Error fontSize="small" className={classes.popupCircleYellow}/>
        </>
    } else if (formattedAggStatus?.severity! === AggStatusSeverity.minor) {
        return <>
            Проблема&nbsp;
            <Error fontSize="small" className={classes.popupCircleBlue}/>
        </>
    }
    return 'Устройства отсутствуют'
}

const getMapsMarkerIcon = (
    loc: Ilocation,
    theme: Theme,
    onLocationClick: (locationId: number) => unknown,
    selectedDevicesStatus?: StatusesOfDevices,
    currentZoomLevel?: number
): L.DivIcon => {
    const optionsForDivIcon = {
        // reset classname to not show default white square
        className: '',
        iconSize: point(MAP_MARKER_ICON_SIZE_PX, MAP_MARKER_ICON_SIZE_PX),
        // Adjust marker location point to be corner (at the bottom) of the LocationOnIcon
        iconAnchor: point(MAP_MARKER_ICON_SIZE_PX / 2, MAP_MARKER_ICON_SIZE_PX / 2),
        popupAnchor: point(0, 0),
        html: ReactDOMServer.renderToString(<div/>)

    }

    if (currentZoomLevel && (currentZoomLevel >= MAP_ZOOM_THRESHOLD_FOR_DIFFERENT_MARKERS)) {
        optionsForDivIcon.html = ReactDOMServer.renderToString(
            <Card sx={{
                height: MAP_MARKER_ICON_SIZE_PX,
                minWidth: MAP_MIN_ICON_CARD_WIDTH_FOR_AFTER_THRESHOLD_FOR_DIFFERENT_MARKERS,
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
                borderRadius: '10px',
                width: 'fit-content',
            }}>
                <Stack direction={"row"} alignItems={"center"} sx={{height: '100%'}}>
                    <Box sx={{minWidth: MAP_MARKER_ICON_SIZE_PX}}>
                        <Stack direction={"column"} alignItems={"center"} sx={{height: '100%'}}>
                            {getMarkerIconType(loc.aggStatus).type === LocationCpesStatusTypes.HAS_ERRORS ?
                                <AlertIcon width={`${MAP_MARKER_ICON_SIZE_PX}`} height={`${MAP_MARKER_ICON_SIZE_PX}`}
                                           withStroke
                                           strokeColor={theme.palette.background.default}
                                           circleFillColor={theme.palette.error.lighter}
                                           iconFillColor={theme.palette.error.dark}/>
                                :
                                <OkIcon width={`${MAP_MARKER_ICON_SIZE_PX}`} height={`${MAP_MARKER_ICON_SIZE_PX}`}
                                        withStroke
                                        strokeColor={theme.palette.background.default}
                                        circleFillColor={theme.palette.primary.lighter}
                                        iconFillColor={theme.palette.primary.dark}/>
                            }
                        </Stack>
                    </Box>
                    <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                        <Stack direction={"column"} justifyContent={"center"}
                               sx={{mb: 0.25, mt: 0.25, pr: 1, textOverflow: 'ellipsis', overflow: 'hidden'}}>
                            <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                                <Typography noWrap
                                            variant={'subtitle1'}>{defaultIfUndef(loc.name, 'Не указано')}</Typography>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2"
                                            sx={{color: theme.palette.text.secondary, fontSize: '10px'}}>
                                    {getMarkerIconType(loc.aggStatus).type === LocationCpesStatusTypes.HAS_ERRORS ?
                                        <span>Не работает</span>
                                        :
                                        (getMarkerIconType(loc.aggStatus).type === LocationCpesStatusTypes.NO_CPES ?
                                            <span>Нет устройств</span>
                                            :
                                            <span>Работает</span>)
                                    }
                                </Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Card>
        )
    } else {
        optionsForDivIcon.html = ReactDOMServer.renderToString(
            (getMarkerIconType(loc.aggStatus).type === LocationCpesStatusTypes.HAS_ERRORS) ?
                <AlertIcon width={`${MAP_MARKER_ICON_SIZE_PX}`} height={`${MAP_MARKER_ICON_SIZE_PX}`} withStroke
                           strokeColor={theme.palette.background.default}
                           circleFillColor={theme.palette.error.lighter} iconFillColor={theme.palette.error.dark}/>
                :
                <OkIcon width={`${MAP_MARKER_ICON_SIZE_PX}`} height={`${MAP_MARKER_ICON_SIZE_PX}`} withStroke
                        strokeColor={theme.palette.background.default}
                        circleFillColor={theme.palette.primary.lighter} iconFillColor={theme.palette.primary.dark}/>
        )
    }
    return divIcon(optionsForDivIcon)
}

const getMapMarkers = (
    cpesWithIdKeys: Record<number, ICpeWithCounters>,
    locations: Ilocation[] | undefined,
    classes: WithStyles<typeof style>['classes'],
    theme: Theme,
    onLocationClick: (locationId: number) => unknown,
    handleCreateTaskFunc: Function,
    selectedDevicesStatus?: StatusesOfDevices,
    showLocationsWithNoCpesAsAllOk?: boolean,
    currentZoomLevel?: number,
): (React.ReactElement)[] | null => {
    const locationsWithCoords = (locations || [])
        .filter((loc) => isDefined(loc.lat) && isDefined(loc.lon))
    if (locationsWithCoords.length === 0) {
        return null
    }
    let filtredLocationsWithCoords
    // console.log(locations)
    if (selectedDevicesStatus === StatusesOfDevices.WORKING) {
        if (showLocationsWithNoCpesAsAllOk) {
            filtredLocationsWithCoords = (locationsWithCoords || [])
                .filter((loc) => (getLocationCpesStatusType(loc.aggStatus) === LocationCpesStatusTypes.ALL_OK) ||
                    (getLocationCpesStatusType(loc.aggStatus) === LocationCpesStatusTypes.NO_CPES))
        } else {
            filtredLocationsWithCoords = (locationsWithCoords || [])
                .filter((loc) => getLocationCpesStatusType(loc.aggStatus) === LocationCpesStatusTypes.ALL_OK)
        }
    } else if (selectedDevicesStatus === StatusesOfDevices.NOT_WORKING) {
        filtredLocationsWithCoords = (locationsWithCoords || [])
            .filter((loc) => getLocationCpesStatusType(loc.aggStatus) === LocationCpesStatusTypes.HAS_ERRORS)
    } else {
        filtredLocationsWithCoords = locationsWithCoords
        if (!showLocationsWithNoCpesAsAllOk) {
            filtredLocationsWithCoords = (filtredLocationsWithCoords || [])
                .filter((loc) => getLocationCpesStatusType(loc.aggStatus) !== LocationCpesStatusTypes.NO_CPES)
        }
    }
    //console.log(cpesWithIdKeys)
    return filtredLocationsWithCoords.map((loc) => (
        <Marker
            // @ts-ignore TS2322: Type 'undefined' is not assignable to type 'number'.
            position={[loc.lat, loc.lon]}
            key={loc.id}
            icon={getMapsMarkerIcon(loc, theme, onLocationClick, selectedDevicesStatus, currentZoomLevel)}
            // icon={divIcon({
            //
            //     // reset classname to not show default white square
            //     className: '',
            //     iconSize: point(MAP_MARKER_ICON_SIZE_PX, MAP_MARKER_ICON_SIZE_PX),
            //     // Adjust marker location point to be corner (at the bottom) of the LocationOnIcon
            //     iconAnchor: point(MAP_MARKER_ICON_SIZE_PX / 2, MAP_MARKER_ICON_SIZE_PX / 2),
            //     popupAnchor: point(0, 0),
            //     html: ReactDOMServer.renderToString(
            //       (getMarkerIconType(loc.aggStatus).type === LocationCpesStatusTypes.HAS_ERRORS) ?
            //         <AlertIcon width={`${MAP_MARKER_ICON_SIZE_PX}`} height={`${MAP_MARKER_ICON_SIZE_PX}`} withStroke
            //                    strokeColor={theme.palette.background.default}
            //                    circleFillColor={theme.palette.error.lighter} iconFillColor={theme.palette.error.dark}/>
            //         :
            //         <OkIcon width={`${MAP_MARKER_ICON_SIZE_PX}`} height={`${MAP_MARKER_ICON_SIZE_PX}`} withStroke
            //                 strokeColor={theme.palette.background.default}
            //                 circleFillColor={theme.palette.primary.lighter} iconFillColor={theme.palette.primary.dark}/>
            //     ),
            // })}
        >
            <Popup className={classes.popupWrapper}>
                {/*<div>Клиент:&nbsp;{loc.clientName} </div>*/}
                <div>
                    <b>Место:&nbsp;{
                        defaultIfUndef(loc.name, 'Не указано')
                    }</b>
                </div>
                <div>Адрес:&nbsp;{loc.address} </div>
                <div className={classes.popupCondition}>
                    Состояние:&nbsp;
                    {
                        getPopupStatus(loc.aggStatus, classes)
                    }
                </div>
                <div>
                    {(loc.aggStatus.cpe.length !== 0) &&
                    <div>
                        <hr/>
                        <b>Устройства</b>:
                        {loc.aggStatus.cpe.map(cpe => {
                            return <div key={cpe.id}>
                                {/*<div>Устройство <b>id {cpe.id}</b> </div>*/}
                                <div>Имя: {cpesWithIdKeys[cpe.id]?.name ? cpesWithIdKeys[cpe.id].name : 'Не указано'}</div>
                                <div>Тип: {cpesWithIdKeys[cpe.id]?.cpeType}</div>
                                <div>S/N: {cpesWithIdKeys[cpe.id]?.serial}</div>
                                {/*<div>{cpesWithIdKeys[cpe.id]}</div>*/}
                                <div>Статус: {formatAggStatusCpe(cpe)?.text}
                                    &nbsp;
                                    <span>
                                          {formatAggStatusCpe(cpe)?.text !== '' && !formatAggStatusCpe(cpe)?.isOk &&
                                          <div className={classes.locationLink} onClick={() => {
                                              //  alert(`Создание заявки для устройства c id ${cpe.id}`)
                                              handleCreateTaskFunc(cpe.id)
                                          }
                                          }>Создать заявку</div>
                                          }
                                      </span>
                                </div>
                                <br/>
                            </div>
                        })}
                    </div>
                    }
                </div>
                {/*<div className={classes.locationLink} onClick={() => onLocationClick(loc.id!)}>*/}
                {/*    Перейти*/}
                {/*</div>*/}
            </Popup>
        </Marker>
    ))
}

const OpenStreetMapCardZoomLevelWatcher: React.FC<IOpenStreetMapCardZoomLevelWatcherProps> = ({zoomLevelChangedCallback}) => {
    const mapEvents = useMapEvents({
        zoomend: () => {
            zoomLevelChangedCallback(mapEvents.getZoom());
        },
    });
    return null
}


const OpenStreetMapCard: React.FC<OpenStreetMapCardProps> = ({
     classes,
     selectedDevicesStatus,
     showLocationsWithNoCpesAsAllOk
 }) => {

    const theme = useTheme()
    const locationsQuery = useGetLocationsWithCountersQuery(
        {},
        {refetchOnMountOrArgChange: true},
    )
    // const clientsQuery = useGetClientsQuery(
    //     undefined,
    //     {refetchOnMountOrArgChange: true},
    // )
    const cpesQuery = useGetCpesQuery({assignedFilter: AssignedFilter.all}, {refetchOnMountOrArgChange: true})

    const cpesWithIdKeys = useMemo(() => {
        return extractIdMakeRecordset(cpesQuery.data?.data || [])
    }, [cpesQuery.data])

    const {
        error,
        isFetching,
        isError,
    } = getCombinedParamsFromRtkqHook([
        locationsQuery,
        // clientsQuery,
        cpesQuery
    ])
    const [classesSequenceId, {inc: incClassesSequenceId}] = useCounter(1)
    useEffect(incClassesSequenceId, [classes, incClassesSequenceId])
    const [currentZoomLevel, setCurrentZoomLevel] = useState<number | undefined>(undefined)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [cpeIdForCreatingTask, setCpeIdForCreatingTask] = useState<number | undefined>(undefined)
    // console.log('zoom level is ', currentZoomLevel)

    const handleModalClose = () => {
        setCpeIdForCreatingTask(undefined)
        setShowCreateModal(false)
    }
    const handleCreateTaskFunc = (cpeId: number | undefined) => {
        // console.log(cpeId)
        setCpeIdForCreatingTask(cpeId)
        setShowCreateModal(true)

    }

    const locations: Ilocation[] | undefined = useMemo(() => {
        // console.log('recalc locations')
        // const clientsWithIdKeys = extractIdMakeRecordset(clientsQuery.data?.data || [])
        return (
            locationsQuery.data?.data.map((location) => (
                {
                    // clientName: clientsWithIdKeys[location.clientId]?.name,
                    name: location.name,
                    address: location.address,
                    lat: location.lat,
                    lon: location.lon,
                    aggStatus: location.aggStatus,
                    id: location.id,
                }
            ))
        )
    }, [locationsQuery])

    // const history = useNavigate()

    const onLocationClick = useCallback(
        (locationId: TLocationId) =>
            // history(generatePath(ENTITY_PAGE_ROUTES[EntityPageEnum.location].path, {locationId})),
            console.log(`redirect to location.id = ${locationId}`),
        [],
    )

    const locationsCoordinates = useMemo(
        () => preparedBounds(locations),
        [locations]
    )
    const shouldUseBounds = locationsCoordinates.length >= 2

    const mapMarkers = useMemo(
        () => getMapMarkers(cpesWithIdKeys, locations, classes, theme, onLocationClick, handleCreateTaskFunc, selectedDevicesStatus, showLocationsWithNoCpesAsAllOk, currentZoomLevel),
        [locations, classes, theme, onLocationClick, selectedDevicesStatus, showLocationsWithNoCpesAsAllOk, cpesWithIdKeys, currentZoomLevel]
    )

    return (
        <>
            {showCreateModal &&
            <CreateNewRequestModal refetchOnMountOrArgChangeLocationsAndCpesData={false} cpeId={cpeIdForCreatingTask}
                                   modalClose={handleModalClose} showingModal={showCreateModal}/>}
            <div className={classes.openStreetMapCard}>
                {
                    isError
                        ?
                        <Paper elevation={20} className={classes.openStreetMapPlaceholder}>
                            <Container>
                                <p>Произошла ошибка загрузки данных устройств {JSON.stringify(error)}</p>
                            </Container>
                        </Paper>
                        : isFetching
                            ? <Skeleton variant='rectangular' height={'100%'} className={classes.openStreetMapPlaceholder}/>
                            : <Paper elevation={20} className={classes.openStreetMapPlaceholder}>
                                <MapContainer
                                    center={shouldUseBounds
                                        ? undefined
                                        : locationsCoordinates.length === 1
                                            ? locationsCoordinates[0]
                                            : [MAP_DEFAULT_CENTER_LAT, MAP_DEFAULT_CENTER_LON]
                                    }
                                    zoom={shouldUseBounds ? undefined : MAP_DEFAULT_ZOOM}
                                    bounds={shouldUseBounds ? locationsCoordinates : undefined}
                                    scrollWheelZoom={true}
                                    className={classes.openStreetMapContainer}
                                    whenCreated={(map) => {
                                        const currentMapZoomLevel = map.getZoom()
                                        // console.log(currentMapZoomLevel)
                                        setCurrentZoomLevel(currentMapZoomLevel)
                                    }
                                    }
                                >
                                    <OpenStreetMapCardZoomLevelWatcher zoomLevelChangedCallback={setCurrentZoomLevel}/>
                                    <TileLayer
                                        // We need to update "key" to rerender TileLayer
                                        // to change colors when change theme type (light/dark)
                                        key={classesSequenceId}
                                        className={classes.tileLayer}
                                        // This is required according to license
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {mapMarkers}
                                </MapContainer>
                            </Paper>
                }
            </div>
        </>
    )
}

export default withStyles(style)(OpenStreetMapCard);