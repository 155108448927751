import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField } from "mui-rff";
import * as Yup from 'yup';
import React, {useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  Link
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../defs/forms";
import {FieldName, FormData} from "../../../containers/Auth/Login/types";
import {useLoginMutation} from "src/api/User/User";
import {canStepNextInWizard} from "../../../utils/forms";
import {getErrorMsgByError} from "../../../containers/Auth/utils";
import {PATH_AUTH} from '../../../routes/path'

// ---------------------
Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.email]: Yup.string().email().required(),
  [FieldName.password]: Yup.string().required(),
})

const validate = makeValidate(schema)
const required = makeRequired(schema)

const  LoginForm: React.FC = () => {
  const [
    login,
    {
      error,
      isError,
      isLoading: isLoggingIn,
    }
  ] = useLoginMutation()
  const [showPassword, toggleShowPassword] = useState(false);
  const handleShowPassword = () => {
    toggleShowPassword((show) => !show);
  };
  const fields = [
    <TextField
      fullWidth
      label='Email'
      type='email'
      name={FieldName.email}
      required={required.email}
      size='small'
      showError={showErrorOnBlur}
    />,
    <TextField
      fullWidth
      label='Пароль'
      type={showPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.password}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end">
              <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
  ]

  const onSubmit = async (values: FormData) => {
    console.log('Auth.onSubmit', values)
    const returnedFromAPIVal = await login(values)
    console.log('Auth.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      console.log('Auth.onSubmit.error')
    } else {
      console.log('Auth.onSubmit.success')
      document.location.reload()
    }
  }

  return <Form<FormData>
    onSubmit={onSubmit}
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      return <form
        onSubmit={props.handleSubmit}
        noValidate
      >
          <Stack spacing={3}>
            {(error || isError) && <Alert severity="error">{getErrorMsgByError(error)}</Alert>}
            {fields.map((field) => (
              <div
                key={field.props.name}
                // xs={12}
              >
                {field}
              </div>
            ))}
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
                Сбросить пароль
            </Link>
            <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.registration}>
                Зарегистрироваться
            </Link>
          </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isLoggingIn}
          disabled={
            !canStepNextInWizard(props)
          }

        >
          Войти
        </LoadingButton>
      </form>
    }}
  />

}
export default LoginForm
