export enum FieldName {
     locationName = 'locationName',
     locationAddress = 'locationAddress',
     lat = 'lat',
     lon = 'lon',
}

export interface FormData {
    [FieldName.locationName]: string
    [FieldName.locationAddress]: string
    [FieldName.lat]: number | undefined
    [FieldName.lon]: number | undefined
}
