import {WithClientRaw} from "../Client/types";
import {emptySplitApi} from "../base";
import {IHttpResponseBase} from "../types";
import {IChangeStatusProps, IDashboardDataTickets, ITaskInApi} from "./types";
import {ITaskCreatingProps} from './types'
export const TaskApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getDashboardDataTasks: build.query<IHttpResponseBase<IDashboardDataTickets>, void>({
            query: () => '/task/stats',
        }),
        getTasks: build.query<IHttpResponseBase<WithClientRaw<ITaskInApi>[]>, void>({
            query: () => ({
                url: '/task',
            }),
        }),
        changeTaskStatus: build.mutation<ITaskInApi, IChangeStatusProps>({
            query: ({taskId, newStatus}) => ({
                url: `/task/${taskId}/status`,
                method: 'POST',
                body: {
                    status: newStatus,
                }
            }),
        }),
        createTask: build.mutation<ITaskInApi, ITaskCreatingProps>({
            query: ({title, text, priority, assignedToCpeId, requestType}) => ({
                url: `/task`,
                method: 'POST',
                body: {
                    title : title,
                    text : text,
                    priority: priority,
                    assignedToCpeId: assignedToCpeId === undefined ? null : assignedToCpeId,
                    requestType: requestType
                }
            }),
        }),
    })
})

export const {
    useGetDashboardDataTasksQuery,
    useGetTasksQuery,
    useChangeTaskStatusMutation,
    useCreateTaskMutation
} = TaskApi
