import { configureStore } from '@reduxjs/toolkit';
import {setupListeners} from "@reduxjs/toolkit/query";

import {emptySplitApi} from "../api/base";
import UserStore from "../redux/User/UserStore";

export const store = configureStore({
  reducer: {
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
    [UserStore.name]: UserStore.reducer,
  },
  middleware: (gDM) => gDM().concat(emptySplitApi.middleware),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

