import React from 'react';
import StyledDrawer from "../../../../components/StyledDrawer";
import {IClientTariff} from 'src/api/ClientTariff/types'
import TariffDetialsBlock from "./TariffDetailsBlock";


interface IDetailsTariffDrawerProps {
  tariff: IClientTariff | undefined,
  showingModal: boolean,
  modalClose: Function,
}


const DetailsTariffDrawer: React.FC<IDetailsTariffDrawerProps> = ({tariff, showingModal,modalClose}) => {
  return (
    <StyledDrawer showingModal={showingModal} modalClose={modalClose}>
      {tariff && <TariffDetialsBlock tariff={tariff}/>}
    </StyledDrawer>
  )

}
export default DetailsTariffDrawer
