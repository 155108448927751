import React from "react";
import {Typography, Stack} from "@mui/material/";
import {useGetNetegisDetailsQuery} from 'src/api/NetegisDetails/NetegisDetails'
import StyledCardForDetailsForPayment from './StyledCardForDetailsForPayment'
import TableWithDetailsForPayment from "./TableWithDetailsForPayment";
import QRWithDetailsForPayment from "./QRWithDetailsForPayment";


const DetailsForPaymentCard: React.FC<{}> = () => {
  const {
    data: netegisDetailsData,
    isFetching: netegisDetailsIsFetching,
    isError: netegisDetailsIsError,
  } = useGetNetegisDetailsQuery(undefined, {refetchOnMountOrArgChange: true})


  return (
    <>
      <StyledCardForDetailsForPayment>
        <Stack sx={{overflow: 'auto'}} justifyContent={'center'} alignItems={'center'}>
          <Typography sx={{width: 1}} variant={'h5'}>
            Реквизиты
          </Typography>
          <Typography sx={{width: 1}} variant={'body1'}>
            Пополнить баланс вы можете по следующим реквизитам:
          </Typography>
          <Stack direction={'row'} flexWrap={'wrap'} justifyContent={'center'} alignItems={'center'}>
            <TableWithDetailsForPayment details={netegisDetailsData?.data}
                                        isLoadingDetailsData={netegisDetailsIsFetching}
                                        isLoadingDetailsDataError={netegisDetailsIsError}/>
            <QRWithDetailsForPayment details={netegisDetailsData?.data}
                                     isLoadingDetailsData={netegisDetailsIsFetching}
                                     isLoadingDetailsDataError={netegisDetailsIsError}/>
          </Stack>

        </Stack>

      </StyledCardForDetailsForPayment>
    </>
  )
}


export default DetailsForPaymentCard