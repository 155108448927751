import {emptySplitApi} from "../base";
import {IdaDataOrganizationsApiResponse, IdaDataProps} from "./types";

const daDataApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getDaDataOrganizations: build.mutation<IdaDataOrganizationsApiResponse, IdaDataProps>({
            query: (value) => ({
                url: `/dadata/suggestions/api/4_1/rs/suggest/party`,
                method: 'POST',
                body: {
                    "query": value,
                },
            }),
            transformResponse: (resp: any) => ({
                data: resp.suggestions.map((suggestion: any) => ({
                    ...suggestion,
                    name: suggestion.value,
                    inn: suggestion.data.inn,
                    kpp: suggestion.data.kpp,
                    ogrn: suggestion.data.ogrn,
                    address: suggestion.data.address.unrestrictedValue,
                    unrestrictedValue: suggestion.data.address.unrestrictedValue,
                    value: suggestion.value,
                }))
            } as IdaDataOrganizationsApiResponse),
        }),
    })
})

export const {
    useGetDaDataOrganizationsMutation,
} = daDataApi
