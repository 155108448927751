import React, {useState} from "react";
import { Box, Grid } from "@mui/material/";
import StatusCard from "./StatusCard";
import {useGetDashboardDataCpesQuery} from '../../../../api/ClientCpe/ClientCpe'
import {StatusesOfDevices} from "../types";

interface IStatusBlockProps {
 selectTypeOfDevicesStatusCallback: Function
}



const StatusBlock: React.FC<IStatusBlockProps> = ({selectTypeOfDevicesStatusCallback}) => {

  const [selectedCardType, setSelectedCardType] = useState<StatusesOfDevices>();
  const cardClickHandler = (typeOfClickedCard: StatusesOfDevices)=>{
    if (typeOfClickedCard === selectedCardType) {
      setSelectedCardType(undefined)
      selectTypeOfDevicesStatusCallback(undefined)
    } else {
      setSelectedCardType(typeOfClickedCard)
      selectTypeOfDevicesStatusCallback (typeOfClickedCard)
    }
  }
  const {
    data,
    error,
    isFetching,
    isError,
  } = useGetDashboardDataCpesQuery(
    undefined,
    {refetchOnMountOrArgChange: true},
  )
  const statisticsCpes = data?.data
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <StatusCard clicked={selectedCardType === StatusesOfDevices.ALL} cardClickCallback={cardClickHandler}
                      isLoadingData={isFetching} isError={isError} error={error} type={StatusesOfDevices.ALL}
                      amount={statisticsCpes?.all}
                      captionText={'Всего устройств'}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <StatusCard clicked={selectedCardType === StatusesOfDevices.WORKING} cardClickCallback={cardClickHandler}
                      isLoadingData={isFetching} isError={isError} error={error} type={StatusesOfDevices.WORKING}
                      amount={statisticsCpes?.free}
                      captionText={'Работают'}/>
        </Grid>
        <Grid item xs={12} md={4}>
          <StatusCard clicked={selectedCardType === StatusesOfDevices.NOT_WORKING} cardClickCallback={cardClickHandler}
                      isLoadingData={isFetching} isError={isError} error={error} type={StatusesOfDevices.NOT_WORKING}
                      captionText={'Не работают'}
                      amount={statisticsCpes?.withProblems}/>
        </Grid>
      </Grid>
    </Box>

  )
}

export default StatusBlock;