// import {createStyles, Theme} from "@material-ui/core";
import {Theme} from '@mui/material';
import {createStyles} from '@mui/styles';

const styles = (theme: Theme) => createStyles(({
    gridItemAddress: {
        color: theme.palette.text.secondary,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
        fontSize: 14,
    },
}))

export default styles