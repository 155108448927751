import {useState, ReactNode} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
// hooks
// import useAuth from '../hooks/useAuth';
import {useIsAuthenticated} from 'src/hooks/useIsAuthenticated'
// pages
// import Auth from '../containers/Auth/Auth';
import Login from '../containers/Auth/Login/Login'
import Page403 from "../containers/Page403";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({children}: AuthGuardProps) {
  const {loggedIn, checkingLogin, isCheckingError, checkingError} = useIsAuthenticated();
  const {pathname} = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (checkingLogin) {
    return <div/>
  }

  if (isCheckingError) {
    if (checkingError?.status === 403) {
      return <Page403/>
    }
    return <p>Ошибка проверки входа: {checkingError?.status} {checkingError?.data?.description} </p>
  }

  if (!loggedIn) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login/>;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation}/>;
  }

  return <>{children}</>;
}
