import React from "react";
import {useTheme} from "@mui/material";

export const AdornmentTextField:React.FC = ({children}) => {
    const theme = useTheme()
    return (
        <div style={{marginRight: theme.spacing(1)}}>
            {children}
        </div>
    )
}