import React from "react";
import {styled} from '@mui/material/styles';
import {Box, Stack, Container, Typography} from '@mui/material';
import Page from '../../../components/Page';
import ResetPasswordForm from "./ResetPasswordForm";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));


// ----------------------------------------------------------------------

const ResetPassword: React.FC = () => {

  return (
    <RootStyle title="Сброс пароля">
      <Container maxWidth="sm">
        <Stack direction="row" alignItems="center" sx={{mb: 2}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="h4" gutterBottom>
              Сброс пароля
            </Typography>
            {/*<Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>*/}
          </Box>
        </Stack>
        <ResetPasswordForm/>
      </Container>

    </RootStyle>
  );
}
export default ResetPassword
