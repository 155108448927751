import {EndpointDefinition} from "@reduxjs/toolkit/query"

import {TClientId, TCpeId, TLocationId, TTariffId} from "src/types/entityIds";
import {ISimCard as IAddClientSimCard} from "src/containers/DashboardLayout/mainEntitites/AddClientProcess/types";

import {RequireProps} from "src/utils/ts";
import {IHttpResponseBase} from "../types";
import {ICpeWithType} from "../ClientCpe/types";
import {IClient} from "../Client/types";
import {IClientLocation} from "../ClientLocation/types";
import {ITariff} from "../Tariff/types";
import {IMobileOperator} from "../Operator/types";
import {baseQueryWithReauth} from "../base";
import {IMobileInterface} from "../CpeInterface/types";

export type ISimCard = IAddClientSimCard

export type ISimCardAncestors = [
    ICpeWithType,
    IClientLocation,
    IClient,
]

export type IGetSimCardsProps =
    & {
        onlyAssigned: boolean
    }
    & (
        | {}
        | { clientId: TClientId }
        | { locationId: TLocationId }
        | { cpeId: TCpeId }
    )

export type IGetSimCardsOfClientProps =
    & {
        onlyAssigned: boolean
    }
    & (
        { clientId: TClientId }
    )

export type WithTariffRaw<T extends {}> = T & {
    tariff: ITariff | null
}

export type WithOperatorRaw<T extends {}> = T & {
    operator: string | IMobileOperator | null
}

export type WithMobileInterfaceRaw<T extends {}> = T & {
    interface: IMobileInterface | null
}

// Do not delete! TODO
export type DependOnTariffOperator<Param extends IGetSimCardsProps, T extends {}> =
    Param extends {withTariff: true}
        ? Param extends {withOperator: true}
            ? WithOperatorRaw<WithTariffRaw<T>>[]
            : WithTariffRaw<T>[]
        : Param extends {withOperator: true}
            ? WithOperatorRaw<T>[]
            : T[]

// Do not delete! TODO
export type STT<Param extends IGetSimCardsProps, T extends {}> =
    EndpointDefinition<
        Param,
        typeof baseQueryWithReauth,
        string,
        IHttpResponseBase<
            DependOnTariffOperator<Param, T>
        >
    >


export interface IAssignSimCardToCpeProps {
    cpeId: TCpeId
    simCard: ISimCard
    // Used in AddClient store redux
    tariffId?: TTariffId
}

// Look for MobileRadioStatusEnum in backend
export enum MobileRadioStatus {
    connected = 'connected',
    disconnected = 'disconnected',
    unknown = 'unknown',
    failed = 'failed',
}

export const MobileRadioStatusLang = {
    [MobileRadioStatus.connected]: 'подключен',
    [MobileRadioStatus.disconnected]: 'отключен',
    [MobileRadioStatus.unknown]: 'неизвестен',
    [MobileRadioStatus.failed]: 'ошибка',
}

export interface ISimCardInApiBase {
    balance: number
    balanceUpdatedAt?: string // ISO date
    nextPaymentDate?: string // ISO date
    cpeIdAssignedTo: number | null
    cpeTypeLocationIdAssignedTo?: number
    createdAt: string // ISO date
    iccid?: string
    id: number
    imsi?: string
    linkup: boolean | null
    mobileRadioStatus: MobileRadioStatus | null
    msisdn?: string
    ownedByClientId?: number
    ownedByPartnerId?: number
    providerTariffId?: number
    unfilled: boolean
    updatedAt: string | null // ISO date
}

export interface IAssignSimCardToCpe extends ISimCardInApiBase {
    // We don't have special entity for SimCard <-> Cpe relation
    // Use SimCard.updatedAt instead because the field is changed
    // on assigning
    updatedAt: string // ISO date
}

export interface ISimCardWithAncestors extends ISimCardInApiBase {
    ancestors: ISimCardAncestors,
}

// Cartesian Prods of types. Here boolean iteration (001, 010, 011, ...):
// msisdn, imsi, iccid
type ISimCardAnyIdent = RequireProps<ISimCardInApiBase, 'iccid'>
    | RequireProps<ISimCardInApiBase, 'imsi'>
    | RequireProps<ISimCardInApiBase, 'imsi' | 'iccid'>
    | RequireProps<ISimCardInApiBase, 'msisdn'>
    | RequireProps<ISimCardInApiBase, 'msisdn' | 'iccid'>
    | RequireProps<ISimCardInApiBase, 'msisdn' | 'imsi'>
    | RequireProps<ISimCardInApiBase, 'msisdn' | 'imsi' | 'iccid'>
interface ISimCardDiscovered extends ISimCardInApiBase {
    unfilled: true
}
interface ISimCardAssigned extends
        RequireProps<ISimCardInApiBase, 'providerTariffId'> {
    unfilled: false
}
type ISimCardAssigning = ISimCardDiscovered | ISimCardAssigned

export type ISimCardInApi = ISimCardAnyIdent & ISimCardAssigning

