import React from "react";
import {motion} from 'framer-motion';
import {Link as RouterLink} from "react-router-dom";
import {styled} from '@mui/material/styles';
import {Button, Stack, Container, Typography, Card} from '@mui/material';
import Page from '../components/Page';
import {MHidden} from '../external/minimal/components/@material-extend';
import LogoWithText from '../components/LogoWithText';
import {PATH_DASHBOARD} from "../routes/path";
import {MotionContainer, varBounceIn} from '../external/minimal/components/animate';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({theme}) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));


const ContentStyle = styled('div')(({theme}) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

const Page404: React.FC = () => {
  // const { method } = useAuth();

  return (
    <RootStyle title="404">
      <MHidden width="mdDown">
        <SectionStyle>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}>
            {/*<img src="/green_logo_netegis.png" width="95%" alt="netegis logo"/>*/}
            <LogoWithText sx={{width: '95%'}}/>
          </Stack>

        </SectionStyle>
      </MHidden>


      <Container maxWidth="sm">
        <MotionContainer initial="initial" open>
          <ContentStyle>
            <MHidden width="mdUp">
              <Stack direction="row" alignItems="center" justifyContent="center">
                <LogoWithText sx={{width: '80%', mb: 3}}/>
              </Stack>
            </MHidden>
            <Container maxWidth="lg">
              <Card sx={{p: 3}}>
                <Stack direction="column" alignItems="center" justifyContent="center" sx={{mb: 0}}>
                  <motion.div variants={varBounceIn}>
                    <Typography variant="h4" sx={{mb: 1}}>
                      404 - страница не найдена
                    </Typography>
                  </motion.div>
                  <Button sx={{textTransform: 'none'}} to={PATH_DASHBOARD.general.dashboard} size="small"
                          variant="contained" component={RouterLink}>
                    на главную
                  </Button>
                </Stack>
              </Card>
            </Container>
          </ContentStyle>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
export default Page404
