import {FieldName} from "src/containers/DashboardLayout/formFields/LocationFields/types";
import {TClientId, TLocationId} from "src/types/entityIds";

import {emptySplitApi} from "../base";
import {
    IAddClientProps,
    IChangeClientLocationProps,
    IClientLocationApiResponse,
    IClientLocationsApiResponse,
    IGetLocationsWithCountersProps,
    ILocationWithCounters,
} from "./types";
import {IHttpResponseBase} from "../types";

const clientLocationApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        addClientLocation: build.mutation<IClientLocationApiResponse, IAddClientProps>({
            query: ({
                clientId,
                locationData: {
                    locationAddress,
                    locationName,
                    lat,
                    lon,
                },
            }) => ({
                url: `/client/${clientId}/location`,
                method: 'POST',
                body: {
                    default: false,
                    address: locationAddress,
                    name: locationName,
                    lat: lat,
                    lon: lon,
                },
            })
        }),
        addDefaultClientLocation: build.mutation<IClientLocationApiResponse, TClientId>({
            query: (clientId) => ({
                url: `/client/${clientId}/location`,
                method: 'POST',
                body: {
                    default: true,
                },
            })
        }),
        getClientLocations: build.query<IClientLocationsApiResponse, TClientId>({
            query: (clientId) => `/client/${clientId}/location`,
        }),
        getLocationsWithCounters: build.query<IHttpResponseBase<ILocationWithCounters[]>, IGetLocationsWithCountersProps>({
            query: (params) => {
                const url = '/location?withCounters=true'
                if ('clientId' in params) {
                    return `/client/${params.clientId}${url}`
                }
                return url
            },
        }),
        getClientLocation: build.query<IClientLocationApiResponse, TLocationId>({
            query: (locationId) => `/location/${locationId}`,
        }),
        changeClientLocation: build.mutation<IClientLocationApiResponse, IChangeClientLocationProps>({
            query: (values) => ({
                url: `/location/${values.locationId}`,
                method: 'PUT',
                body: {
                    name: values[FieldName.locationName],
                    address: values[FieldName.locationAddress],
                },
            }),
        }),
        // getLocationWithAncestors: build.query<IClientLocationWithAncestorsApiResponse, TLocationId>({
        //     query: (locationId) => `/location/${locationId}?withAncestors`
        // }),
    })
})

export const {
    endpoints: {
        addClientLocation,
        addDefaultClientLocation,
        // assignCpeToClientLocation,
    },
    // useAssignCpeToClientLocationMutation,
    useAddClientLocationMutation,
    useAddDefaultClientLocationMutation,
    useGetClientLocationsQuery,
    useGetClientLocationQuery,
    useChangeClientLocationMutation,
    // useGetLocationWithAncestorsQuery,
    useGetLocationsWithCountersQuery,
} = clientLocationApi
