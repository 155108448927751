import {UseQuery} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {OptionalProps} from "./ts";

type useQueryResult = ReturnType<UseQuery<any>>
type requiredQueryResultFields = OptionalProps<
    Pick<
        useQueryResult,
        'error' | 'isFetching' | 'isError'
    >,
    'error'
>

export const getCombinedParamsFromRtkqHook = (
    hooks: [requiredQueryResultFields, ...requiredQueryResultFields[]],
): Required<requiredQueryResultFields> => {
    const first = {
        isError: hooks[0].isError,
        isFetching: hooks[0].isFetching,
        error: hooks[0].error,
    }
    return hooks
        .slice(1)
        .reduce<Required<requiredQueryResultFields>>(
            (prev, curHook) => ({
                isError: prev.isError || curHook.isError,
                isFetching: prev.isFetching || curHook.isFetching,
                error: prev.error || curHook.error,
            }),
            first,
        )
}
