import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField} from "mui-rff";
import * as Yup from 'yup';
import React, {useState} from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import {Alert, IconButton, InputAdornment, Link, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Icon} from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {useSnackbar} from "notistack";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../defs/forms";
import {FieldName, FormData} from "./types";
import {canStepNextInWizard} from "../../../utils/forms";
import {useConfirmPasswordMutation} from "../../../api/User/User";
import {getErrorMsgByErrorForConfirmPassword} from "../utils";
import {IConfirmPasswordProps} from '../../../api/User/types'
import {PATH_DASHBOARD} from "../../../routes/path";


Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.newPassword]: Yup.string().required(),
  [FieldName.confirmPassword]: Yup.string().oneOf([Yup.ref(`${FieldName.newPassword}`), null], 'Пароли должны совпадать').required()
})

const validate = makeValidate(schema)
const required = makeRequired(schema)

interface INewPasswordFormProps {
  uuid: string
}


const NewPasswordForm: React.FC<INewPasswordFormProps> = ({uuid}) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showNewPassword, toggleShowNewPassword] = useState(false);
  const [showConfirmPassword, toggleShowConfirmPassword] = useState(false);
  const [
    confirmPassword,
    {
      error: confirmPasswordError,
      isError: confirmPasswordIsError,
      isLoading: confirmPasswordIsLoading
    }
  ] = useConfirmPasswordMutation()
  const handleShowNewPassword = () => {
    toggleShowNewPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    toggleShowConfirmPassword((show) => !show);
  };


  const fields = [
    <TextField
      fullWidth
      label='Новый пароль'
      type={showNewPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.newPassword}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowNewPassword} edge="end">
              <Icon icon={showNewPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
    <TextField
      fullWidth
      label='Повторите новый пароль'
      type={showConfirmPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.confirmPassword}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowConfirmPassword} edge="end">
              <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
  ]

  const onSubmit  = async (values: FormData) => {
    console.log('confirmPassword req')
    // navigate(PATH_AUTH.otpEnter, { replace: true })
    const dataForSendToAPI: IConfirmPasswordProps = {
      password: values[FieldName.newPassword],
      uuid: uuid
    }
    console.log('confirmPassword.onSubmit', dataForSendToAPI)
    const returnedFromAPIVal = await confirmPassword(dataForSendToAPI);
    // console.log(returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
        console.log('confirmPassword.onSubmit.error')
      } else {
        console.log('confirmPassword.onSubmit.success')
        enqueueSnackbar('Пароль успешно изменен. Войдите используя новый пароль', {variant: 'success', autoHideDuration: 5000});
        navigate(PATH_DASHBOARD.general.dashboard, {replace: false})
      }
  }
  return <Form<FormData>
    onSubmit={onSubmit}
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      return <form
        onSubmit={props.handleSubmit}
        noValidate
      >
        <div>
          <Stack spacing={3}>
            {(confirmPasswordError || confirmPasswordIsError) &&
              <Alert severity="error">{getErrorMsgByErrorForConfirmPassword(confirmPasswordError)}</Alert>}
            {fields.map((field) => (
              <div
                key={field.props.name}
                // xs={12}
              >
                {field}
              </div>
            ))}
          </Stack>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
          <Link component={RouterLink} variant="subtitle2" to={PATH_DASHBOARD.general.dashboard}>
            На главную
          </Link>
        </Stack>
        <LoadingButton
          sx={{textTransform: 'none'}}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={confirmPasswordIsLoading}
          disabled={
            !canStepNextInWizard(props)
          }

        >
          Изменить пароль
        </LoadingButton>
      </form>
    }}
  />

}
export default NewPasswordForm
