import React from "react";
import {styled} from "@mui/material/styles";
import {Card, Link} from "@mui/material";
import {useNavigate} from 'react-router-dom';


const GoBackLinkStyled = styled(Card)(({theme}) => ({
  cursor: 'pointer'
}));

const GoBackLink: React.FC = () => {
  const navigate = useNavigate();
  return (
    <GoBackLinkStyled>
      <Link variant="subtitle2" onClick={() => navigate(-1)}>
        Назад
      </Link>
    </GoBackLinkStyled>
  );
}
export default GoBackLink
