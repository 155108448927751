import React from "react";
import {Typography, Stack} from "@mui/material/";
import StyledCardForBalanceHistory from './StyledCardForBalanceHistory'
import BalanceHistoryTable from "./BalanceHistoryTable";


interface IBalanceHistoryCardProps {

}

const BalanceHistoryCard: React.FC<IBalanceHistoryCardProps> = () => {
  // const {
  //   data: netegisDetailsData,
  //   isFetching: netegisDetailsIsFetching,
  //   isError: netegisDetailsIsError,
  // } = useGetNetegisDetailsQuery(undefined, {refetchOnMountOrArgChange: true})


  return (
    <>
      <StyledCardForBalanceHistory>
        <Stack>
          <Stack sx={{overflow: 'auto'}} justifyContent={'center'} alignItems={'center'}>
            <Typography sx={{width: 1}} variant={'h5'}>
              История баланса
            </Typography>
            <BalanceHistoryTable/>
          </Stack>
        </Stack>

      </StyledCardForBalanceHistory>
    </>
  )
}


export default BalanceHistoryCard