import React from "react";
import {styled} from '@mui/material/styles';
import {Card, Typography, Box, Stack, useTheme, CardActionArea, Skeleton} from '@mui/material';
import { Icon } from '@iconify/react';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill'
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill'
import homeFill from '@iconify/icons-eva/home-fill'
import {StatusesOfDevices} from '../types'


const CardActionAreaStyled = styled(CardActionArea)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3)
}));

// ----------------------------------------------------------------------

const ICONS_WIDTH_AND_HEIGHT = 30
const ICONS_STROKE_WIDTH_AND_HEIGHT = 70

interface IStatusCardProps {
  type: StatusesOfDevices
  amount?: number,
  clicked?: boolean,
  captionText?: string,
  isError?: boolean,
  error?: any,
  isLoadingData?: boolean,
  cardClickCallback: Function
}

const StatusCard: React.FC<IStatusCardProps> = ({type, amount, clicked,
                                                  captionText, isError, error,
                                                  isLoadingData, cardClickCallback}) => {
  const theme = useTheme();

  let color, bgcolor

  if (type === StatusesOfDevices.WORKING) {
    color = theme.palette.primary.dark
    bgcolor = theme.palette.primary.lighter
  } else if (type === StatusesOfDevices.NOT_WORKING) {
    color = theme.palette.error.dark
    bgcolor = theme.palette.error.lighter
  } else if (type === StatusesOfDevices.ALL) {
    color = theme.palette.info.dark
    bgcolor = theme.palette.info.lighter
  } else {
    color = theme.palette.background.neutral
    bgcolor = theme.palette.background.neutral
  }

  const getIconDependingOnTheType = (type: StatusesOfDevices) => {
    if (type === StatusesOfDevices.WORKING) {
      return checkmarkCircle2Fill
    } else if (StatusesOfDevices.NOT_WORKING) {
      return alertTriangleFill
    }
    return homeFill
  }

  const handleCardClick = ()=>{
    cardClickCallback(type)
  }

  return (
    <Card sx={{
      bgcolor: clicked ? bgcolor : '',
      color: (theme.palette.mode === "dark" && clicked) ? theme.palette.background.default : ''
    }}>
      <CardActionAreaStyled  onClick={handleCardClick}>
        <Box sx={{width: '90%'}}>
          {isLoadingData  ?
           <Box  sx={{width: "95%"}}>
             <Typography variant="h1"><Skeleton/></Typography>
             <Typography variant="subtitle2" sx={{color: 'text.secondary'}}><Skeleton /></Typography>

           </Box>
            :
            isError ?
            <div>
              <Box sx={{mb:1}}>
                <Typography variant="body1">Произошла ошибка загрузки данных
                  устройств: {JSON.stringify(error)}</Typography>
              </Box>
              <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                {(captionText)}
              </Typography>
            </div>
            :
            <div>
              <Typography variant="h3">{(amount ? amount : 'Нет данных')}</Typography>
              <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                {(captionText)}
              </Typography>
            </div>


          }

        </Box>
        <Stack
          direction="row" alignItems="center" justifyContent="center"
          sx={{
            minWidth: ICONS_STROKE_WIDTH_AND_HEIGHT,
            width: ICONS_STROKE_WIDTH_AND_HEIGHT,
            height: ICONS_STROKE_WIDTH_AND_HEIGHT,
            lineHeight: 0,
            borderRadius: '50%',
            // bgcolor: 'background.neutral',
            color: theme.palette.primary.lighter,
            bgcolor: isLoadingData ? theme.palette.background.neutral : bgcolor,
          }}
        >
          {isLoadingData ?
            <Skeleton variant={'circular'} width={ICONS_STROKE_WIDTH_AND_HEIGHT}
                      height={ICONS_STROKE_WIDTH_AND_HEIGHT}/>
            :
            <Box>
              <Icon icon={getIconDependingOnTheType(type)} color={color} width={ICONS_WIDTH_AND_HEIGHT}
                      height={ICONS_WIDTH_AND_HEIGHT}/>
            </Box>
          }

        </Stack>
      </CardActionAreaStyled>
    </Card>

  );
}
export default StatusCard
