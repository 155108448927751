import React from "react";
import {Button, Grid, Box, Stack} from "@mui/material/";
import {Form} from "react-final-form";
// import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";
import {useTheme} from "@mui/material/styles";
import * as Yup from "yup";
import {DatePicker, makeRequired, makeValidate} from "mui-rff";
import {FFORMS_SUBSCRIPTION} from "../../../../../defs/forms";
import {canStepNextInWizard} from "../../../../../utils/forms";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../../../defs/validation";


interface CpeDateRangeCardProps {
  isLoading: boolean
  endDate: Date,
  startDate: Date,
  setEndDate: (newValue: Date) => unknown,
  setStartDate: (newValue: Date) => unknown,
}

enum FieldName {
  startDate = 'startDate',
  endDate = 'endDate',
}

interface FormData {
  [FieldName.startDate]: Date,
  [FieldName.endDate]: Date,
}

Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object()
  .shape({
    startDate: Yup.date().typeError('Введите корректную дату').required(),
    endDate: Yup
      .date()
      .typeError('Введите корректную дату')
      .min(
        Yup.ref(`${FieldName.startDate}`),
        "Конечная дата должна быть позднее начальной"
      )
      .notOneOf(
        [Yup.ref(`${FieldName.startDate}`)],
        "Даты не должны совпадать"
      )
      .required(),
  })

export const validate = makeValidate(schema)
const required = makeRequired(schema)

const DatePickerForBalanceHistory: React.FC<CpeDateRangeCardProps> = ({
                                                                        endDate,
                                                                        startDate,
                                                                        setEndDate,
                                                                        setStartDate,
                                                                        isLoading
                                                                      }) => {
  const theme = useTheme()

  const onSubmit = (values: FormData) => {
    setEndDate(values[FieldName.endDate])
    setStartDate(values[FieldName.startDate])
  }

  return (
    <Box sx={{mt: 1}}>
      <Form<FormData>
        initialValues={{startDate, endDate}}
        onSubmit={onSubmit}
        validate={validate}
        subscription={{
          ...FFORMS_SUBSCRIPTION,
          values: true,
          dirty: true,
        }}
        render={(props) => {

          const {
            dirty,
            handleSubmit,
          } = props
          return (<Stack
              component='form'
              spacing={1}
              onSubmit={handleSubmit}
              noValidate
            >
              <Box
              >
                {
                  <Grid container alignItems='top' direction='row' spacing={2}>
                    <Grid item container alignItems='top' wrap='nowrap' xs={12} lg={9} sx={{height: 1}}>
                      <DatePicker
                        inputFormat="dd.MM.yyyy"
                        mask='__.__.____'
                        disabled={isLoading}
                        label="Начальная дата"
                        name={FieldName.startDate}
                        required={required[FieldName.startDate]}
                        locale={ruLocale}
                        // showError={() => false}
                      />
                      <Box sx={{px: 1}}>
                        <Stack sx={{mt: 2}} direction={'column'} justifyContent={'space-around'}>
                          по
                        </Stack>
                      </Box>
                      <DatePicker
                        inputFormat="dd.MM.yyyy"
                        mask='__.__.____'
                        disabled={isLoading}
                        label="Конечная дата"
                        name={FieldName.endDate}
                        required={required[FieldName.endDate]}
                        locale={ruLocale}
                        // showError={() => false}
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <Stack sx={{[theme.breakpoints.down('lg')]: {mt: 0}, [theme.breakpoints.up('sm')]: {mt: 1.4},}}
                             direction={'column'} justifyContent={'space-around'}>
                        <Button
                          // fullWidth={true}
                          variant='contained'
                          color='primary'
                          type='submit'
                          disabled={
                            !canStepNextInWizard(props)
                            || isLoading
                            || !dirty
                          }
                        >
                          Выбрать
                        </Button>
                      </Stack>

                    </Grid>
                  </Grid>
                }
              </Box>
            </Stack>
          )
        }}
      />
    </Box>
  )
}

export default DatePickerForBalanceHistory