import {useGetUserQuery} from "src/api/User/User";
import {useAppSelector} from "src/app/hooks";
import UserStore from "src/redux/User";

interface IIsAuthenticatedReturnType {
    loggedIn: boolean
    checkingLogin: boolean
    isCheckingError: boolean
    checkingError?: any
}

export const useIsAuthenticated = (): IIsAuthenticatedReturnType => {
    const cachedUser = useAppSelector(({
        [UserStore.name]: {
            userInfo: user
        }
    }) => user)

    const userDefined = !!cachedUser?.id

    const {
        data,
        isFetching,
        isError,
        error,
    } = useGetUserQuery(
        undefined,
        {
            refetchOnMountOrArgChange: true,
            skip: userDefined,
        }
    )

    if (userDefined) {
        return {
            loggedIn: true,
            checkingLogin: false,
            isCheckingError: false,
            checkingError: undefined,
        }
    }

    // @ts-ignore Property 'status' does not exist on type 'SerializedError'
    const unauthenticated = isError && (error?.status === 401)
    return {
        loggedIn: userDefined && !unauthenticated && !!data?.data.id,
        checkingLogin: isFetching,
        isCheckingError: !unauthenticated && isError,
        checkingError: !unauthenticated
            ? error
            : undefined,
    }
}
