import React from 'react';

import {
  Stack,
  Grid,
  Box,
  Typography,
  Card,
} from '@mui/material';
import MyAvatar from "../../../../components/MyAvatar";
import {useAppSelector} from "../../../../app/hooks";
import UserStore from "../../../../redux/User";
import UserGeneralForm from "./UserGeneralForm";


const  UserGenral: React.FC = () => {


  const cachedUser = useAppSelector(({
        [UserStore.name]: {
            userInfo: user
        }
    }) => user)


  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{py: 10, px: 3, textAlign: 'center'}}>
            <MyAvatar sx={{ mx: 'auto', mb: 2,  width: 100, height: 100 }} />
                          <Box sx={{ml: 1, textOverflow: 'ellipsis', overflow: 'hidden'}} >
                <Typography noWrap variant="subtitle2" sx={{color: 'text.primary'}}>
                  {cachedUser?.username}
                </Typography>
                <Typography   noWrap variant="body2" sx={{color: 'text.secondary'}}>
                  {cachedUser?.email}
                </Typography>
              </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{p: 3}}>
            <Stack spacing={{xs: 2, md: 3}}>
              <UserGeneralForm/>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      </Box>

  );

}

export default UserGenral
