import React from "react";
import { Card } from "@mui/material/";


interface IStyledCardForBoardProps {

}

const StyledCardForBoard: React.FC<IStyledCardForBoardProps> = ({
  children,

}) => {
    return(
          <Card
            sx={{
              px: 2,
              width: 1,
              minHeight: '5em',
              position: 'relative',
              wordWrap: 'normal',
              boxShadow: (theme) => theme.customShadows.z8,
              '&:hover': {
                boxShadow: (theme) => theme.customShadows.z24
              }
            }}
          >
            {children}
          </Card>
    )
}


export default StyledCardForBoard