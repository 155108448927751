import React from "react";
import {Card} from "@mui/material/";

const StyledCardForDetailsForPayment: React.FC<{}> = ({
    children,
}) => {
  return (
    <Card
      sx={{
        mx: '20px',
        my: '10px',
        px: 3,
        pt: 3,
        pb: 4,
        // // width: '100%',
        // minWidth: '250px',
        // // width: 'calc(30% - 40px)',
        // [theme.breakpoints.down('sm')]: {width: '80%'},
        // [theme.breakpoints.up('sm')]: {width: '46%'},
        // wordWrap: 'normal',
        boxShadow: (theme) => theme.customShadows.z20,
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24
        }
      }}
    >
      {children}
    </Card>
  )
}


export default StyledCardForDetailsForPayment