import {TCpeId} from "src/types/entityIds";
import {emptySplitApi} from "../base";

import {
    AssignedFilter,
    CpeBackupType,
    ICpeWithAncestorsNTypeNProblem,
    ICpeWithCounters,
    IDashboardDataDowntime,
    IDataCpes,
    IDowntimeDataProps,
    IGetCpesProps,
    WithRawAnyProblem,
} from "./types";

import {IHttpResponseBase, ISpeedWithTimestamp, IMSecWithTimestampData} from "../types";

const clientCpeApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getCpes: build.query<IHttpResponseBase<ICpeWithCounters[]>, IGetCpesProps>({
            query: (params) => {
                const {assignedFilter} = params
                const filter = assignedFilter === AssignedFilter.onlyAssigned
                        ? 'onlyAssigned'
                    : assignedFilter === AssignedFilter.onlyNotAssigned
                        ? 'onlyNotAssigned'
                    : ''
                const url = `/cpe?withCounters&${filter}`
                if ('clientId' in params) {
                    return `/client/${params.clientId}${url}`
                }
                if ('locationId' in params) {
                    return `/location/${params.locationId}${url}`
                }
                return url
            },
            transformResponse: (
                resp: IHttpResponseBase<ICpeWithCounters[]>
            ) => ({
                ...resp,
                data: resp.data.map((cpe) => ({
                    ...cpe,
                    locationId: cpe.clientLocationId,
                    aggStatus: cpe.aggStatus,
                        // cpe.id === 24
                        //     ? {
                        //         wiredUplinks: [
                        //             {
                        //                 id: 23,
                        //                 interface: {
                        //                     id: 21,
                        //                     linkup: true,
                        //                 },
                        //                 assigned: true,
                        //             }
                        //         ],
                        //         mobileUplinks: [
                        //             {
                        //                 id: 33,
                        //                 interface: {
                        //                     id: 2,
                        //                     linkup: true,
                        //                     radioStatus: MobileRadioStatus.connected,
                        //                     error: null
                        //                 },
                        //                 assigned: true
                        //             },
                        //             {
                        //                 id: 34,
                        //                 interface: {
                        //                     id: 22,
                        //                     linkup: true,
                        //                     radioStatus: MobileRadioStatus.connected,
                        //                     error: null
                        //                 },
                        //                 assigned: true
                        //             },
                        //         ],
                        //         managementLinkup: true,
                        //         error: null,
                        //     }
                        //     :
                        //     {
                        //         wiredUplinks: [],
                        //         mobileUplinks: [],
                        //         managementLinkup: false,
                        //         error: null,
                        //     },
                    managementLinkup: cpe.managementInterfaceLinkup,
                }))
            }),
        }),
        getCpeWithAncestorsAndCounters: build.query<IHttpResponseBase<ICpeWithAncestorsNTypeNProblem>, TCpeId>({
            query: (cpeId) => ({
                url: `/cpe/${cpeId}`,
                params: {
                    withAncestors: true,
                    withCpeType: true,
                    withProblem: true,
                }
            }),
            transformResponse: (
                resp: IHttpResponseBase<WithRawAnyProblem<ICpeWithAncestorsNTypeNProblem>>
            ) => ({
                ...resp,
                data: {
                    ...resp.data,
                    backupType: resp.data.backupType === 'failover'
                        ? CpeBackupType.failover
                        : resp.data.backupType === 'aggregation'
                            ? CpeBackupType.aggregation
                            : undefined,
                    // TODO: обсудить
                    // updatedAt: isDefined(resp.data.updatedAt)
                    //     // @ts-ignore TS2769: Type 'undefined' is not assignable to type 'string | number'.
                    //     ? new Date(resp.data.updatedAt)
                    //     : resp.data.updatedAt,
                    // createdAt: isDefined(resp.data.createdAt)
                    //     ? new Date(resp.data.createdAt)
                    //     : resp.data.createdAt,
                    aggStatus: resp.data.aggStatus
                        // resp.data.id === 24
                        // ? {
                        //         wiredUplinks: [
                        //             {
                        //                 id: 23,
                        //                 interface: {
                        //                     id: 21,
                        //                     linkup: true,
                        //                 },
                        //                 assigned: true,
                        //             }
                        //         ],
                        //         mobileUplinks: [
                        //             {
                        //                 id: 33,
                        //                 interface: {
                        //                     id: 2,
                        //                     linkup: true,
                        //                     radioStatus: MobileRadioStatus.connected,
                        //                     error: null
                        //                 },
                        //                 assigned: true
                        //             },
                        //             {
                        //                 id: 34,
                        //                 interface: {
                        //                     id: 22,
                        //                     linkup: true,
                        //                     radioStatus: MobileRadioStatus.connected,
                        //                     error: null
                        //                 },
                        //                 assigned: true
                        //             },
                        //         ],
                        //         managementLinkup: true,
                        //         error: null,
                        //     }
                        // :
                        // {
                        //     wiredUplinks: [],
                        //     mobileUplinks: [],
                        //     managementLinkup: false,
                        //     error: null,
                        // },
                },
            })
        }),
        getDashboardDataCpes: build.query<IHttpResponseBase<IDataCpes>, void>({
            query: () => '/cpe/stats',
        }),
        getDashboardDataDowntime: build.query<IHttpResponseBase<IDashboardDataDowntime>, IDowntimeDataProps>({
            query: ({
                timeStampFromSec,
                timeStampToSec,
            }) => ({
                url: '/cpe/stats/downtime',
                params: {
                    from: timeStampFromSec,
                    to: timeStampToSec,
                },
            }),
        }),
        getCpeRttSeq: build.query<IHttpResponseBase<IMSecWithTimestampData[]>, TCpeId>({
            query: (cpeId) => `/cpe/${cpeId}/rtt`
        }),
        getCpeSpeedSeq: build.query<IHttpResponseBase<ISpeedWithTimestamp[]>, TCpeId>({
            query: (cpeId) => `/cpe/${cpeId}/speed`
        }),

    })
})

export const {
    useGetCpesQuery,
    useGetCpeWithAncestorsAndCountersQuery,
    useGetDashboardDataCpesQuery,
    useGetDashboardDataDowntimeQuery,
    useGetCpeRttSeqQuery,
    useGetCpeSpeedSeqQuery

} = clientCpeApi
