// routes
import { PATH_DASHBOARD } from '../../routes/path';
// components
import SvgIconStyle from '../../external/minimal/components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  dashboard: getIcon('ic_dashboard'),
  map:  getIcon('ic_map')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'МЕНЮ',
    items: [
      {
        title: 'Сводка',
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard
      },
      { title: 'Устройства', path: PATH_DASHBOARD.general.devices, icon: ICONS.map },
      { title: 'Заявки', path: PATH_DASHBOARD.general.requests, icon: ICONS.calendar },
      // { title: 'Тариф и оплата', path: PATH_DASHBOARD.general.tariffs, icon: ICONS.ecommerce },
      // { title: 'ЛК пользователя', path: PATH_DASHBOARD.general.account, icon: ICONS.user }
    ]
  },
];

export default sidebarConfig;
