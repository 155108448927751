import React from 'react';

import {
  Stack,
  Card,
} from '@mui/material';
import Page from "../../../../components/Page";
import ChangePasswordForm from "./ChangePasswordForm";





const  Passwords: React.FC = () => {

  return (
    <Page title="Пароли">

      <Card sx={{minHeight: '100px'}}>
        <Stack direction={'column'} alignItems={'left'} sx={{p: 3}}>
          {/*<Typography variant="h2">Пароли</Typography>*/}
          <ChangePasswordForm/>
        </Stack>
      </Card>

    </Page>

  );

}

export default Passwords
