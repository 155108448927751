import React from "react";
import {Typography, Stack} from "@mui/material/";
import {IClientTariff} from 'src/api/ClientTariff/types'



interface ITariffDetailsBlockProps {
  tariff: IClientTariff;
}

const TariffDetailsBlock: React.FC<ITariffDetailsBlockProps> = ({
                                                  tariff
                                                }) => {

  return (

      <Stack direction={'column'} justifyContent={'space-between'} height={1}>
        <Stack direction={'column'} height={1}>
          <Typography sx={{mb:1}} variant={'body2'}><b>Название:</b></Typography>
          <Typography sx={{mb: 3, mt:0}} variant={'h3'}>{tariff.title}</Typography>
          <Typography sx={{mb:1}} variant={'body2'}><b>Описание тарифа:</b></Typography>
          <Typography sx={{maxHeight: '60vh', overflowY: 'auto', mb: 2}} variant={'body2'}>{tariff.description}</Typography>
          <Typography variant={'body2'}><b>Цена: </b> </Typography>
          <Typography sx={{mb: 2}} variant={'h5'}>{tariff.price}</Typography>

        </Stack>

      </Stack>

  )
}


export default TariffDetailsBlock