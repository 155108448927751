import React from "react";
import {Typography, Stack, Skeleton} from "@mui/material/";
import StyledCardForTariff from "./StyledCardForTariff";



interface ITarrifCardSkeletonProps {

}

const TarrifCardSkeleton: React.FC<ITarrifCardSkeletonProps> = () => {
  return (
    <StyledCardForTariff>
      <Stack direction={'column'} height={1}>
        <Typography variant={'h3'}><Skeleton/></Typography>
        {Array.from(Array(7).keys()).map((count) => {
          return (
            <Typography key={count} variant={'body1'}><Skeleton/></Typography>
          );
        })}

        <Stack sx={{position: 'absolute', bottom: '30px', left: '0px', width: '100%'}} direction={'row'}
               justifyContent={'center'}>
          <Typography width={'70%'} alignItems={'center'} variant={'h2'}><Skeleton/></Typography>
        </Stack>
      </Stack>
    </StyledCardForTariff>
  )
}


export default TarrifCardSkeleton