import React from "react";


interface IOkIconProps {
  circleFillColor?: string,
  iconFillColor?: string,
  height?: string
  width?: string,
  withStroke?: boolean,
  strokeColor?: string
}

const OkIcon: React.FC<IOkIconProps> = ({circleFillColor, iconFillColor, height, width, withStroke, strokeColor}) => {
  return (
    !withStroke ?
      <svg width={width ? width : '48'} height={height ? height : '48'} viewBox="0 0 48 48" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill={circleFillColor ? circleFillColor : "#C8FACD"}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M14.6665 24.1334C14.6665 18.9787 18.8452 14.8 23.9998 14.8C26.4752 14.8 28.8492 15.7834 30.5995 17.5337C32.3498 19.2841 33.3332 21.658 33.3332 24.1334C33.3332 29.288 29.1545 33.4667 23.9998 33.4667C18.8452 33.4667 14.6665 29.288 14.6665 24.1334ZM23.7479 27.5027L28.0132 21.9027V21.8747C28.2166 21.6079 28.2622 21.2529 28.1329 20.9434C28.0036 20.6338 27.719 20.4168 27.3863 20.374C27.0535 20.3313 26.7233 20.4693 26.5199 20.736L22.9919 25.4027L21.4705 23.4613C21.2655 23.1979 20.9354 23.0639 20.6048 23.1098C20.2741 23.1557 19.993 23.3745 19.8674 23.6838C19.7418 23.9931 19.7908 24.3459 19.9959 24.6093L22.2732 27.512C22.4513 27.7374 22.7233 27.8682 23.0105 27.8667C23.2994 27.866 23.5717 27.7316 23.7479 27.5027Z"
              fill={iconFillColor ? iconFillColor : "#007B55"}/>
      </svg>
      :
      <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '48'} height={height ? height : '48'}  viewBox="0 0 64 64">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path d="M0,32A32,32,0,0,1,32,0h0A32,32,0,0,1,64,32h0A32,32,0,0,1,32,64h0A32,32,0,0,1,0,32Z" fill={strokeColor ? strokeColor : 'white'}/>
              <circle cx="32" cy="32" r="24"  fill={circleFillColor ? circleFillColor : "#FFE7D9"} />
              <path
                d="M22.67,32.13A9.33,9.33,0,1,1,32,41.47,9.33,9.33,0,0,1,22.67,32.13Zm9.08,3.37L36,29.9v0a.92.92,0,0,0,.12-.93.93.93,0,0,0-1.61-.2L31,33.4l-1.52-1.94A.93.93,0,1,0,28,32.61l2.27,2.9a.93.93,0,0,0,.74.36A.94.94,0,0,0,31.75,35.5Z"
                 fill={iconFillColor ? iconFillColor : "#B72136"} style={{fillRule: 'evenodd'}}/>
            </g>
          </g>
        </g>
      </svg>



  );
}
export default OkIcon