function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/confirm-password'),
  registration: path(ROOTS_AUTH, '/registration'),
  otpEnter: path(ROOTS_AUTH, '/otp-enter'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/main'),
    devices: path(ROOTS_DASHBOARD, '/devices'),
    requests: path(ROOTS_DASHBOARD, '/requests'),
    // tariffs: path(ROOTS_DASHBOARD, '/tariffs'),
    // account: path(ROOTS_DASHBOARD, '/account'),
    user: {
      main: path(ROOTS_DASHBOARD, '/user'),
      general: path(ROOTS_DASHBOARD, '/user/general'),
      tariffs: path(ROOTS_DASHBOARD, '/user/tariffs'),
      notifications: path(ROOTS_DASHBOARD, '/user/notifications'),
      passwords: path(ROOTS_DASHBOARD, '/user/passwords')
    }
  },
};