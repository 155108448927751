import React from "react";
import {Container, Grid} from '@mui/material';

import useSettings from 'src/external/minimal/hooks/useSettings';
import Page from 'src/components/Page';
import {useGetClientDataForCurrentUserQuery} from "src/api/Client/Client";

import CpeCard from "./CpeCard";
import WelcomeCard from "./WelcomeCard";
import OnboardingCard from "./OnboardingCard";
import RequestsCard from "./RequestsCard";

const Dashboard: React.FC = () => {
  const {themeStretch} = useSettings();

  const {
    data: clientDataQuery,
    isLoading: clientIsLoading,
    isError: clientIsError,
    error: clientError,
  } = useGetClientDataForCurrentUserQuery(
      undefined, {refetchOnMountOrArgChange: true}
  )

  const clientData = clientDataQuery?.data!

  return (
    <Page title="Сводка">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <WelcomeCard
                data={clientData}
                isLoading={clientIsLoading}
                isError={clientIsError}
                error={clientError}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <OnboardingCard />
          </Grid>
          <Grid item xs={12}>
            <RequestsCard
              clientData={clientData}
              clientIsLoading={clientIsLoading}
              clientIsError={clientIsError}
              clientError={clientError}
            />
          </Grid>
          <Grid item xs={12}>
            <CpeCard/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
export default Dashboard