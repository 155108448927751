import React from "react";
import {Typography, Stack, Box} from "@mui/material/";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {LoadingButton} from "@mui/lab";
import {IClientTariff} from 'src/api/ClientTariff/types'
import StyledCardForTariff from "./StyledCardForTariff";


interface ITarrifCardProps {
  tariff: IClientTariff;
  currentTariff: boolean,
  selectTariffCallback: Function,
  showDeatilsCallback: Function,
  disableChangeTariffButton: boolean
}

const TarrifCard: React.FC<ITarrifCardProps> = ({
                                                  tariff,
                                                  currentTariff,
                                                  selectTariffCallback,
                                                  showDeatilsCallback,
                                                  disableChangeTariffButton
                                                }) => {
  const handleButtonClick = () => {
    selectTariffCallback(tariff.id)
  }
  const handleClickDetails = () => {
    showDeatilsCallback(tariff.id)
  }
  return (
    <StyledCardForTariff>
      <Stack direction={'column'} justifyContent={'space-between'} height={1}>
        <Stack direction={'column'} height={'80%'}>
          <Typography sx={{mb: 3}} variant={'h3'}>{tariff.title}</Typography>
          <Typography sx={{maxHeight: '80%', overflowY: 'auto', mb: 1}}
                      variant={'body1'}>{tariff.description}</Typography>
        </Stack>
        <Stack direction={'column'} height={'20%'} justifyContent={'flex-end'} alignItems={'center'}>
          <Stack  sx={{ mb: 3, width: 1}} direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
            <Box sx={{width: '50%', textAlign:'left'}}>
              <Typography component={'span'} variant={'body1'}><Typography component={'span'}
                                                                           sx={{fontStyle: 'italic'}}>Цена</Typography> {tariff.price}
              </Typography>
            </Box>
            <Box sx={{width: '50%', textAlign:'right', cursor: 'pointer'}} onClick={handleClickDetails}>
              <Stack direction={'row'} alignItems={'start'} width={'100%'} justifyContent={'flex-end'}>
                <Typography sx={{fontStyle: 'italic'}} variant={'body1'}> Подробнее </Typography> <ArrowForwardIosIcon
                sx={{position: 'relative', top: '1px'}} fontSize={'small'}/>
              </Stack>
            </Box>
          </Stack>

          {
            currentTariff ?
              <LoadingButton
                loading={disableChangeTariffButton}
                disableRipple={true}
                color="primary"
                variant="contained"
                size={'medium'}
                sx={{width: 1, cursor: 'default', textTransform: 'none'}}
              >
                Мой тариф
              </LoadingButton>
              :
              <LoadingButton
                loading={disableChangeTariffButton}
                color="primary"
                variant="outlined"
                size={'medium'}
                onClick={handleButtonClick}
                sx={{width: 1, textTransform: 'none'}}
              >
                Перейти на этот план
              </LoadingButton>
          }
        </Stack>
      </Stack>
    </StyledCardForTariff>
  )
}


export default TarrifCard