import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField} from "mui-rff";
import * as Yup from 'yup';
import React, {useState} from 'react';
import {Alert, IconButton, InputAdornment, Stack} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Icon} from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import {useSnackbar} from "notistack";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../../defs/forms";
import {FieldName, FormData} from "./types";
import {canStepNextInWizard} from "../../../../utils/forms";
import {useChangePasswordForLoginedUserMutation} from "../../../../api/User/User";
import {getErrorMsgByErrorForChangePassword} from "./utils";
import {IChangePasswordForLoginedUserProps} from '../../../../api/User/types'
// import {PATH_DASHBOARD} from "../../../../routes/path";
// import {useLogoutMutation} from "src/api/User/User";


Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.oldPassword]: Yup.string().required(),
  [FieldName.newPassword]: Yup.string().required(),
  [FieldName.confirmPassword]: Yup.string().oneOf([Yup.ref(`${FieldName.newPassword}`), null], 'Новые пароли должны совпадать').required()
})

const validate = makeValidate(schema)
const required = makeRequired(schema)

interface IChangePasswordFormProps {

}


const ChangePasswordForm: React.FC<IChangePasswordFormProps> = () => {
  const { enqueueSnackbar } = useSnackbar();
  // const navigate = useNavigate();
  const [showNewPassword, toggleShowNewPassword] = useState(false);
  const [showConfirmPassword, toggleShowConfirmPassword] = useState(false);
  const [showOldPassword, toggleShowOldPassword] = useState(false);
  const [
    changePassword,
    {
      error: changePasswordError,
      isError: changePasswordIsError,
      isLoading: changePasswordIsLoading
    }
  ] = useChangePasswordForLoginedUserMutation()

  // const [
  //   logoutRequest,
  //   logoutMutationResult
  // ] = useLogoutMutation()

  const handleShowNewPassword = () => {
    toggleShowNewPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    toggleShowConfirmPassword((show) => !show);
  };
  const handleShowOldPassword = () => {
    toggleShowOldPassword((show) => !show);
  };



  const fields = [
    <TextField
      fullWidth
      label='Старый пароль'
      type={showOldPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.oldPassword}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowOldPassword} edge="end">
              <Icon icon={showOldPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
    <TextField
      fullWidth
      label='Новый пароль'
      type={showNewPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.newPassword}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowNewPassword} edge="end">
              <Icon icon={showNewPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
    <TextField
      fullWidth
      label='Повторите новый пароль'
      type={showConfirmPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.confirmPassword}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowConfirmPassword} edge="end">
              <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
  ]

  const onSubmit  = async (values: FormData) => {
    console.log('confirmPassword req')
    // navigate(PATH_AUTH.otpEnter, { replace: true })
    const dataForSendToAPI: IChangePasswordForLoginedUserProps = {
      oldPassword: values[FieldName.oldPassword],
      newPassword: values[FieldName.newPassword]
    }
    console.log('confirmPassword.onSubmit', dataForSendToAPI)
    const returnedFromAPIVal = await changePassword(dataForSendToAPI);
    console.log('returnedFromAPIVal', returnedFromAPIVal)
    // console.log(returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
        console.log('confirmPassword.onSubmit.error')
      } else {
        console.log('confirmPassword.onSubmit.success')
        enqueueSnackbar('Пароль успешно изменен', {variant: 'success', autoHideDuration: 5000});
        // await logoutRequest()
        // navigate(PATH_DASHBOARD.general.dashboard, {replace: false})
      }
  }
  return <Form<FormData>
    onSubmit={onSubmit}
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      const {handleSubmit, form} = props
      return <form
        onSubmit={async event => {
          await handleSubmit(event)?.then(() => {
              form.restart()
          })
        }
      }
        noValidate
      >
        <div>
          <Stack spacing={3}>
            {(changePasswordError || changePasswordIsError) &&
              <Alert severity="error">{getErrorMsgByErrorForChangePassword(changePasswordError)}</Alert>}
            {fields.map((field) => (
              <div
                key={field.props.name}
                // xs={12}
              >
                {field}
              </div>
            ))}
          </Stack>
        </div>
        <Stack direction="row" width={'100%'} justifyContent="flex-end" sx={{my: 2}}>
          {/*<Link component={RouterLink} variant="subtitle2" to={PATH_DASHBOARD.general.dashboard}>*/}
          {/*  На главную*/}
          {/*</Link>*/}
          <LoadingButton
            sx={{textTransform: 'none'}}
            type="submit"
            variant="contained"
            loading={changePasswordIsLoading}
            disabled={
              !canStepNextInWizard(props)
            }

          >
            Изменить
          </LoadingButton>
        </Stack>

      </form>
    }}
  />

}
export default ChangePasswordForm
