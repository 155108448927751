import {emptySplitApi} from "../base";
import {INetegisDetailsApiResponse} from "./types";



export const netegisDetailstApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getNetegisDetails: build.query<INetegisDetailsApiResponse, void>({
            query: () => ({
                url: '/netegis_details',
            }),
        }),
    }),
})

export const {
    useGetNetegisDetailsQuery,
} = netegisDetailstApi
