import React from "react";
import {Box, BoxProps, Grid} from '@mui/material';
import {styled} from "@mui/material/styles";
import {TaskStatusEnum} from "../../../api/Task/types";

interface IStatusRectanglesProps {
  status: TaskStatusEnum
  sx?: BoxProps,
  height?: string,
}

const styledBox = styled(Box)(({theme}) => ({
  width: '100%',
  height: '100%'
}));

const StyledBlockFirst = styled(styledBox)(({theme}) => ({
  backgroundColor: theme.palette.primary.lighter
}));
const StyledBlockSecond = styled(styledBox)(({theme}) => ({
  backgroundColor: theme.palette.primary.light
}));
const StyledBlockLast= styled(styledBox)(({theme}) => ({
  backgroundColor: theme.palette.primary.main
}));


const StatusRectangles: React.FC<IStatusRectanglesProps> = ({status, height, sx}) => {
  const heightForRectangles = height ? height : '0.3em'
  return (
    <Box sx={{...sx, height: heightForRectangles}}>
      <Grid container columnSpacing={{xs: 1}} sx={{height: heightForRectangles}}>
        <Grid item sx={{height: heightForRectangles}} xs={4}>
          <StyledBlockFirst/>
        </Grid>
        <Grid item sx={{height: heightForRectangles}} xs={4}>
          {(status === TaskStatusEnum.IN_WORK || status === TaskStatusEnum.CLOSED) && <StyledBlockSecond/>}
        </Grid>
        <Grid item sx={{height: heightForRectangles}} xs={4}>
          {status === TaskStatusEnum.CLOSED && <StyledBlockLast/>}
        </Grid>
      </Grid>
    </Box>
  );
}
export default StatusRectangles