import {createSlice} from "@reduxjs/toolkit";

import {getUser, login, logout} from "src/api/User/User";
import {loggedOut} from './actions'
import {IUserSliceState, USER_STORE_NAME} from "./types";

const makeInitialState = (): IUserSliceState => ({
    userInfo: undefined,
})

export const UserStore = createSlice({
    name: USER_STORE_NAME,
    initialState: makeInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loggedOut, () => makeInitialState())
        builder.addMatcher(
            logout.matchFulfilled,
            () => makeInitialState())
        builder.addMatcher(
            logout.matchRejected,
                (state, action) => {
                    if(action.payload?.status === 401){
                        return makeInitialState()
                    }
                    return state
                },
        )
        builder.addMatcher(
            getUser.matchFulfilled,
            (state, action) => {
                state.userInfo = action.payload.data
            },
        )
        builder.addMatcher(
            login.matchFulfilled,
            (state, action) => {
                state.userInfo = action.payload.data
            }
        )
    }
})

export default UserStore