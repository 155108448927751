import React, {useCallback, useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import {Container, Box, Typography, Tabs, Tab} from '@mui/material';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {Icon} from '@iconify/react';
import roundAccountBox from '@iconify/icons-ic/round-account-box';
import receipt from '@iconify/icons-ic/receipt';
import vpnKey from '@iconify/icons-ic/vpn-key';
import notifications from '@iconify/icons-ic/notifications';

import useSettings from '../../../external/minimal/hooks/useSettings';
import Page from '../../../components/Page';
import {PATH_DASHBOARD} from '../../../routes/path';

const TABS = [
  {
    name: 'Основное',
    path: PATH_DASHBOARD.general.user.general,
    icon: <Icon icon={roundAccountBox} width={20} height={20}/>,

  },
  {
    name: 'Тарифы',
    path: PATH_DASHBOARD.general.user.tariffs,
    icon: <Icon icon={receipt} width={20} height={20}/>,
  },
  {
    name: 'Уведомления',
    path: PATH_DASHBOARD.general.user.notifications,
    icon: <Icon icon={notifications} width={20} height={20}/>,
  },
  {
    name: 'Пароли',
    path: PATH_DASHBOARD.general.user.passwords,
    icon: <Icon icon={vpnKey} width={20} height={20}/>,
  },
];

const TabsWrapperStyle = styled('div')(({theme}) => ({
  zIndex: 9,
  // top: 0,
  // left: 0,
  width: '100%',
  display: 'flex',
  // position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center'
  },
  [theme.breakpoints.up('md')]: {
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(3)
  }
}));

type userPageSubpagePath = keyof typeof PATH_DASHBOARD.general.user


const User: React.FC = () => {
  const {themeStretch} = useSettings();
  const history = useNavigate()
  const {pathname} = useLocation();
  const [currentTab, setCurrentTab] = useState<userPageSubpagePath>(pathname as userPageSubpagePath);
  // console.log(pathname)
  // console.log(PATH_DASHBOARD.general.user.main)
  const handleChangeTab = useCallback(
    (newValue: userPageSubpagePath) => {
      setCurrentTab(newValue);
      history(newValue);
    },
    [history, setCurrentTab],
  );
  useEffect(() => {
    if (pathname === PATH_DASHBOARD.general.user.main) {
      handleChangeTab(PATH_DASHBOARD.general.user.general as userPageSubpagePath)
    }
  }, [pathname, handleChangeTab])
  return (
    <Page title="Основное">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box>
            <Box sx={{flexGrow: 1}}>
              <Typography variant="h4" gutterBottom>
                Профиль
              </Typography>
              <Box>
                <TabsWrapperStyle>
                  <Tabs
                    value={currentTab}
                    scrollButtons="auto"
                    variant="scrollable"
                    allowScrollButtonsMobile
                    onChange={(e, value) => handleChangeTab(value)}
                  >
                    {TABS.map((tab) => (
                      <Tab
                        disableRipple
                        key={tab.path}
                        value={tab.path}
                        icon={tab.icon}
                        label={tab.name}
                      />
                    ))}
                  </Tabs>
                </TabsWrapperStyle>
              </Box>
            </Box>


          <Outlet/>

        </Box>
      </Container>
    </Page>
  );
}
export default User