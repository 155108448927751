import {FetchBaseQueryError} from "@reduxjs/toolkit/query/react";
import {SerializedError} from "@reduxjs/toolkit";
// material
import { styled } from '@mui/material/styles';
import { Typography, Card, CardContent, CardProps, Skeleton } from '@mui/material';

import SeoIllustration from "src/external/minimal/assets/illustration_seo";
import {IClientForCurrentUser} from "../../../api/Client/types";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.lighter,
    [theme.breakpoints.up('md')]: {
        height: '100%',
        display: 'flex',
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
}));

// ----------------------------------------------------------------------

interface IWelcomeCardProps extends CardProps {
    data: IClientForCurrentUser | undefined,
    isLoading: boolean;
    isError: boolean;
    error: FetchBaseQueryError | SerializedError | undefined,
}

export default function WelcomeCard({
   data,
   isLoading,
   isError,
   error,
}: IWelcomeCardProps) {
    return (
        <RootStyle>
            <CardContent
                sx={{
                    p: { md: 0 },
                    pl: { md: 5 },
                    color: 'grey.800'
                }}
            >
                {
                    isError
                    //@ts-ignore
                    ? <>Ошибка сервера {error?.status}</>
                    : <Typography gutterBottom variant="h4" sx={{ pb: { xs: 2, xl: 2 }, maxWidth: 480}}>
                        <div style={{display:"flex"}}>
                            <div>
                                Привет,&nbsp;
                            </div>
                            <div>
                                {
                                    isLoading
                                        ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                        : <>{`${data?.name!}!`}</>
                                }
                            </div>
                        </div>
                    </Typography>
                }

                <div style={{textAlign: 'left'}}>
                    <Typography variant="body2" sx={{ pb: { xs: 3, xl: 5 }, maxWidth: 480 }}>
                            Здесь, в разделе сводка отображается актуальная краткая информация о твоем бизнес - интернете
                    </Typography>
                </div>

                <div style={{display:"flex"}}>
                    <div>
                        Тариф:&nbsp;
                    </div>
                    <div>
                        {
                            isLoading
                                ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                : <div style={{fontWeight: 700}}>
                                    {`${data?.tariff?.title! ? data?.tariff?.title! : 'пока не выбран' }`}
                                </div>
                        }
                    </div>
                </div>
                <div style={{display:"flex"}}>
                    <div>
                        Баланс:&nbsp;
                    </div>
                    <div>
                        {
                            isLoading
                                ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                : <div style={{fontWeight: 700}}>
                                    {`${typeof(data?.balance!) === 'number' ? data?.balance! + ' ₽' : 'отсутствует' }`}
                                </div>
                        }
                    </div>
                </div>

            </CardContent>

            <SeoIllustration
                sx={{
                    p: 3,
                    width: 360,
                    margin: { xs: 'auto', md: 'inherit' }
                }}
            />
        </RootStyle>
    );
}
