import { Box, BoxProps } from '@mui/material';
import React from "react";

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ ...sx }}>
      <img src="/logo230.png" width="100%" height="100%" alt="netegis logo"/>
    </Box>
  );
}
