import {Card, Box, Typography, Stack, Grid} from '@mui/material';
import {useSnackbar} from "notistack";
import React, {useMemo, useState} from "react";
import Page from '../../../../components/Page';
import TariffCard from '../Tariffs/TariffCard'
import TariffCardSkeleton from "./TariffCardSkeleton";
import {useGetTariffsQuery, useChangeTariffMutation} from 'src/api/ClientTariff/ClientTariff'
import {IClientTariff} from 'src/api/ClientTariff/types'
import {IClientForCurrentUser} from 'src/api/Client/types'
import {useGetClientDataForCurrentUserQuery} from "../../../../api/Client/Client";
import {getCombinedParamsFromRtkqHook} from "src/utils/rtkq";
import DetailsTariffDrawer from "./DetailsTariffDrawer";
import {useTheme} from "@mui/material/styles";
import DetailsForPaymentCard from "./DeatilsForPayment/DetailsForPaymentCard";
import BalanceHistoryCard from "./BalanceHistory/BalanceHistoryCard";



const Tariffs: React.FC = () => {
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar();
  const [showDetailsTariffDrawer, setshowDetailsTariffDrawer] = useState(false)
  const [showingInDrawerTariff, setShowingInDrawerTariff] = useState<IClientTariff | undefined>(undefined)
  const tariffsQuery = useGetTariffsQuery(undefined, {refetchOnMountOrArgChange: true})
  const clientDataForCurrentUserQuery = useGetClientDataForCurrentUserQuery(undefined, {refetchOnMountOrArgChange: true})
  const [
    changeTariffMutation,
    {
      isLoading: isTariffChanging,
    }
  ] = useChangeTariffMutation()

  const {
    error: combinedError,
    isFetching: combinedIsFetching,
    isError: combinedIsError
  } = getCombinedParamsFromRtkqHook([
    tariffsQuery,
    clientDataForCurrentUserQuery
  ])

  const tariffsData: IClientTariff[] = useMemo(() => {
    return (
      tariffsQuery.data?.data || [] as IClientTariff[]
    )
  }, [tariffsQuery])


  const clientData: IClientForCurrentUser | undefined = useMemo(() => {
    return (
      clientDataForCurrentUserQuery.data?.data
    )
  }, [clientDataForCurrentUserQuery])

    const clientCurrentTariffId: number | null = useMemo(() => {
    if (!clientData){
      return null
    }else{
      if (clientData.tariff === null){
        return null
      }else{
        return  clientData.tariff.id
      }
    }
  }, [clientData])

  const handleDetailsDrawerClose = () => {
    setshowDetailsTariffDrawer(false)
    setShowingInDrawerTariff(undefined)
  }

  const handleShowDetailsClick = (tariffId: number) => {
    // console.log(taskId)
    const tariffForShow = tariffsData.find((tariff) => {
      return tariff.id === tariffId
    })
    setShowingInDrawerTariff(tariffForShow)
    setshowDetailsTariffDrawer(true)
  }


  const selectTariffButtonClickHandler = async (selectedTariffId: number) => {
    // console.log('Select tariff with id', selectedTariffId)
    console.log('Change tariff.onSubmit', {tariffId: selectedTariffId})
    const returnedFromAPIVal = await changeTariffMutation({tariffId: selectedTariffId})
    console.log('Change tariff.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      console.log('Change tariff.onSubmit.error')
      enqueueSnackbar('Ошибка при смене тарифа', {variant: 'error', autoHideDuration: 2000});
    } else {
      // @ts-ignore
      let isNoContractError
      try {
        // @ts-ignore
        if ('status' in returnedFromAPIVal.data && returnedFromAPIVal.data.status === 'error') {
          // @ts-ignore
          if (returnedFromAPIVal.data.errors.length && returnedFromAPIVal.data.errors[0].name === 'not found contract') {
            isNoContractError = true
          }
        }
      } catch (e) {
        console.log(e)
      }
      if (isNoContractError) {
        console.log('Change tariff.onSubmit.error NoContractError')
        enqueueSnackbar('Невозможно сменить тарифный план. Для этого действия требуется договор', {variant: 'error', autoHideDuration: 2000});
      } else {
        console.log('Change tariff.onSubmit.success')
        enqueueSnackbar('Тариф успешно изменен', {variant: 'success', autoHideDuration: 2000});
        tariffsQuery.refetch()
        clientDataForCurrentUserQuery.refetch();
      }

    }
  }
  return (
    <Page title="Тарифы">
      <>
        <DetailsTariffDrawer tariff={showingInDrawerTariff}  modalClose={handleDetailsDrawerClose} showingModal={showDetailsTariffDrawer}/>
        <Grid container sx={{width: 1}}>
          <Grid item xs={12} lg={6}>
            <DetailsForPaymentCard/>
          </Grid>
          <Grid item xs={12} lg={6}>
            <BalanceHistoryCard/>
          </Grid>
        </Grid>
        {combinedIsError ?
          <Box>
            <Card sx={{minHeight: '100px'}}>
              <Stack direction={'row'} alignItems={'center'} sx={{p: 3}}>
                <Typography variant="body1">Произошла ошибка загрузки данных
                  тарифов: {JSON.stringify(combinedError)}</Typography>
              </Stack>
            </Card>
          </Box>
          :
          <Stack direction={'row'} justifyContent={'space-between'} flexWrap={'wrap'} width={1}
                 sx={{[theme.breakpoints.down('sm')]: {justifyContent: 'center'},}}>
            {combinedIsFetching ?
              Array.from(Array(tariffsData ? tariffsData.length : 3).keys()).map((count) => {
                return (
                  <TariffCardSkeleton key={count}/>
                );
              })
              :
              tariffsData?.length === 0 ?
                <p>Нет данных о тарифах</p>
                :
                tariffsData?.map((tarriff) => {
                  return <TariffCard key={tarriff.id} tariff={tarriff}
                                     currentTariff={clientCurrentTariffId === tarriff.id ? true : false}
                                     selectTariffCallback={selectTariffButtonClickHandler}
                                     showDeatilsCallback={handleShowDetailsClick}
                                     disableChangeTariffButton={isTariffChanging}/>
                })}

          </Stack>}
      </>
    </Page>
  );
}
export default Tariffs




