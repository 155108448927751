import { Box, BoxProps } from '@mui/material';
import React from "react";

// ----------------------------------------------------------------------

export default function LogoWithText({ sx }: BoxProps) {

  return (
    <Box sx={{   ...sx }}>
      <img src="/logo9901.png" width="100%" height="100%" alt="netegis logo"/>
    </Box>
  );
}
