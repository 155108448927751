import {isDefined} from '../../utils/object'
export const getErrorMsgByError = (err: any): string => {
    if (err.status === 401) {
        return 'Введён неверный пароль'
    }
    if (err.status === 404) {
        return 'Указанный пользователь не зарегистрирован'
    }
    return 'Произошла ошибка при входе. Повторите попытку'
}


export const getErrorMsgByErrorForRegistration = (err: any): string => {
    if (err.status === 409) {
        return 'Пользователь с такими данными уже есть'
    }
    if (chceckErrorIncludeErrorText(err, 'Please use a valid phone format')){
        return 'Введите номер телефона в правильном формате'
    }
    return 'Произошла ошибка при регистрации. Повторите попытку'
}


export const getErrorMsgByErrorForEnterOTPCode = (err: any): string => {
    return 'Произошла ошибка при вводе кода. Повторите попытку'
}


export const getErrorMsgByErrorForResetPassword = (err: any): string => {
    if (chceckErrorIncludeErrorText(err, 'email could not be found')) {
        return 'Пользователь с таким Email не найден. Проверьте правильность введенного Email или зарегистрируйтесь'
    }
    return 'Произошла ошибка при сбросе пароля. Повторите попытку'
}


export const getErrorMsgByErrorForConfirmPassword = (err: any): string => {
    return 'Произошла ошибка при изменении пароля. Повторите попытку'
}


const chceckErrorIncludeErrorText = (err: any, text: String) => {
    try {
        if (isDefined(err.data) &&
          err.data !== undefined && typeof err.data === 'object' &&
          err.data.message.includes(text)) {
            return true
        }
        return false
    } catch (e) {
        console.log(e)
        return false
    }

}

// const chceckErrorIncludeErrorTextForRegistration = (err: any, text: String) => {
//     // console.log('chceckErrorIncludeErrorText', err)
//     if (isDefined(err.data) &&
//       err.data !== undefined && typeof err.data === 'object' &&
//       err.data.data !== undefined && typeof  err.data.data === 'object' &&
//       err.data.data.errors !== undefined && Array.isArray(err.data.data.errors) &&
//       err.data.data.errors.length ){
//       for (const errorFormErrorsList of err.data.data.errors){
//          if (errorFormErrorsList.name === text) {
//            return  true
//          }
//       }
//         return true
//     }
//     return false
// }

