// > formatCpeSerial('ABCKY')
// 'AB CKY'
// > formatCpeSerial('A B C K Y')
// 'AB CKY'
// > formatCpeSerial('ABPCKY')
// 'ABP CKY'
import {MobileRadioStatus, MobileRadioStatusLang} from "../api/ClientMobileUplink/types";
import {ICpeAggStatus} from "../api/ClientCpe/types";
import {IClientLocationAggStatus} from "../api/ClientLocation/types";
import {IClientAggStatus} from "../api/Client/types";
import {isDefined} from "./object";
import {IMobileInterfaceAggStatus, IWiredInterfaceAggStatus, MobileInterfaceError} from "../api/CpeInterface/types";

const MAC_ALPHABET = '0123456789abcdef'

export enum AggStatusSeverity {
    minor, // = 'minor',
    normal, // = 'normal',
    major, // = 'major',
    critical, // = 'critical',
    blocker, // = 'blocker',
}

export type ResultAggStatus =
    | {
        severity: AggStatusSeverity
        isOk: false
        // type: SmthEnum
        // value: string | number | null
        text: string
    }
    | {
        severity: null
        isOk: true
        // value: null
        text: 'Работает'
    }
    // // в случае, когда данных нет (?)
    // | {
    //     severity: null
    //     isOk: false
    //     // value: null
    //     text: undefined
    // }

const allOk: ResultAggStatus = {
    severity: null,
    isOk: true,
    text: 'Работает',
}

export const getWiredUplinkInterfaceStatus = (interfaceStatus: IWiredInterfaceAggStatus | null) => {
    // TODO: добавить обработку не назначенных
    if(interfaceStatus === null){
        return null
    }
    if(interfaceStatus.linkup){
        return allOk
    } else if (!interfaceStatus.linkup){
        return {
            severity: AggStatusSeverity.major,
            isOk: false,
            text: 'Проводной канал не работает',
        }
    }
    return null
}

export const getMobileInterfaceStatus = (interfaceStatus: IMobileInterfaceAggStatus | null, assigned?: boolean):ResultAggStatus | null => {
    if(interfaceStatus === null && assigned!){
        return {
            severity: AggStatusSeverity.major,
            isOk: false,
            text: 'SIM карта не вставлена или не распознана',
        }
    }
    if(interfaceStatus?.error !== null && interfaceStatus?.error !== undefined){
        if(interfaceStatus?.error === MobileInterfaceError.low_rssi){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Низкий уровень сигнала',
            }
        } else if(interfaceStatus?.error === MobileInterfaceError.not_registered){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'SIM не зарегистрирована',
            }
        } else if(interfaceStatus?.error === MobileInterfaceError.no_ip){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Отсутствует ip от мобильного оператора',
            }
        } else if (interfaceStatus?.error === MobileInterfaceError.no_traffic){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Отсутствует трафик',
            }
        } else if (interfaceStatus?.error === MobileInterfaceError.low_traffic){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Низкий уровень трафика',
            }
        } else if (interfaceStatus?.error === MobileInterfaceError.sim_error){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Ошибка SIM-карты',
            }
        } else if (interfaceStatus?.error === MobileInterfaceError.sim_not_inserted){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'SIM-карта не вставлена',
            }
        }
    } else {
        if(interfaceStatus?.mobileRadioStatus === MobileRadioStatus.disconnected){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Отключено',
            }
        } else if(interfaceStatus?.mobileRadioStatus === MobileRadioStatus.unknown){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Ошибка модема',
            }
        } else if(interfaceStatus?.mobileRadioStatus === MobileRadioStatus.failed){
            return {
                severity: AggStatusSeverity.major,
                isOk: false,
                text: 'Ошибка регистрации в сети',
            }
        } else if(interfaceStatus?.mobileRadioStatus === MobileRadioStatus.connected && interfaceStatus?.linkup === true){
            return allOk
        }
    }
    return null
}

export const formatAggStatusClient = (aggStatus: IClientAggStatus):ResultAggStatus | null => {
    if(isDefined(aggStatus)){
        if(isDefined(aggStatus.locations)){
            const t = aggStatus.locations
                .map((cpeAggStatus) => formatAggStatusLocation(cpeAggStatus))
                .filter((s) => s) as ResultAggStatus[]
            return t.reduce(getMostSevereAggStatus, null)
        }
    }
    return null
}

const getMostSevereAggStatus = (a: ResultAggStatus | null, b: ResultAggStatus): ResultAggStatus => {
    if (!a) {
        return b
    }
    if (a.severity === null) {
        return b
    }
    if (b.severity === null) {
        return a
    }
    if (a.severity > b.severity) {
        return a
    }
    if (b.severity > a.severity) {
        return b
    }
    return a
}

export const formatAggStatusLocation = (aggStatus: IClientLocationAggStatus):ResultAggStatus | null => {
    if(isDefined(aggStatus)){
        if(isDefined(aggStatus.cpe)){
            const t = aggStatus.cpe
                .map((cpeAggStatus) => formatAggStatusCpe(cpeAggStatus))
                .filter((s) => s) as ResultAggStatus[]
            return t.reduce(getMostSevereAggStatus, null)
        }
    }
    return null
}

export const formatAggStatusCpe = (aggStatus: ICpeAggStatus): ResultAggStatus | null => {

    if(aggStatus === null || aggStatus === undefined){
        return null
    }
    if(!aggStatus.managementInterfaceLinkup){
        return {
            severity: AggStatusSeverity.blocker,
            isOk: false,
            text: 'Устройство отключено от платформы управления',
        }
    }
    const discoveredWiredUplinkLinkups: boolean[] = aggStatus.wiredUplinks
        .map((wiredUplink) => wiredUplink.interface?.linkup)
        .filter((linkup) => isDefined(linkup))
        .map((linkup) => Boolean(linkup))
    // aggStatus.wiredUplinks.map((wiredUpLink) => {
    //     if(isDefined(wiredUpLink.interface)){
    //         if(wiredUpLink.interface?.linkup === true){
    //             wiredUpLinkConditionsArr.push(true)
    //         } else if(wiredUpLink.interface?.linkup === false) (
    //             wiredUpLinkConditionsArr.push(false)
    //         )
    //     }
    // })

    const discoveredMobileUplinkLinkups: boolean[] = aggStatus.mobileUplinks
        .map((mobileUplink) => mobileUplink.interface?.linkup)
        .filter((linkup) => isDefined(linkup))
        .map((linkup) => Boolean(linkup))

    const anyUplinkWorked: boolean = [
        ...discoveredWiredUplinkLinkups,
        ...discoveredMobileUplinkLinkups,
    ].some((s) => s)

    if (!anyUplinkWorked) {
        return {
            severity: AggStatusSeverity.blocker,
            isOk: false,
            text: 'Не работают все каналы',
        }
    }

    // На этом этапе у нас есть хотя бы один работающих Uplink
    if (discoveredWiredUplinkLinkups.every((s) => !s)) {
        return {
            severity: AggStatusSeverity.major,
            isOk: false,
            text: 'Не работают все проводные каналы',
        }
    }
    if (discoveredWiredUplinkLinkups.some((s) => !s)) {
        return {
            severity: AggStatusSeverity.major,
            isOk: false,
            text: 'Не работает проводной канал',
        }
    }
    if (discoveredMobileUplinkLinkups.every((s) => !s)) {
        return {
            severity: AggStatusSeverity.major,
            isOk: false,
            text: 'Не работают все мобильные каналы',
        }
    }
    if (discoveredMobileUplinkLinkups.some((s) => !s)) {
        return {
            severity: AggStatusSeverity.major,
            isOk: false,
            text: 'Не работает мобильный канал',
        }
    }

    // TODO: Несоответствие факта (распознанных каналов) плану (назначенных каналов)

    if ([...discoveredWiredUplinkLinkups, ...discoveredMobileUplinkLinkups].length > 0) {
        return allOk
    }

    // aggStatus.mobileUplinks.map((mobileUplink) => {
    //     if(isDefined(mobileUplink.interface)){
    //         if(mobileUplink.interface?.linkup === true){
    //             discoveredMobileUplinkLinkups.push(true)
    //         } else if (mobileUplink.interface?.linkup === false){
    //             discoveredMobileUplinkLinkups.push(false)
    //         }
    //     }
    // })
    //
    // пошел хардкод
    // if(discoveredWiredUplinkLinkups.length > 0){
    //     if(discoveredWiredUplinkLinkups[0]){
    //         if(discoveredMobileUplinkLinkups.length > 0){
    //             if(discoveredMobileUplinkLinkups.length === 1){
    //                 if(discoveredMobileUplinkLinkups[0]){
    //                     return allOk
    //                 } else if(!discoveredMobileUplinkLinkups[0]){
    //                     return {
    //                         severity: AggStatusSeverity.major,
    //                         isOk: false,
    //                         text: 'Не работает мобильный канал',
    //                     }
    //                 }
    //             } else if(discoveredMobileUplinkLinkups.length === 2){
    //                 if(discoveredMobileUplinkLinkups[0] && discoveredMobileUplinkLinkups[1]){
    //                     return allOk
    //                 } else if(
    //                     (!discoveredMobileUplinkLinkups[0] && discoveredMobileUplinkLinkups[1])
    //                     || (discoveredMobileUplinkLinkups[0] && !discoveredMobileUplinkLinkups[1])
    //                 ){
    //                     return {
    //                         severity: AggStatusSeverity.major,
    //                         isOk: false,
    //                         text: 'Не работает мобильный канал',
    //                     }
    //                 } else if(!discoveredMobileUplinkLinkups[0] && !discoveredMobileUplinkLinkups[1]){
    //                     return {
    //                         severity: AggStatusSeverity.major,
    //                         isOk: false,
    //                         text: 'Не работают оба мобильных канала',
    //                     }
    //                 }
    //             }
    //         } else {
    //             return allOk
    //         }
    //     } else if (!discoveredWiredUplinkLinkups[0]){
    //         if(discoveredMobileUplinkLinkups.length > 0){
    //             if(discoveredMobileUplinkLinkups.length === 1){
    //                 if(discoveredMobileUplinkLinkups[0]){
    //                     return {
    //                         severity: AggStatusSeverity.major,
    //                         isOk: false,
    //                         text: 'Не работает проводной канал',
    //                     }
    //                 } else if(!discoveredMobileUplinkLinkups[0]){
    //                     return {
    //                         severity: AggStatusSeverity.blocker,
    //                         isOk: false,
    //                         text: 'Не работают все каналы',
    //                     }
    //                 }
    //             } else if(discoveredMobileUplinkLinkups.length === 2){
    //                 if(discoveredMobileUplinkLinkups[0] && discoveredMobileUplinkLinkups[1]){
    //                     return ({
    //                         severity: AggStatusSeverity.major,
    //                         isOk: false,
    //                         text: 'Не работает проводной канал',
    //                     })
    //                 } else if(
    //                     (!discoveredMobileUplinkLinkups[0] && discoveredMobileUplinkLinkups[1])
    //                     || (discoveredMobileUplinkLinkups[0] && !discoveredMobileUplinkLinkups[1])
    //                 ){
    //                     return ({
    //                         severity: AggStatusSeverity.major,
    //                         isOk: false,
    //                         text: 'Не работает мобильный проводной каналы',
    //                     })
    //                 } else if(!discoveredMobileUplinkLinkups[0] && !discoveredMobileUplinkLinkups[1]){
    //                     return ({
    //                         severity: AggStatusSeverity.blocker,
    //                         isOk: false,
    //                         text: 'Не работают все каналы',
    //                     })
    //                 }
    //             }
    //         } else {
    //             return ({
    //                 severity: AggStatusSeverity.blocker,
    //                 isOk: false,
    //                 text: 'Не работают все каналы',
    //             })
    //         }
    //     }
    // } else {
    //     if(discoveredMobileUplinkLinkups.length === 1){
    //         if(discoveredMobileUplinkLinkups[0]){
    //             return allOk
    //         } else if(!discoveredMobileUplinkLinkups[0]){
    //             return ({
    //                 severity: AggStatusSeverity.blocker,
    //                 isOk: false,
    //                 text: 'Не работают все каналы',
    //             })
    //         }
    //     } else if(discoveredMobileUplinkLinkups.length === 2){
    //         if(discoveredMobileUplinkLinkups[0] && discoveredMobileUplinkLinkups[1]){
    //             return allOk
    //         } else if(
    //             (!discoveredMobileUplinkLinkups[0] && discoveredMobileUplinkLinkups[1])
    //             || (discoveredMobileUplinkLinkups[0] && !discoveredMobileUplinkLinkups[1])
    //         ){
    //             return ({
    //                 severity: AggStatusSeverity.major,
    //                 isOk: false,
    //                 text: 'Не работает мобильный канал',
    //             })
    //         } else if(!discoveredMobileUplinkLinkups[0] && !discoveredMobileUplinkLinkups[1]){
    //             return ({
    //                 severity: AggStatusSeverity.blocker,
    //                 isOk: false,
    //                 text: 'Не работают все каналы',
    //             })
    //         }
    //     }
    // }
    return null
}

export const formatCpeSerial = (serial: string): string => {
    const rawSerial = serial.replaceAll(/\s/g, '')
    return `${rawSerial.slice(0, -3)} ${rawSerial.slice(-3)}`
}

// > formatIccid('897010120120 8052879')
// '897 0101 20 120 805 287 9'
// // without check digit
// > formatIccid('897 0101 20120805287')
// '897 0101 20 120 805 287'
export const formatIccid = (iccid: string): string => {
    const rawIccid = iccid.replaceAll(/\s/g, '')

    const rusSimCode = rawIccid.slice(0, 3)
    const rusMNC = rawIccid.slice(3, 3 + 4)

    let id = rawIccid.slice(0, 19).slice(3 + 4)
    let idPart
    let splittedId: string[] = []
    while (id.length > 0) {
        [id, idPart] = [id.slice(0, -3), id.slice(-3)]
        splittedId = [idPart, ...splittedId]
    }

    const check = rawIccid.slice(19, 19 + 1)

    return [
        rusSimCode,
        rusMNC,
        ...splittedId,
        check,
    ].join(' ')
}

// > formatRusPhoneNumber('89671234567')
// +7 (967) 123-45-67
export const formatRusPhoneNumber = (phoneNumber: string): string => {
    const filteredPhoneNumber = phoneNumber.replaceAll(/[^+0-9]/g, '');
    const rawPhoneNumber = filteredPhoneNumber.slice(-10)
    return (
        `+7 (${rawPhoneNumber.slice(0, 3)})`
        + ` ${rawPhoneNumber.slice(3, 3 + 3)}`
        + `‑${rawPhoneNumber.slice(6, 6 + 2)}`
        + `‑${rawPhoneNumber.slice(8, 8 + 2)}`
    )
}

export const formatLinkup = (linkup: boolean): string =>
    linkup
        ? 'Включен'
        : 'Выключен'

export const formatCurrencyRus = (value: number): string =>
    Intl.NumberFormat(
        'ru-RU',
        { style: 'currency', currency: 'RUB' }
    ).format(value)

export const formatMobileRadioStatus = (radioStatus: MobileRadioStatus): string =>
    MobileRadioStatusLang[radioStatus]

const bytesSuffix = [
    'байт',
    'кБ',
    'Мб',
    'Гб',
    'Тб',
]

const biBytesSuffix = [
    'байт',
    'КиБ',
    'МиБ',
    'ГиБ',
    'ТиБ',
]

const bitsSuffix = [
    'бит',
    'кБит',
    'Мбит',
    'Гбит',
    'Тбит',
]

// TODO: обсудить, пришел к тому, что так понятнее, хоть и немного код повторяется
export const formatBytes = (bits: number | undefined, decimals: number, divideBy: 1000 | 1024): string => {
    if (typeof bits !== 'number' || bits < 0){
        return 'Не определено'
    }
    if (bits === 0) {
        return '0 байт'
    }
    const bytes = bits/8
    const suffixArr = divideBy === 1024 ? bytesSuffix : biBytesSuffix
    const suffixKey = Math.floor(Math.log(bytes) / Math.log(divideBy));
    return(
        parseFloat((bytes / Math.pow(divideBy, suffixKey)).toFixed(decimals))
        + ' '
        + suffixArr[suffixKey]
    )
}

export const formatBits = (bits: number | undefined, decimals: number): string => {
    if (typeof bits !== 'number' || bits < 0){
        return 'Не определено'
    }
    if (bits === 0) {
        return '0 бит'
    }
    const suffixKey = Math.floor(Math.log(bits) / Math.log(1000));
    return(
        parseFloat((bits / Math.pow(1000, suffixKey)).toFixed(decimals))
        + ' '
        + bitsSuffix[suffixKey]
    )
}

export const formatBitsWithoutSuffix = (bytes: number | undefined, decimals: number, formatTo:string): number => {
    if (typeof bytes !== 'number' || bytes <= 0 || bitsSuffix.indexOf(formatTo) === -1){
        return 0
    }
    return parseFloat((bytes / Math.pow(1000, bitsSuffix.indexOf(formatTo))).toFixed(decimals))
}

export const formatDate = (date: Date) => (
    new Intl.DateTimeFormat('ru-RU', {
        month: 'numeric',
        day: 'numeric',
    }).format(date)
)

const decimalFormatSubnet = [
    '0.0.0.0',
    '128.0.0.0',
    '192.0.0.0',
    '224.0.0.0',
    '240.0.0.0',
    '248.0.0.0',
    '252.0.0.0',
    '254.0.0.0',
    '255.0.0.0',
    '255.128.0.0',
    '255.192.0.0',
    '255.224.0.0',
    '255.240.0.0',
    '255.248.0.0',
    '255.252.0.0',
    '255.254.0.0',
    '255.255.0.0',
    '255.255.128.0',
    '255.255.192.0',
    '255.255.224.0',
    '255.255.240.0',
    '255.255.248.0',
    '255.255.252.0',
    '255.255.254.0',
    '255.255.255.0',
    '255.255.255.128',
    '255.255.255.192',
    '255.255.255.224',
    '255.255.255.240',
    '255.255.255.248',
    '255.255.255.252',
    '255.255.255.254',
    '255.255.255.255',
]

export const formatSubNet = (lanNetmask: number | undefined): string => {
    if(typeof lanNetmask === 'number' && lanNetmask >= 0 && lanNetmask <= 32){
        return decimalFormatSubnet[lanNetmask]
    }
    return 'Не определена'
}

export const formatSubNetNumAddresses = (lanNetmask: number | undefined): string | number => {
    if(typeof lanNetmask === 'number' && lanNetmask >= 0 && lanNetmask <= 32){
        if (lanNetmask === 32 || lanNetmask === 31){
            return 'Не допустимые значения'
        }
        // 3 consists of:
        // - subnet itself address (reserved)
        // - gateway (CPE self)
        // - broadcast address
        return Math.pow(2, 32 - lanNetmask) - 3
    }
    return 'Не определена'
}

const FORMAT_MAC_RE = new RegExp(`[${MAC_ALPHABET}]{1,2}`, 'g')
export const formatMac = (mac: string): string => {
    // mac: assume format "a0b1c2d3e4f5"
    // return "a0:b1:c2:d3:e4:f5"
    return (mac.match(FORMAT_MAC_RE) || []).join(':')
}
