import React from "react";
import {styled} from '@mui/material/styles';
import {Card, Typography, Box, Stack, useTheme, CardActionArea, Skeleton} from '@mui/material';
import { Icon } from '@iconify/react';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill'
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill'
import homeFill from '@iconify/icons-eva/home-fill'
import {StatusesOfDevices} from "../../Devices/types";


const CardActionAreaStyled = styled(CardActionArea)(({theme}) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3)
}));

// ----------------------------------------------------------------------

const ICONS_WIDTH_AND_HEIGHT = 30
const ICONS_STROKE_WIDTH_AND_HEIGHT = 70

interface ICpeStatusCardProps {
  type: StatusesOfDevices
  amount?: number,
  clicked?: boolean,
  captionText?: string,
  isError?: boolean,
  error?: any,
  isLoadingData?: boolean,
  cardClickCallback: Function
}

const CpeStatusCard: React.FC<ICpeStatusCardProps> = ({
  type,
  amount,
  clicked,
  captionText,
  isError,
  error,
  isLoadingData,
  cardClickCallback
}) => {
  const theme = useTheme();

  let color, bgcolor, defaultBgcolor

  if (type === StatusesOfDevices.WORKING) {
    defaultBgcolor = theme.palette.primary.lighter
    color = theme.palette.background.paper
    bgcolor = theme.palette.primary.main
  } else if (type === StatusesOfDevices.NOT_WORKING) {
    defaultBgcolor = theme.palette.error.lighter
    color = theme.palette.background.paper
    bgcolor = theme.palette.error.main
  } else if (type === StatusesOfDevices.ALL) {
    defaultBgcolor = theme.palette.info.lighter
    color = theme.palette.background.paper
    bgcolor = theme.palette.info.main
  } else {
    defaultBgcolor = theme.palette.background.neutral
    color = theme.palette.background.paper
    bgcolor = theme.palette.background.neutral
  }

  const getIconDependingOnTheType = (type: StatusesOfDevices) => {
    if (type === StatusesOfDevices.WORKING) {
      return checkmarkCircle2Fill
    } else if (StatusesOfDevices.NOT_WORKING) {
      return alertTriangleFill
    }
    return homeFill
  }

  const handleCardClick = ()=>{
    cardClickCallback(type)
  }

  return (
    <Card sx={{
      bgcolor: clicked ? bgcolor : defaultBgcolor,
      color: clicked ? theme.palette.background.default : '',
      height: '100%',
    }}>
      <CardActionAreaStyled  onClick={handleCardClick}>
        <Box sx={{width: '90%'}}>
          {isLoadingData  ?
           <Box  sx={{width: "95%"}}>
             <Typography variant="h4"><Skeleton/></Typography>
             <Typography variant="subtitle2" sx={{color: 'text.secondary', paddingTop: '16px'}}><Skeleton /></Typography>
           </Box>
            :
            isError ?
            <div>
              <Box sx={{mb:1}}>
                <Typography variant="body1">Произошла ошибка загрузки данных
                  устройств: {JSON.stringify(error)}</Typography>
              </Box>
              <Typography variant="subtitle2" sx={{color: 'text.secondary'}}>
                {(captionText)}
              </Typography>
            </div>
            :
            <div>
              <Typography variant="h5">{(amount ? amount : 'Нет данных')}</Typography>
              <Typography
                  variant="subtitle2"
                  sx={{
                    color: clicked ? theme.palette.background.default :'text.secondary',
                    paddingTop: '16px'
                  }}
              >
                {(captionText)}
              </Typography>
            </div>
          }
        </Box>
        <Stack
          direction="row" alignItems="center" justifyContent="center"
          sx={{
            minWidth: ICONS_STROKE_WIDTH_AND_HEIGHT,
            width: ICONS_STROKE_WIDTH_AND_HEIGHT,
            height: ICONS_STROKE_WIDTH_AND_HEIGHT,
            lineHeight: 0,
            borderRadius: '50%',
            // bgcolor: 'background.neutral',
            // color: theme.palette.primary.dark,
            bgcolor: isLoadingData ? theme.palette.background.neutral : bgcolor,
          }}
        >
          {isLoadingData ?
            <Skeleton variant={'circular'} width={ICONS_STROKE_WIDTH_AND_HEIGHT}
                      height={ICONS_STROKE_WIDTH_AND_HEIGHT}/>
            :
            <Box>
              <Icon
                  icon={getIconDependingOnTheType(type)}
                  color={color}
                  width={ICONS_WIDTH_AND_HEIGHT}
                  height={ICONS_WIDTH_AND_HEIGHT}
              />
            </Box>
          }

        </Stack>
      </CardActionAreaStyled>
    </Card>

  );
}
export default CpeStatusCard