import React from "react";
import {Card} from "@mui/material/";


interface IStyledCardForTariffProps {

}

const StyledCardForTariff: React.FC<IStyledCardForTariffProps> = ({
                                                                    children,
                                                                  }) => {
  return (
    <Card
      sx={{
        mx: '20px',
        my: '10px',
        px: 3,
        pt: 3,
        pb: 4,
        minWidth: '250px',
        width: 'calc(30% - 40px)',
        minHeight: '60vh',
        maxHeight: '65vh',
        // wordWrap: 'normal',
        boxShadow: (theme) => theme.customShadows.z20,
        '&:hover': {
          boxShadow: (theme) => theme.customShadows.z24
        }
      }}
    >
      {children}
    </Card>
  )
}


export default StyledCardForTariff