import React from "react";
import * as Yup from 'yup'
import {makeValidate} from "mui-rff";
import {AnySchema as YupSchema} from "yup/lib/schema";

import {isDefined} from "./object";

export const appMakeValidate = (schema: YupSchema) => makeValidate(
    schema,
    ({ path, message }) =>
        <span style={{ display: 'block' }} key={`${path}-${message}`}>
            {message}
        </span>,
)

const corpInnCoeffs = [2, 4, 10, 3, 5, 9, 4, 6, 8]
const ipInnCoeffsN11 = [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
const ipInnCoeffsN12 = [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]
const calculateInnCRC = (inn: string, coeffsType: number[]): number =>
    (
        coeffsType.reduce(
            (prev, curr, i) =>
                prev + curr * Number(inn[i]),
            0,
        ) % 11
    ) % 10

export const innValidation = Yup
    .string()
    .trim()
    .test('len', 'Ровно 10 или 12 символов', value =>
        value == null
        || value?.length === 10
        || value?.length === 12
    )
    // .length(10)
    .test({
        name: 'corpInnCRC',
        test: (value?: string) => {
            const newValue = (value || '').replace(/\D/g, '')
            return !isDefined(value)
                // this check is to only show msg about length if applicable
                || ((value || '').length !== 10 && (value || '').length !== 12)
                || (
                    /^(\d{10})$/i.test(newValue)
                    && calculateInnCRC(newValue, corpInnCoeffs) === Number(newValue[9])
                )
                || (
                    /^(\d{12})$/i.test(newValue)
                    && calculateInnCRC(newValue, ipInnCoeffsN11) === Number(newValue[10])
                    && calculateInnCRC(newValue, ipInnCoeffsN12) === Number(newValue[11])
                )

        },
        message: 'Неверный ИНН',
    })



export const kppValidation = Yup
    .string()
    .trim()
    .length(9, '9 символов')

export const ogrnValidation = Yup
    .string()
    .trim()
    .test('len', 'Ровно 13 или 15 символов', value =>
        value == null
        || value?.length === 13
        || value?.length === 15
    )
    .test({
        name: 'ogrnCRC',
        test: (value?: string) => {
            const newValue = (value || '').replace(/\D/g, '')
            return !isDefined(value)
                // this check is to only show msg about length if applicable
                || ((value || '').length !== 13 && (value || '').length !== 15)
                || (
                    /^(\d{13})$/i.test(newValue)
                    // КК (4-й, 5-й знаки) — порядковый номер субъекта РФ
                    // по перечню субъектов РФ, установленному статьей 65 Конституции РФ
                    && Number(newValue.slice(3, 4)) <= 85
                    && Number(newValue[12]) === (Number(newValue.slice(0, -1)) % 11 % 10)
                )
                || (
                    /^(\d{15})$/i.test(newValue)
                    // КК (4-й, 5-й знаки) — порядковый номер субъекта РФ
                    // по перечню субъектов РФ, установленному статьей 65 Конституции РФ
                    && Number(newValue.slice(3, 4)) <= 85
                    && Number(newValue[14]) === (Number(newValue.slice(0, -1)) % 13 % 10)
                )

        },
        message: 'Неверный ОГРН',
    })

export const rusPhoneValidation = Yup
    .string()
    .trim()
    .matches(
        /\+7\d{10}/i,
        {
            message: 'Введите в формате +79001234567',
            excludeEmptyString: true,
        },
    )

// export const ipNDomainValidation = Yup
//     .string()
//     .test({
//         name: 'addressIp',
//         test: (value?: string) => {
//             return !isDefined(value)
//                 || ipRegex({exact: true}).test(value)
//                 // TODO: не проверенная особо регулярка
//                 || (/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/.test(value!))
//         },
//         message: 'Неверный адрес',
//     })

export const domainValidation = Yup
    .string()
    .test({
        name: 'addressIp',
        test: (value?: string) => {
            return !isDefined(value)
                // TODO: не проверенная особо регулярка
                || (/\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/.test(value!))
        },
        message: 'Неверный адрес',
    })
