import {TId} from "../types/entityIds";

type TObjWithId = {
    id: TId
}

export const extractIdMakeRecordset = <TObj extends TObjWithId>(
    objArr: TObj[]
): Record<TId, TObj> =>
    objArr.reduce(
        (prev, cur) => ({
            ...prev,
            [cur.id]: cur,
        }),
        {},
    )

// Got from https://fettblog.eu/typescript-hasownproperty/
export const hasOwnProperty = <X extends {}, Y extends PropertyKey>(
    obj: X, prop: Y
): obj is X & Record<Y, unknown> => {
  return obj.hasOwnProperty(prop)
}

export const nullPropsToUndef = <T extends {}>(
    obj: T
): T =>
    // @ts-ignore TS2322: Type '{}' is not assignable to type 'T'.
    Object.entries(obj)
        .map(([k, v]) => [k, v === null ? undefined : v])
        .reduce(
            (prev, [k, newV]) => ({
                ...prev,
                [k as string]: newV,
            }),
            {},
        )

export const isDefined = (o: any): boolean =>
    o !== null && o !== undefined
