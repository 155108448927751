import React from "react";
import {Box, Chip} from '@mui/material';
import {RequestsPriority} from "../types";
import {IPriorityChipProps} from './types'


const PriorityChip: React.FC<IPriorityChipProps> = ({priority, caption, selected, clickCallback, sx}) => {
  const clickHandler = ()=>{
    if (clickCallback){
      // console.log(priority)
      clickCallback(priority)
    }
  }

  const getPriotityIcon = (priority: RequestsPriority) => {
    if (priority === RequestsPriority.HIGH) {
      return "/static/icons/tasks-priority/fire.png"
    } else if (priority === RequestsPriority.LOW) {
      return "/static/icons/tasks-priority/leaf.png"
    } else if (priority === RequestsPriority.MEDIUM) {
      return "/static/icons/tasks-priority/lightning.png"
    }
  }
    const getPriotityCaption = (priority: RequestsPriority) => {
    if (priority === RequestsPriority.HIGH) {
      return "Очень срочно"
    } else if (priority === RequestsPriority.LOW) {
      return "Низкий"
    } else if (priority === RequestsPriority.MEDIUM) {
      return "Средний"
    }
  }

  return (
    <Box sx={{...sx}}>
      <Chip color={selected ? 'primary' : 'default'}
            deleteIcon={<img alt='low' src={getPriotityIcon(priority)}/>}
            onDelete={clickCallback ? clickHandler : () => {
            }}
            size="small" label={caption ? caption : getPriotityCaption(priority)}
            sx = {clickCallback ? { cursor: 'pointer'} : {cursor: 'inherit'}}
            onClick={clickCallback ? clickHandler : () => {
            }}/>
    </Box>
  );
}
export default PriorityChip