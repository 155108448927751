// import {Theme} from "@material-ui/core";
// import {PaletteColor} from "@material-ui/core/styles/createPalette";
//
// export const getThemeTypeAwareColor = (theme: Theme, color: PaletteColor): string =>
//     color[theme.palette.type || 'main']

import {Theme} from "@mui/material";
import {PaletteColor} from "@mui/material/styles/createPalette";

export const getThemeTypeAwareColor = (theme: Theme, color: PaletteColor): string =>
    color[theme.palette.mode || 'main']
