import { MAvatar } from '../external/minimal/components/@material-extend';
import { MAvatarProps } from '../external/minimal/components/@material-extend/MAvatar';
import createAvatar from '../external/minimal/utils/createAvatar';
import {useAppSelector} from "src/app/hooks";
import UserStore from "src/redux/User";


// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const cachedUser = useAppSelector(({
        [UserStore.name]: {
            userInfo: user
        }
    }) => user)
  // console.log(cachedUser)
  let user = {
    displayName: 'User',
    photoURL: ''
  }
  if (cachedUser) {
    user = {
      displayName: cachedUser.email,
      photoURL: ''
    }
  }

  // const user = {
  //   displayName: 'Test user',
  //   photoURL: ''
  // }
  // console.log(cachedUser)

  return (
    <MAvatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </MAvatar>
  );
}
