export enum FieldName {
    name = 'name',
    inn = 'inn',
    kpp = 'kpp',
    ogrn = 'ogrn',
    address = 'address',
    phone = 'phone',
    email = 'email',
}

export interface FormData {
    [FieldName.name]: string
    [FieldName.phone]?: string
    [FieldName.email]: string
    [FieldName.inn]?: string
    [FieldName.kpp]?: string
    [FieldName.ogrn]?: string
    [FieldName.address]?: string
}
