import {BalanceActionTypeEnum} from 'src/api/BalanceHistory/types'

export const getBalanceHistoryActionForDisplay = (action: BalanceActionTypeEnum) => {
  if (action === BalanceActionTypeEnum.ADD) {
    return 'Пополнение'
  }
  if (action === BalanceActionTypeEnum.SUB) {
    return 'Списание'
  }
  return action
}

export const formatDate = (dateFromApi: number | string) => {
  const date = new Date(dateFromApi);
  // console.log(date)
  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date)
}

export const getDateWithHoursSetToBeginOfTheDay = (date: Date) => {
  const returningDate = new Date(date)
  returningDate.setHours(0, 0, 0, 0)
  return returningDate
}
export const getDateWithHoursSetToEndOfTheDay = (date: Date) => {
  const returningDate = new Date(date)
  returningDate.setHours(23, 59, 59, 0)
  return returningDate
}