import {Draggable} from 'react-beautiful-dnd';
import {Box} from '@mui/material';

import {ITaskInApi} from "src/api/Task/types";
import StyledCardForBoard from "./StyledCardForBoard";
import RequestCard from "../RequestCard";


type RequestsBoardViewCardProps = {
  index: number;
  task: ITaskInApi,
  showDisplayRequestModal?: Function
};

export default function RequestsBoardViewCard({task, index, showDisplayRequestModal}: RequestsBoardViewCardProps) {
  const handleCardClick = () => {
    if (showDisplayRequestModal){
      showDisplayRequestModal(task.id)
    }
  }

  return (
    <Draggable draggableId={String(task.id)} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
          <StyledCardForBoard>
            <Box width={1} onClick={() => {
              handleCardClick()
            }} sx={{cursor: 'pointer'}}>
             <RequestCard task={task} />
            </Box>
          </StyledCardForBoard>
        </div>
      )}
    </Draggable>
  );
}
