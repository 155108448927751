import {ITaskInApi, TaskStatusEnum} from "../../../api/Task/types";
import {isDefined} from '../../../utils/object'
import {SortType} from "./types";

export const getErrorMsgByErrorForCreateTask = (err: any): string => {
    return 'Произошла ошибка при создании заявки. Повторите попытку'
}

export const getErrorMsgByErrorForChangeTaskStatus = (err: any): string => {
    // console.log('this is error')
    // console.log(err)
    if (chceckErrorTaskStatusIncludeErrorText(err, 'Change from status TaskStatus.CLOSED to TaskStatus.IN_WORK is not possible')) {
        return 'Нельзя изменть статус заявки с Закрыто на В работе'
    }
    if (chceckErrorTaskStatusIncludeErrorText(err, 'Change from status TaskStatus.CLOSED to TaskStatus.CREATED is not possible')) {
        return 'Нельзя изменть статус заявки с Закрыто на Новая'
    }
    if (chceckErrorTaskStatusIncludeErrorText(err, 'Change from status TaskStatus.IN_WORK to TaskStatus.CREATED is not possible')) {
        return 'Нельзя изменть статус заявки с В работе на Новая'
    }
    return 'Произошла ошибка при смене статуса заявки'
}



export const getStatusForDisplay = (status: TaskStatusEnum) => {
    if (status === TaskStatusEnum.CREATED) {
      return 'Создано'
    }
    if (status === TaskStatusEnum.IN_WORK) {
      return 'В работе c'
    }
    if (status === TaskStatusEnum.CLOSED) {
      return 'Закрыто c'
    }
    return status
  }



  const chceckErrorTaskStatusIncludeErrorText = (err: any, text: String) => {
    if (isDefined(err.description) &&
      err.description.includes(text)) {
        return true
    }
    return false
}
export const getNotAvailablyChangeTaskStatusMessage = (currentStatus: TaskStatusEnum, newStatusForCheck: TaskStatusEnum) =>{
  const names = {
    [TaskStatusEnum.CREATED]: 'Новая',
    [TaskStatusEnum.IN_WORK]: 'В работе',
    [TaskStatusEnum.CLOSED]: 'Закрытая'
  }
  return `Нельзя изменять статус заявки с ${names[currentStatus]} на ${names[newStatusForCheck]}`
}

const TaskStatusChangeAvailability = {
  [TaskStatusEnum.CREATED]: [TaskStatusEnum.IN_WORK, TaskStatusEnum.CLOSED],
  [TaskStatusEnum.IN_WORK]: [TaskStatusEnum.CLOSED],
  [TaskStatusEnum.CLOSED]: [] as TaskStatusEnum[]
}
export const checkIsNewStatusIsAvailabily = (currentStatus: TaskStatusEnum, newStatusForCheck: TaskStatusEnum) => {
  if (TaskStatusChangeAvailability[currentStatus].includes(newStatusForCheck)) {
    return true
  }
  return false
}

const SortTypeCorrespondingFieldName = {
  [SortType.BY_TITLE]: 'title',
  [SortType.BY_ID]: 'id',
  [SortType.BY_CREATED_AT]: 'createdAt',
  [SortType.BY_UPDATED_AT]: 'updatedAt',
  [SortType.BY_STATUS]: 'status',
  [SortType.BY_PRIORITY]: 'priority',
}

export const getFieldNameCorrespondingWithSortType= (sortType: SortType) => {
  return SortTypeCorrespondingFieldName[sortType] as keyof ITaskInApi
}