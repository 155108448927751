import React from "react";
import {Grid, InputAdornment} from "@mui/material";
import {ContactMail, HomeWork, Phone} from "@mui/icons-material";
import {makeRequired, TextField} from "mui-rff";
import * as Yup from "yup";


import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "src/defs/validation";
import {
    appMakeValidate,
    innValidation,
    kppValidation,
    ogrnValidation,
    rusPhoneValidation
} from "src/utils/validation";
import {AdornmentTextField} from "src/components/MUI/AdornmentTextField";

import OrganizationAutoComplete from "./OrganizationAutoComplete/OrganizationAutoComplete";
import {FieldName, FormData} from "./types";

Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
    [FieldName.name]: Yup.string().required(),
    [FieldName.phone]: rusPhoneValidation,
    [FieldName.email]: Yup.string().email().required(),
    [FieldName.inn]: innValidation.required(),
    [FieldName.kpp]: kppValidation,
    [FieldName.ogrn]: ogrnValidation,
    [FieldName.address]: Yup.string(),
})

export const validate = appMakeValidate(schema)

const required = makeRequired(schema)

// const fields = [
//     <Grid item xs={12}>
//         <OrganizationAutoComplete
//             name={FieldName.name}
//             required={required[FieldName.name]}
//             label={'Название организации'}
//         />
//     </Grid>,
//     <Grid item xs={12} sm={6}>
//         <TextField
//             InputProps={{
//                 startAdornment: (
//                     <InputAdornment position="start">
//                         <Phone/>
//                     </InputAdornment>
//                 ),
//             }}
//             label='Телефон'
//             name={FieldName.phone}
//             required={required[FieldName.phone]}
//         />
//     </Grid>,
//     <Grid item xs={12} sm={6}>
//         <TextField
//             InputProps={{
//                 startAdornment: (
//                     <InputAdornment position="start">
//                         <ContactMail/>
//                     </InputAdornment>
//                 ),
//             }}
//             label='Email'
//             type='email'
//             name={FieldName.email}
//             required={required[FieldName.email]}
//         />
//     </Grid>,
//     <Grid item xs={12} sm={4}>
//         <TextField
//             label='ИНН'
//             name={FieldName.inn}
//             required={required[FieldName.inn]}
//         />
//     </Grid>,
//     <Grid item xs={12} sm={4}>
//         <TextField
//             label='КПП'
//             name={FieldName.kpp}
//             required={required[FieldName.kpp]}
//         />
//     </Grid>,
//     <Grid item xs={12} sm={4}>
//         <TextField
//             label='ОГРН'
//             name={FieldName.ogrn}
//             required={required[FieldName.ogrn]}
//         />
//     </Grid>,
//     <Grid item xs={12}>
//         <TextField
//             InputProps={{
//                 startAdornment: (
//                     <AdornmentTextField>
//                         <HomeWork/>
//                     </AdornmentTextField>
//                 ),
//             }}
//             multiline
//             label='Юрид. адрес'
//             name={FieldName.address}
//             required={required[FieldName.address]}
//         />
//     </Grid>,
// ]
interface IUserGenralFormFieldsProps {
  disableFields: boolean
}

const UserGenralFormFields: React.FC<IUserGenralFormFieldsProps> = ({disableFields}) => (
  <Grid container spacing={2}>
    {/*{fields.map((field) => (*/}
    {/*    cloneElement(*/}
    {/*        field,*/}
    {/*        {*/}
    {/*            key: field.props.children.props.name,*/}
    {/*        },*/}
    {/*    )*/}
    {/*))}*/}
    <Grid item xs={12}>
      <OrganizationAutoComplete
        name={FieldName.name}
        required={required[FieldName.name]}
        label={'Название организации'}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Phone/>
            </InputAdornment>
          ),
        }}
        label='Телефон'
        name={FieldName.phone}
        required={required[FieldName.phone]}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMail/>
            </InputAdornment>
          ),
        }}
        label='Email'
        type='email'
        name={FieldName.email}
        required={required[FieldName.email]}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        label='ИНН'
        name={FieldName.inn}
        required={required[FieldName.inn]}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        label='КПП'
        name={FieldName.kpp}
        required={required[FieldName.kpp]}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12} sm={4}>
      <TextField
        label='ОГРН'
        name={FieldName.ogrn}
        required={required[FieldName.ogrn]}
        disabled={disableFields}
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        InputProps={{
          startAdornment: (
            <AdornmentTextField>
              <HomeWork/>
            </AdornmentTextField>
          ),
        }}
        multiline
        label='Юрид. адрес'
        name={FieldName.address}
        required={required[FieldName.address]}
        disabled={disableFields}
      />
    </Grid>
  </Grid>
)


export default UserGenralFormFields
