export const FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION = {
    mixed: {
        required: 'Обязательно',
    },
    string: {
        // eslint-disable-next-line no-template-curly-in-string
        length: 'Должно быть ровно ${length} символов',
        email: 'Неверный email',
    },
}
