import {TClientId, TCpeId, TLocationId, TSimCardId, TWiredUplinkId} from "src/types/entityIds";
import {IClientLocation} from "../ClientLocation/types";
import {IClient} from "../Client/types";
import {ICpeType} from "../CpeType/types";
import {IMobileInterfaceAggStatus, IWiredInterfaceAggStatus} from "../CpeInterface/types";

export enum CpeBackupType {
    aggregation = 'aggregation',
    failover = 'failover',
}

export interface ICpeInApiBase {
    backupType?: CpeBackupType,
    id: TCpeId
    clientId: number
    clientLocationId: number | null
    hostname?: string
    lanIp?: string,
    // TODO: разобраться с типом
    // 0..32
    lanNetmask?: number,
    // without any separator and lower case: a1b2c345de6f
    mac?: string,
    managementInterfaceLinkup: boolean
    name?: string
    partnerId: number | null
    partnerRelType: string  // really enum
    serial: string
    typeId: number
    uuid: string | null
    wifi24Ssid?: string,
    wifi24Password?: string,
    wifi50Ssid?: string,
    wifi50Password?: string,
    createdAt: string
    updatedAt: string | null
}

// NB: Empty array if CPE is not assigned to any Client
export type ICpeAncestors = [
    IClientLocation,
    IClient,
] | []

export interface ICpeWithType extends ICpeInApiBase {
    type: ICpeType
}

export type WithRawAnyProblem<T extends {}> =
    & Omit<T, 'anyProblem'>
    & { resAnyProblem: boolean }

export interface ICpeWithAncestorsNTypeNProblem extends ICpeWithType {
    ancestors: ICpeAncestors
    aggStatus: ICpeAggStatus
}

export interface ICpeWithCounters extends ICpeInApiBase {
    cpeType: string
    numSimCardsAssigned: number
    numSimCardsSlots: number
    numWiredUplinksAssigned: number
    numWiredUplinksSlots: number
    locationId: number | null
    aggStatus: ICpeAggStatus
    managementLinkup: boolean
}

interface IGetCpesBaseProps {
    assignedFilter: AssignedFilter
}

export type IGetCpesProps = IGetCpesBaseProps
    & (
        | {}
        | { clientId: TClientId }
        | { locationId: TLocationId }
    )

export enum AssignedFilter {
    all = 'all',
    onlyAssigned = "onlyAssigned",
    onlyNotAssigned = "onlyNotAssigned"
}

export interface IDataCpes {
    all: number
    withProblems: number
    free: number
}

export interface IDowntimeDataProps {
    timeStampFromSec: number
    timeStampToSec: number
}

export interface ICpesDowntime {
    datetime: number, // timestamp
    ok: number,
    withProblems: number,
}

export interface IDashboardDataDowntime {
    inMonthSec: number
    inMonthPercent: number
    todaySec: number
    todayPercent: number
    graph: ICpesDowntime[]
}

export interface ICpeDiagProps {
    cpeId: TCpeId
    target: string
}

export type IDiagResponse = string

enum CpeError {
    no_wdm_interfaces = 'no_wdm_interfaces',
    usb_error = 'usb_error',
    all_sims_not_inserted = 'all_sims_not_inserted',
}

export interface ICpeAggStatus {
    wiredUplinks: (
        | {
            id: TWiredUplinkId
            interface: IWiredInterfaceAggStatus
            assigned: boolean
        }
        | {
            // only assigned, but not discovered
            id: TWiredUplinkId
            interface: null
            assigned: true
        }
    )[]
    mobileUplinks: (
        | {
            id: TSimCardId
            interface: IMobileInterfaceAggStatus | null
            assigned: boolean
        }
        | {
            // only assigned, but not discovered
            id: TSimCardId
            interface: null
            assigned: true
        }
    )[]
    managementInterfaceLinkup: boolean
    error: CpeError | null
}

export type WithRawProblem<T extends {}> = T
    & { aggStatus: ICpeAggStatus }
