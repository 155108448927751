import React from "react";


interface IAlertIconProps {
  circleFillColor?: string,
  iconFillColor?: string,
  height?: string
  width?: string,
  withStroke?: boolean,
  strokeColor?: string
}

const AlertIcon: React.FC<IAlertIconProps> = ({circleFillColor, iconFillColor, height, width, withStroke, strokeColor}) => {
  return (
    !withStroke ?
      <svg width={width ? width : '48'} height={height ? height : '48'} viewBox="0 0 48 48" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill={circleFillColor ? circleFillColor : "#FFE7D9"}/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M33.856 28.0134L26.6974 16.1414C26.1091 15.2212 25.0922 14.6643 24 14.6643C22.9078 14.6643 21.891 15.2212 21.3027 16.1414L14.144 28.0134C13.6293 28.8714 13.6115 29.9388 14.0974 30.8134C14.6592 31.7982 15.7076 32.4044 16.8414 32.4001H31.1587C32.2848 32.4121 33.3313 31.8212 33.9027 30.8508C34.403 29.9665 34.3852 28.8807 33.856 28.0134ZM24 28.6667C23.4846 28.6667 23.0667 28.2489 23.0667 27.7334C23.0667 27.2179 23.4846 26.8001 24 26.8001C24.5155 26.8001 24.9334 27.2179 24.9334 27.7334C24.9334 28.2489 24.5155 28.6667 24 28.6667ZM24 25.8667C24.5155 25.8667 24.9334 25.4489 24.9334 24.9334V21.2001C24.9334 20.6846 24.5155 20.2667 24 20.2667C23.4846 20.2667 23.0667 20.6846 23.0667 21.2001V24.9334C23.0667 25.4489 23.4846 25.8667 24 25.8667Z"
              fill={iconFillColor ? iconFillColor : "#B72136"}/>
      </svg>
      :
      <svg xmlns="http://www.w3.org/2000/svg" width={width ? width : '48'} height={height ? height : '48'}  viewBox="0 0 64 64">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g>
              <path d="M0,32A32,32,0,0,1,32,0h0A32,32,0,0,1,64,32h0A32,32,0,0,1,32,64h0A32,32,0,0,1,0,32Z" fill={strokeColor ? strokeColor : 'white'}/>
              <circle cx="32" cy="32" r="24"  fill={circleFillColor ? circleFillColor : "#FFE7D9"} />
              <path
                d="M41.86,36,34.7,24.14a3.2,3.2,0,0,0-5.4,0L22.14,36a2.8,2.8,0,0,0,0,2.8,3.14,3.14,0,0,0,2.74,1.59H39.16a3.15,3.15,0,0,0,2.74-1.55A2.8,2.8,0,0,0,41.86,36ZM32,36.67a.94.94,0,1,1,.93-.94A.93.93,0,0,1,32,36.67Zm0-2.8a.93.93,0,0,0,.93-.94V29.2a.93.93,0,0,0-1.86,0v3.73A.93.93,0,0,0,32,33.87Z"
                 fill={iconFillColor ? iconFillColor : "#B72136"} style={{fillRule: 'evenodd'}}/>
            </g>
          </g>
        </g>
      </svg>


  );
}
export default AlertIcon