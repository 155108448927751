import React from 'react';
import {LoadingButton} from '@mui/lab';
import {
  Stack, Box, Skeleton, Typography
} from '@mui/material';
import {useSnackbar} from "notistack";
import {Switches, SwitchData} from 'mui-rff';
import {Form} from "react-final-form";

import {FFORMS_SUBSCRIPTION} from "../../../../defs/forms";
import {canStepNextInWizard} from "src/utils/forms";
import {useGetUserSubscriptionsQuery, useChangeUserSubscriptionsMutation} from "src/api/User/User";
import {ClientNotificationEventTypeEnum, NotificationTransportType, ISubscriptions} from "src/api/User/types";
import {IHttpResponseBase} from "../../../../api/types";


const notificationSwitchers: SwitchData[] = [
  {label: 'Ежемесячная сводка', value: ClientNotificationEventTypeEnum.monthlyNewsletter},
  {label: 'Уведомлять о необходимости оплаты', value: ClientNotificationEventTypeEnum.needPayment},
  {label: 'Уведомлять об ответах на заявки', value: ClientNotificationEventTypeEnum.taskResponse}
]

interface FormData {
  notifications: string[],
}

const NotificationsForm: React.FC = () => {
  const {enqueueSnackbar} = useSnackbar();
  const {
    data: subscriptionsData,
    isFetching: subscriptionsIsFetching,
    // isError: subscriptionsIsError,
    refetch: subscriptionsRefetch
  } = useGetUserSubscriptionsQuery(undefined, {refetchOnMountOrArgChange: true})

  const [
    putSubscriptions,
    {
      // data: dataAfterChange,
      isLoading: isPutLoading,
    }
  ] = useChangeUserSubscriptionsMutation()

  const prepareDataFromAPIToInitForm = (
      dataFromAPI: IHttpResponseBase<ISubscriptions> | undefined,
  ): FormData | undefined => {
    if (!dataFromAPI?.data) {
      return undefined
    }
    return {
      notifications: Object.entries(dataFromAPI.data)
                          .filter(([k, v]) => v.includes(NotificationTransportType.email))
                          .map(
                              ([k, v]) => ClientNotificationEventTypeEnum[k as keyof typeof ClientNotificationEventTypeEnum]
                          ),
    }
  }

  const prepareDataFromFormToSendToAPI = (values: FormData) => {
    const preparedDataToSend = {
      [ClientNotificationEventTypeEnum.monthlyNewsletter]: [] as NotificationTransportType[],
      [ClientNotificationEventTypeEnum.needPayment]: [] as NotificationTransportType[],
      [ClientNotificationEventTypeEnum.taskResponse]: [] as NotificationTransportType[],
    }

    values.notifications.forEach((notification) => {
      preparedDataToSend[notification as ClientNotificationEventTypeEnum] = [NotificationTransportType.email]
    })

    return preparedDataToSend
  }

  const onSubmit = async (values: FormData) => {
    const preparedDataToSend = prepareDataFromFormToSendToAPI(values)
    // console.log(preparedDataToSend)
    // console.log(prepareDataFromAPIToInitForm(subscriptionsData))
    const returnedFromAPIVal = await putSubscriptions(preparedDataToSend)
    if ('error' in returnedFromAPIVal) {
      enqueueSnackbar('Изменения не были применены', {variant: 'error'});
      console.log('putSubscriptions.onSubmit.error')
    } else {
      enqueueSnackbar('Изменения успешно применены', {variant: 'success'});
      subscriptionsRefetch()
      console.log('putSubscriptions.onSubmit.ok')
    }

  }

  return (
    subscriptionsIsFetching || isPutLoading ?
      <Stack>
        {Array.from(Array(notificationSwitchers.length ? notificationSwitchers.length : 3).keys()).map((count) => {
          return (
            <Typography key={count} variant={'h4'}>
              <Skeleton/>
            </Typography>
          );
        })
        }
        <Stack direction="row" width={'100%'} justifyContent="flex-end" sx={{my: 2}}>
          <LoadingButton
            variant='contained'
            color='primary'
            loading={true}
          >
            Сохранить
          </LoadingButton>
        </Stack>

      </Stack>
      :
      <Form<FormData>
        initialValues=
          {
            prepareDataFromAPIToInitForm(subscriptionsData)
          }
        onSubmit={onSubmit}
        subscription={{
          ...FFORMS_SUBSCRIPTION,
          values: true,
          dirty: true,
        }}
        render={(props) => {
          const {
            dirty,
            handleSubmit,
          } = props

          return (
            <Box component='form' onSubmit={handleSubmit}
                 noValidate>

              {/*{isError*/}
              {/*    // @ts-ignore Property does not exist on type*/}
              {/*    && <p>Произошла ошибка {error?.status}</p>*/}
              {/*}*/}

              <Switches
                disabled={isPutLoading}
                size={'medium'}
                name="notifications"
                required={true}
                data={notificationSwitchers}
                showError={() => false}
              />
              <Stack direction="row" width={'100%'} justifyContent="flex-end" sx={{my: 2}}>
                <LoadingButton
                  variant='contained'
                  color='primary'
                  type='submit'
                  disabled={
                    !canStepNextInWizard(props)
                    || !dirty
                  }
                  loading={subscriptionsIsFetching
                    || isPutLoading}
                >
                  Сохранить
                </LoadingButton>
              </Stack>
            </Box>


          )
        }}
      />


  );

}

export default NotificationsForm
