import {fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {IHttpResponseBase} from "../types";
import {emptySplitApi} from "../base";
import {AUTH_BASE_URL} from "../constants";
import {
  IConfirmPasswordApiResponse, IConfirmPasswordProps,
  ILoginProps,
  IOTPCodeApiResponse,
  IOTPCodeProps,
  IResetPasswordApiResponse, IResetPasswordProps,
  IUserApiRegistrationResponse,
  IUser, IUserRegistrationProps, IChangePasswordForLoginedUserProps, IChangePasswordForLoginedUserApiResponse, ISubscriptions
} from "./types";

const authBaseQuery = fetchBaseQuery({baseUrl: AUTH_BASE_URL})

const userApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query<IHttpResponseBase<IUser>, void>({
            query: () => '/user',
        }),
        login: build.mutation<IHttpResponseBase<IUser>, ILoginProps>({
            queryFn: async (
                values,
                sys,
            ) => {
                const encodedValues = Object.entries(values)
                    .map(([key, value]) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                    ).join('&')
                // FIXME: type any
                return (authBaseQuery(
                    {
                        url: '/login',
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        body: encodedValues,
                    },
                    sys,
                    {},
                ) as any)
            },
        }),
        logout: build.mutation<IHttpResponseBase<IUser>, void>({
            queryFn: async (
                _,
                sys,
            ) => {
                return (authBaseQuery(
                    {
                        url: '/logout',
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                    },
                    sys,
                    {},
                ) as any)
            }
        }),
        register: build.mutation<IUserApiRegistrationResponse, IUserRegistrationProps>({
            queryFn: async (
                values,
                sys,
            ) => {
                // const encodedValues = Object.entries(values)
                //     .map(([key, value]) =>
                //         `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                //     ).join('&')
                return (authBaseQuery(
                    {
                        url: '/register',
                        method: 'POST',
                        // headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        body: values,
                    },
                    sys,
                    {},
                ) as any)
            },
        }),
       otpCodeEnter: build.mutation<IOTPCodeApiResponse, IOTPCodeProps>({
            queryFn: async (
                values,
                sys,
            ) => {
                const encodedValues = Object.entries(values)
                    .map(([key, value]) =>
                        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                    ).join('&')
                return (authBaseQuery(
                    {
                        url: '/verify',
                        method: 'POST',
                        headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        body: encodedValues,
                    },
                    sys,
                    {},
                ) as any)
            },
        }),
      resetPassword: build.mutation<IResetPasswordApiResponse, IResetPasswordProps>({
            queryFn: async (
                values,
                sys,
            ) => {
                // const encodedValues = Object.entries(values)
                //     .map(([key, value]) =>
                //         `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
                //     ).join('&')
                return (authBaseQuery(
                    {
                        url: '/reset_password',
                        method: 'POST',
                        // headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                        body: values,
                    },
                    sys,
                    {},
                ) as any)
            },
        }),
      confirmPassword: build.mutation<IConfirmPasswordApiResponse, IConfirmPasswordProps>({
            queryFn: async (
                values,
                sys,
            ) => {
                return (authBaseQuery(
                    {
                        url: '/apply_password',
                        method: 'POST',
                        body: values,
                    },
                    sys,
                    {},
                ) as any)
            },
        }),
        changePasswordForLoginedUser: build.mutation<IChangePasswordForLoginedUserApiResponse, IChangePasswordForLoginedUserProps>({
            query: ({oldPassword, newPassword}) => ({
                url: `/change_password`,
                method: 'POST',
                body: {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                }
            }),
        }),
      getUserSubscriptions: build.query<IHttpResponseBase<ISubscriptions>, void>({
            query: () => '/user/subscriptions',
        }),
      changeUserSubscriptions: build.mutation<IHttpResponseBase<any>, ISubscriptions>({
            query: (values) => ({
                url: '/user/subscriptions',
                method: 'PUT',
                body: { ...values },
            })
        }),
    }),
})

export const {
    endpoints: {
        getUser,
        login,
        logout
    },
    useGetUserQuery,
    useLoginMutation,
    useLogoutMutation,
    useRegisterMutation,
    useOtpCodeEnterMutation,
    useResetPasswordMutation,
    useConfirmPasswordMutation,
    useChangePasswordForLoginedUserMutation,
    useGetUserSubscriptionsQuery,
    useChangeUserSubscriptionsMutation
} = userApi