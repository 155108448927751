import {Navigate, useRoutes} from 'react-router-dom';

// Error Pages
import Page404 from '../containers/Page404'

// Layouts
import DashboardLayout from '../containers/DashboardLayout/DashboardLayout'
import Dashboard from "../containers/DashboardLayout/Dashboard/Dashboard";
// import ClientAccount from "../containers/DashboardLayout/ClientAccount/ClientAccount";
import Devices from "../containers/DashboardLayout/Devices/Devices";
import Requests from "../containers/DashboardLayout/Requests/Requests"


// User aka Accounting Page
import User from "../containers/DashboardLayout/User/User"
import UserGeneral from '../containers/DashboardLayout/User/UserGeneral/UserGeneral'
import Tariffs from "../containers/DashboardLayout/User/Tariffs/Tariffs";
import Passwords from "../containers/DashboardLayout/User/Passwords/Passwords"
import Notifications from  "../containers/DashboardLayout/User/Notifications/Notifications"

// Auth Layout
import AuthLayout from '../containers/Auth/AuthLayout'
import ResetPassword from '../containers/Auth/ResetPassword/ResetPassword'
import Registration from "../containers/Auth/Registration/Registration";
import OTPEnter from "../containers/Auth/OTPEnter/OTPEnter";
import NewPassword from "../containers/Auth/NewPassword/NewPassword";

// guards
import AuthGuard from '../guards/AuthGuard';


export default function Router() {
  return useRoutes([
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout/>
        </AuthGuard>
      ),
      children: [
        {path: 'main', element: <Dashboard/>},
        // {path: 'account', element: <ClientAccount/>},
        {path: 'devices', element: <Devices/>},
        {path: 'requests', element: <Requests/>},
        // {path: 'tariffs', element: <Tariffs/>},
        {
          path: 'user', element: <User/>,
          children: [
            {path: 'general', element: <UserGeneral/>},
            {path: 'tariffs', element: <Tariffs/>},
            {path: 'notifications', element: <Notifications/>},
            {path: 'passwords', element: <Passwords/>}
          ]
        }
      ]
    },
    {path: 'reg', element: <AuthLayout/>, children:[{path:':referral_code',element: <Registration/>}]},
    {
      path: 'auth',
      element: (
        <AuthLayout/>
      ),
      children: [
        {path: 'reset-password', element: <ResetPassword/>},
        {path: 'registration', element: <Registration/>},
        {path: 'otp-enter', element: <OTPEnter/>},
        {path: 'confirm-password', element: <NewPassword/>},
      ]
    },
    {path: '/', element: <Navigate to="/dashboard" replace/>},
    {path: '/404', element: <Page404/>},
    {path: '*', element: <Navigate to="/404" replace/>}
  ]);
}


