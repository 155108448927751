import {Box, Stack, Typography} from '@mui/material';
import {useMemo} from "react";
import CheckIcon from "@mui/icons-material/Check";
import {LoadingButton, Skeleton} from '@mui/lab';
import {styled, useTheme} from "@mui/material/styles";
import {useSnackbar} from "notistack";

import {useChangeTaskStatusMutation} from "../../../api/Task/Task";
import {extractIdMakeRecordset, isDefined} from "../../../utils/object";
import {ITaskInApi, TaskStatusEnum} from "../../../api/Task/types";
import PriorityChip from "./PriorityStatusesChips/PriorityChip";
import TypeChip  from "./TypeChips/TypeChip";
import {getErrorMsgByErrorForChangeTaskStatus, getStatusForDisplay} from './utils'
import {formatDateTask} from "./Requests";
import StatusRectangles from './StatusRectangles'
import {useGetCpesQuery} from "../../../api/ClientCpe/ClientCpe";
import {AssignedFilter} from "../../../api/ClientCpe/types";
import {formatCpeSerial} from "../../../utils/format";
import {defaultIfUndef} from "../../../utils/func";




interface  IDisplayRequestBlockProps {
  task: ITaskInApi | undefined,
  modalClose?: Function
  refetchRequestsData?: Function
}


const StyledSpan = styled('span')(({theme}) => ({
  marginLeft: '1em'
}));

const ItemHeaderTypographyStyled = styled(Typography)(({theme}) => ({
  color: theme.palette.text.disabled,
}));
const ItemHeaderTypographyStyledForText = styled(ItemHeaderTypographyStyled)(({theme}) => ({
  display: 'inline'
}));


const DisplayRequestBlock: React.FC<IDisplayRequestBlockProps> = ({task, modalClose, refetchRequestsData}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const cpesQuery = useGetCpesQuery({assignedFilter: AssignedFilter.all}, {refetchOnMountOrArgChange: true})
  const cpesWithIdKeys = useMemo(() => {
      return extractIdMakeRecordset(cpesQuery.data?.data || [])
    }, [cpesQuery.data])
  const [
    changeTaskStatusMutation,
    {
      isLoading: isLoadingChangeTaskStatus
    }
  ] = useChangeTaskStatusMutation()
  const handleCloseButtonClick = async () => {
    if (task) {
      const returnedFromAPIVal = await changeTaskStatusMutation({taskId: task.id, newStatus: TaskStatusEnum.CLOSED})
      console.log('Change task status.obSubmit.result', returnedFromAPIVal)
      if ('error' in returnedFromAPIVal) {
        console.log('Change task status.onSubmit.error')
        try {
          // @ts-ignore
          enqueueSnackbar(getErrorMsgByErrorForChangeTaskStatus(returnedFromAPIVal.error.data.errors[0]), {
            variant: 'warning',
            autoHideDuration: 5000
          });
        } catch (e) {
          enqueueSnackbar('Ошибка при обновлении статуса заявки', {variant: 'error', autoHideDuration: 5000});
        }
      } else {
        console.log('Change task status.onSubmit.success')
        enqueueSnackbar('Заявка закрыта', {variant: 'success', autoHideDuration: 5000});
        if (refetchRequestsData) {
          refetchRequestsData()
        }
        if (modalClose){
          modalClose()
        }

      }
    }
  }

  return (
    !task ?
      <span>Ошибка загрузки данных</span>
    :
    <Stack direction={'column'}>
      {

        task?.status !== TaskStatusEnum.CLOSED &&
        <Box sx={{width: '40%', mb: 2}}>
          <LoadingButton
            color="primary"
            onClick={() => {
              handleCloseButtonClick()
            }}
            loadingPosition="start"
            loading={isLoadingChangeTaskStatus}
            startIcon={<CheckIcon/>}
            variant="contained"
            size={'small'}
          >
            Закрыть заявку
          </LoadingButton>
        </Box>

      }
      <Typography variant={'body2'} sx={{color: theme.palette.text.disabled, mb: 1, mt:0}}>Заявка №{task?.id}</Typography>
      <Typography variant={'h6'} sx={{mb: 4}}>
        {task.title}
      </Typography>
      <Box
        sx={{mb: 0.5}}><ItemHeaderTypographyStyledForText>{getStatusForDisplay(task.status as TaskStatusEnum)}&nbsp;</ItemHeaderTypographyStyledForText>
        {task.status === TaskStatusEnum.CREATED ?
          (isDefined(task.createdAt)
            ? <ItemHeaderTypographyStyledForText>{formatDateTask(task.createdAt)}</ItemHeaderTypographyStyledForText>
            : <ItemHeaderTypographyStyledForText>Не определено</ItemHeaderTypographyStyledForText>)
          :
          (isDefined(task.updatedAt)
            ?
            <ItemHeaderTypographyStyledForText>{formatDateTask(task.updatedAt as string)}</ItemHeaderTypographyStyledForText>
            : <ItemHeaderTypographyStyledForText>Неопределено</ItemHeaderTypographyStyledForText>)
        }
      </Box>
      <Box>
        <StatusRectangles sx={{mb:3}} status={task.status as TaskStatusEnum}/>
      </Box>
      {
        cpesQuery.isFetching ?
          <Typography variant="h3"><Skeleton /></Typography>
          :
          <Stack direction={'row'} sx={{mb: 3}}>
            <ItemHeaderTypographyStyled variant={'body1'}>Устройство</ItemHeaderTypographyStyled>
            {task.assignedToCpeId && cpesWithIdKeys[task.assignedToCpeId] ?
              <StyledSpan>{defaultIfUndef(cpesWithIdKeys[task.assignedToCpeId].name, 'Нет имени')} (S/N: {formatCpeSerial(cpesWithIdKeys[task.assignedToCpeId].serial)})</StyledSpan>
              :
              <StyledSpan>Устройство не задано</StyledSpan>
            }
          </Stack>
      }

      <Stack direction={'row'} sx={{mb: 3}} >
        <ItemHeaderTypographyStyled variant={'body1'}>Приоритет</ItemHeaderTypographyStyled>
        <PriorityChip sx={{ml: 2}} priority={task.priority}   />
      </Stack>
      <Stack direction={'row'} sx={{mb: 3}} >
        <ItemHeaderTypographyStyled variant={'body1'}>Тип заявки</ItemHeaderTypographyStyled>
        <TypeChip sx={{ml: 2}} type={task.requestType}   />
      </Stack>
      <Box sx={{mb: 3, maxHeight: '20em', overflow:'auto'}} >
          <ItemHeaderTypographyStyledForText variant={'body1'}>Комментарий клиента: </ItemHeaderTypographyStyledForText>
          <StyledSpan>{task.text}</StyledSpan>
      </Box>
    </Stack>


  )

}
export default DisplayRequestBlock
