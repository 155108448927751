import {Skeleton} from '@mui/material';
import StyledCardForBoard from "./StyledCardForBoard";


export default function RequestsBoardViewCardSkeleton() {
  return (
    <StyledCardForBoard>
      <Skeleton/>
      <Skeleton/>
      <Skeleton/>
      <Skeleton/>
    </StyledCardForBoard>

  );
}
