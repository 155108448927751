import {useTimeout} from "react-use";
import {useEffect} from "react";

export const useTimeDelay = (isLoading: boolean, waitUntilShowLoaderMs: number) => {
    const [
        isTimeoutExceeded,
        cancelTimeout,
        resetTimeout,
    ] = useTimeout(waitUntilShowLoaderMs)
    useEffect(
        () => {
            if (isLoading) {
                resetTimeout()
            } else {
                cancelTimeout()
            }
        },
        [cancelTimeout, resetTimeout, isLoading],
    )
    return isLoading && isTimeoutExceeded() === true
}
