import { useSnackbar } from 'notistack';
import {LoadingButton} from '@mui/lab';
import { useRef, useState, useEffect } from 'react';
import { alpha } from '@mui/material/styles';
import {  Box, Divider, Typography } from '@mui/material';
import { MIconButton } from '../../external/minimal/components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../external/minimal/components/MenuPopover';
import {useAppSelector} from "src/app/hooks";
import UserStore from "src/redux/User";
import {useLogoutMutation} from "src/api/User/User";




// ----------------------------------------------------------------------

// const MENU_OPTIONS = [
//   // {
//   //   label: 'Дашборд',
//   //   icon: '',
//   //   linkTo: '/dashboard/dashboard'
//   // },
//   // {
//   //   label: 'Profile',
//   //   icon: personFill,
//   //   linkTo: PATH_DASHBOARD.user.profile
//   // },
//   // {
//   //   label: 'Settings',
//   //   icon: settings2Fill,
//   //   linkTo: PATH_DASHBOARD.user.account
//   // }
// ];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const cachedUser = useAppSelector(({
        [UserStore.name]: {
            userInfo: user
        }
    }) => user)

  const [
    logoutRequest,
    logoutMutationResult
  ] = useLogoutMutation()

  useEffect(() => {
    if (logoutMutationResult.error) {
      enqueueSnackbar('Произошла ошибка при выходе', {variant: 'error'});
      logoutMutationResult.reset()
    }
  })

  const handleLogout = () => {
    logoutRequest()
  }


  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400 }}
      >
        {/*<Box sx={{ my: 1.5, px: 2.5 }}>*/}
        {/*  <Typography variant="subtitle1" noWrap>*/}
        {/*    {user?.displayName}*/}
        {/*  </Typography>*/}
        {/*  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>*/}
        {/*    {user?.email}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {cachedUser?.username}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {cachedUser?.email}
          </Typography>
        </Box>



        <Divider sx={{ my: 1 }} />

        {/*{MENU_OPTIONS.map((option) => (*/}
        {/*  <MenuItem*/}
        {/*    key={option.label}*/}
        {/*    to={option.linkTo}*/}
        {/*    component={RouterLink}*/}
        {/*    onClick={handleClose}*/}
        {/*    sx={{ typography: 'body2', py: 1, px: 2.5 }}*/}
        {/*  >*/}
        {/*    <Box*/}
        {/*      component={Icon}*/}
        {/*      icon={option.icon}*/}
        {/*      sx={{*/}
        {/*        mr: 2,*/}
        {/*        width: 24,*/}
        {/*        height: 24*/}
        {/*      }}*/}
        {/*    />*/}

        {/*    {option.label}*/}
        {/*  </MenuItem>*/}
        {/*))}*/}

        {/*<Box sx={{ p: 2, pt: 1.5 }}>*/}
        {/*  <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>*/}
        {/*    Logout*/}
        {/*  </Button>*/}
        {/*</Box>*/}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <LoadingButton
            loading={logoutMutationResult.isLoading}
            fullWidth color="inherit"
            variant="outlined"
            onClick={handleLogout}>
            Выйти
          </LoadingButton>
        </Box>
      </MenuPopover>
    </>
  );
}
