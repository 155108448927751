import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField} from "mui-rff";
import * as Yup from 'yup';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {
  Stack,
  IconButton,
  InputAdornment,
  Box, Alert
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from "notistack";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../defs/forms";
import {FieldName, FormData} from "./types";
import {useRegisterMutation} from "../../../api/User/User";
import {IUserRegistrationProps} from "../../../api/User/types"
import {getErrorMsgByErrorForRegistration} from "../utils";
import {canStepNextInWizard} from "../../../utils/forms";
import {PATH_AUTH} from '../../../routes/path'
import GoBackLink from "../../../components/GoBackLink";


Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)
// const phoneRegExp = /^((\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/
const phoneRegExp = /^(\+7)\d{10}$/

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.email]: Yup.string().email().required(),
  [FieldName.password]: Yup.string().required(),
  [FieldName.phone]: Yup.string().required().matches(phoneRegExp, 'Введите номер телефона в правильном формате'),
  [FieldName.name]: Yup.string().required(),
})

const validate = makeValidate(schema)
const required = makeRequired(schema)

interface IRegistrationFormProps {
  referralCode?: string
}

const RegistrationForm: React.FC<IRegistrationFormProps> = ({referralCode}) => {
  const { enqueueSnackbar } = useSnackbar();
    const [
    register,
    {
      error: registerError,
      isError: registerIsError,
      isLoading: registerIsLoading
    }
  ] = useRegisterMutation()

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const fields = [
    <TextField
      fullWidth
      label='Email'
      type='email'
      name={FieldName.email}
      required={required.email}
      size='small'
      showError={showErrorOnBlur}
    />,
    <TextField
      fullWidth
      label='Имя'
      type='text'
      name={FieldName.name}
      required={required.name}
      size='small'
      showError={showErrorOnBlur}
    />,
    <TextField
      fullWidth
      label='Телефон (в формате +7ХХХХХХХХХХ)'
      type='text'
      name={FieldName.phone}
      required={required.phone}
      size='small'
      showError={showErrorOnBlur}
    />,
    <TextField
      fullWidth
      label='Пароль'
      type={showPassword ? 'text' : 'password'}
      size='small'
      name={FieldName.password}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword} edge="end">
              <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
            </IconButton>
          </InputAdornment>
        )
      }}
      required={required.password}
      showError={showErrorOnBlur}

    />,
  ]

  const onSubmit = async (values: FormData) => {
    console.log('registration req')
    // navigate(PATH_AUTH.otpEnter, { replace: true })
    console.log('Register.onSubmit', values)
    const valuesForSubmit:IUserRegistrationProps = {...values, referral_code: referralCode }
    const returnedFromAPIVal = await register(valuesForSubmit)
    console.log('Register.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      console.log('Register.onSubmit.error')
    } else {
      console.log('Register.onSubmit.success')
      if (returnedFromAPIVal.data.data.verified === false) {
        enqueueSnackbar('На электронную почту отправлено письмо с кодом для подтверждения регистрации', {
          variant: 'success',
          autoHideDuration: 5000
        });
        navigate(PATH_AUTH.otpEnter, {replace: false})
      }
    }
  }

  return <Form<FormData>
    onSubmit={onSubmit}
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      return <form
        onSubmit={props.handleSubmit}
        noValidate
      >
        <div>
          <Stack spacing={3}>
            {(registerError || registerIsError) && <Alert severity="error">{getErrorMsgByErrorForRegistration(registerError)}</Alert>}
            {fields.map((field) => (
              <div
                key={field.props.name}
                // xs={12}
              >
                {field}
              </div>
            ))}
          </Stack>

          <Box  sx={{my: 2}}>
            {!referralCode && <GoBackLink/>}
          </Box>


        </div>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={registerIsLoading}
          disabled={
            !canStepNextInWizard(props)
          }

        >
          Зарегистрироваться
        </LoadingButton>
      </form>
    }}
  />

}
export default RegistrationForm
