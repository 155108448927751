export enum FieldName {
    email = 'email',
    password = 'password',
    phone = 'phone',
    name = 'name',
}

export interface FormData {
    [FieldName.email]: string
    [FieldName.password]: string
    [FieldName.phone]: string
    [FieldName.name]: string
}
