import {isDefined} from '../../../../utils/object'

export const getErrorMsgByErrorForChangePassword = (err: any): string => {
   if  (chceckErrorIncludeErrorText(err, 'invalid password')){
       return 'Неверный пароль'
   }
    return 'Произошла ошибка при изменении пароля. Повторите попытку'
}


const chceckErrorIncludeErrorText = (err: any, text: String) => {
  try {
    // console.log('chceckErrorIncludeErrorText', err)
    if (isDefined(err.data) &&
      err.data !== undefined && typeof err.data === 'object' &&
      err.data.data !== undefined && typeof err.data.data === 'object' &&
      err.data.data.errors !== undefined && Array.isArray(err.data.data.errors) &&
      err.data.data.errors.length) {
      for (const errorFormErrorsList of err.data.data.errors) {
        if (errorFormErrorsList.name === text) {
          return true
        }
      }
    }
    return false
  } catch (e) {
    console.log(e)
    return false
  }

}

