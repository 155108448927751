import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField} from "mui-rff";
import * as Yup from 'yup';
import React, {useMemo, useState} from 'react';
import {Alert, Box, InputAdornment, MenuItem, Skeleton, Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useTheme} from "@mui/material/styles";
import {useSnackbar} from "notistack";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../defs/forms";
import {FieldName, FormData, RequestsPriority, RequestsType} from "./types";
import {useCreateTaskMutation} from "../../../api/Task/Task";
import {getErrorMsgByErrorForCreateTask} from "./utils";
import {defaultIfUndef} from "src/utils/func";
import {canStepNextInWizard} from "../../../utils/forms";
import PriorityChip from "./PriorityStatusesChips/PriorityChip";
import TypeChip from "./TypeChips/TypeChip";
import {useGetCpesQuery} from "../../../api/ClientCpe/ClientCpe";
import {AssignedFilter, ICpeWithCounters} from "../../../api/ClientCpe/types";
import {extractIdMakeRecordset} from "../../../utils/object";
import {formatCpeSerial} from "src/utils/format";
import {useGetLocationsWithCountersQuery} from "../../../api/ClientLocation/ClientLocation";
import {getCombinedParamsFromRtkqHook} from "../../../utils/rtkq";



Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const MAX_TITLE_FIELD_SIZE = 128

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.title]: Yup.string().required().max(MAX_TITLE_FIELD_SIZE, `Не более ${MAX_TITLE_FIELD_SIZE} символов`),
  [FieldName.text]: Yup.string().required(),
  [FieldName.assignedToCpeId]: Yup.number()

})

const validate = makeValidate(schema)
const required = makeRequired(schema)

interface  ICreateNewRequestFormProps {
  refetchOnMountOrArgChangeLocationsAndCpesData: boolean,
  modalClose?: Function
  refetchRequestsData?: Function,
  cpeId?: number
}

const requestsTypes = [
  RequestsType.MAKE_BETTER,
  RequestsType.NETWORK_PROBLEMS,
  RequestsType.ADD_NEW_ADDRESS,
  RequestsType.ADD_NEW_CPE,
  RequestsType.OTHER
]

const requestsPrioritys = [
  RequestsPriority.LOW,
  RequestsPriority.MEDIUM,
  RequestsPriority.HIGH
]

const CreateNewRequestForm: React.FC<ICreateNewRequestFormProps> = ({refetchOnMountOrArgChangeLocationsAndCpesData = true, modalClose, refetchRequestsData, cpeId}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme()
  const [counterInTitleField, setCounterInTitleField] = useState(MAX_TITLE_FIELD_SIZE)
  const [selectedPriority, setSelectedPriority] = useState<RequestsPriority>(RequestsPriority.MEDIUM)
  const [selectedType, setSelectedType] = useState<RequestsType>(RequestsType.OTHER)
  const handlePriorityButtonsClick =  (priorityType: RequestsPriority) => {
    if (priorityType === selectedPriority){
      return
    }
    setSelectedPriority(priorityType)
  }
  const handleTypeButtonsClick =  (type: RequestsType) => {
    if (type === selectedType){
      return
    }
    setSelectedType(type)
  }
    const [
    createTaskMutation,
    {
      error: createTaskError,
      isError: createTaskIsError,
      isLoading: createTaskIsLoading
    }
  ] = useCreateTaskMutation()


  const cpesQuery = useGetCpesQuery({assignedFilter: AssignedFilter.all}, {refetchOnMountOrArgChange: refetchOnMountOrArgChangeLocationsAndCpesData})
  const locationsQuery = useGetLocationsWithCountersQuery(
        {},
        {refetchOnMountOrArgChange: refetchOnMountOrArgChangeLocationsAndCpesData},
    )
      const {
        isFetching: locationsAndCpesIsFetching,
    } = getCombinedParamsFromRtkqHook([
        cpesQuery,
        locationsQuery
    ])
  const locationsWithIdKeys = useMemo(() => {
    return extractIdMakeRecordset(locationsQuery.data?.data || [])
  }, [locationsQuery.data])

  const cpesList = useMemo(() => {
    return cpesQuery.data?.data || []
  }, [cpesQuery.data])

  const handleTitleFieldInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value)
    const inputedLength = e.target.value.length
    if (inputedLength !== undefined) {
      if (MAX_TITLE_FIELD_SIZE - inputedLength >= 0) {
        setCounterInTitleField(MAX_TITLE_FIELD_SIZE - inputedLength)
      } else {
        setCounterInTitleField(0)
      }
    } else {
      setCounterInTitleField(MAX_TITLE_FIELD_SIZE)
    }

  }

  const onSubmit = async (values: FormData) => {
    console.log('form', values)
    const valuesForRequest = {
      priority: selectedPriority,
      requestType: selectedType,
      ...values,
    }
    console.log(valuesForRequest)
    console.log('Create task.onSubmit', values)
    const returnedFromAPIVal =  await createTaskMutation(valuesForRequest)
    console.log('Create task.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      console.log('Create task.onSubmit.error')
    } else {
      console.log('Create task.onSubmit.success')
      if (refetchRequestsData) {
        refetchRequestsData()
      }
      if (modalClose) {
        modalClose()
      }
      enqueueSnackbar('Заявка успешно создана', {variant: 'success', autoHideDuration: 5000});
    }
  }
  return <Form<FormData>
    onSubmit={onSubmit}
    initialValues={
      cpeId ? {assignedToCpeId: cpeId} as Partial<FormData> : undefined
    }
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      return <form
        onSubmit={props.handleSubmit}
        noValidate
      >
        <div>
          <Stack spacing={3} sx={{width: 1, mt: 0}}>
            <Box>
              <Typography variant={'body2'} sx={{color: theme.palette.text.disabled, mb:2}}>Новая заявка</Typography>
              {(createTaskIsError) && <Alert sx={{mt: 1}} severity="error">{getErrorMsgByErrorForCreateTask(createTaskError)}</Alert>}
              <Typography variant={'body2'} sx={{mb: 0.5}}>Приоритет</Typography>
              <Stack direction={'row'} width={'100%'} flexWrap={'wrap'}>
                {requestsPrioritys.map((priority) => {
                  return (
                    <PriorityChip key={priority} sx={{mr: 1}} priority={priority} clickCallback={handlePriorityButtonsClick}
                                  selected={selectedPriority === priority}/>
                  )
                })}
              </Stack>
            </Box>

            <TextField
              onInput={handleTitleFieldInput}
              multiline
              minRows={2}
              fullWidth
              label='В чем проблема'
              type='text'
              name={FieldName.title}
              required={required.title}
              size='small'
              showError={showErrorOnBlur}
              disabled={createTaskIsLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant={'body2'}>{counterInTitleField}</Typography>
                  </InputAdornment>
                )
              }}
            />
            <Box>
              <Typography variant={'body2'} sx={{mb: 0.5}}>Тип заявки</Typography>
              <Stack direction={'row'} width={'100%'} flexWrap={'wrap'}>
                {requestsTypes.map(type => {
                  return (
                    <TypeChip key={type} sx={{mr: 1, mb: 0.5}} selected={selectedType === type}
                              clickCallback={handleTypeButtonsClick} type={type}/>
                  )
                })}
              </Stack>
            </Box>
            <Box>
            {locationsAndCpesIsFetching ?
              <Typography sx={{mt:0}} variant={'h2'}><Skeleton/></Typography>
              :
              <TextField
                fullWidth
                multiline
                minRows={2}
                select
                type='number'
                label='Устройство'
                name={FieldName.assignedToCpeId}
                // required={required.cpe}
                size='small'
                showError={showErrorOnBlur}
                disabled={createTaskIsLoading}
              >
                { cpesList.length ?
                  cpesList.map((cpe: ICpeWithCounters) => {
                    // console.log(cpe)
                    return (
                      <MenuItem sx={{width: '100%', overflow: 'hidden'}} key={cpe.id} value={cpe.id}>
                        <Stack direction={'column'} justifyContent={'left'}>
                          <Box>
                            <Stack direction={'row'} justifyContent={'left'} alignItems={'center'}>
                              <Typography sx={{mr: 1}}>{defaultIfUndef(cpe?.name, 'Нет имени')}</Typography>
                              <Typography sx={{
                                fontSize: '0.8em',
                                color: theme.palette.text.disabled,
                              }}>S/N: {formatCpeSerial(cpe.serial)}</Typography>
                            </Stack>
                          </Box>
                          <Box>
                            <Typography sx={{
                              whiteSpace: 'normal',
                              fontSize: '0.8em',
                              color: theme.palette.text.disabled
                            }}>{cpe.locationId ? locationsWithIdKeys[cpe.locationId]?.address : 'адрес не указан'}</Typography>
                          </Box>
                        </Stack>
                      </MenuItem>
                    )
                  })
                  :
                  <MenuItem disabled={true} sx={{width: '100%', overflow: 'hidden'}} value={undefined}>Устройств не найдено</MenuItem>
                }
              </TextField>
            }
            </Box>

            <Typography sx={{mb: 0.5}} variant={'body2'} >Комментарий</Typography>
            <TextField
              multiline
              minRows={4}
              fullWidth
              label='Расскажите подробнее'
              type='text'
              name={FieldName.text}
              required={required.text}
              size='small'
              showError={showErrorOnBlur}
              disabled={createTaskIsLoading}
            />
          </Stack>

        </div>
        <LoadingButton
          sx={{textTransform: 'none', mt: 2}}
          fullWidth
          size="small"
          type="submit"
          variant="contained"
          loading={createTaskIsLoading}
          disabled={
            !canStepNextInWizard(props)
          }
        >
          Создать заявку
        </LoadingButton>
      </form>
    }}
  />

}
export default CreateNewRequestForm
