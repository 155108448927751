import {Box, Breadcrumbs, Container, FormControl, MenuItem, Select, SelectChangeEvent, Typography, TableSortLabel} from '@mui/material';
import {orderBy, sortBy} from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {useSearchParams} from "react-router-dom";

import useSettings from 'src/external/minimal/hooks/useSettings';
import {useGetTasksQuery} from "src/api/Task/Task";
import {ITaskInApi, TaskStatusEnum} from "src/api/Task/types";
import Page from 'src/components/Page';
import {isDefined} from "src/utils/object";
import {IHttpResponseBase} from "src/api/types";
import {WithClientRaw} from "src/api/Client/types";

import {RequestsViewType, SortDirection, SortType, RequestsPriority} from './types'
import {getFieldNameCorrespondingWithSortType} from './utils'
import RequestsTableView from "./RequestsTableView/RequestsTableView";
import RequestsBoardView from "./RequestsBoardView/RequestsBoardView";
import CreateNewRequestModal from "./CreateNewRequestModal";
import DisplayRequestModal from "./DisplayRequestModal"

const Separator = (
  <Box
    component="span"
    sx={{
      width: 4,
      height: 4,
      borderRadius: '50%',
      bgcolor: 'text.disabled'
    }}
  />
);

export const sortTasks = (
    tasks: ITaskInApi[],
    data: IHttpResponseBase<WithClientRaw<ITaskInApi>[]> | undefined,
    sortDirection: SortDirection,
    selectedSortType: SortType,
) => {
  const arrayForSort = isDefined(tasks)
      ? [...tasks]
      : []
  let sortedAndOrderedTasks: ITaskInApi[]
  let first: number, second: number
  if (sortDirection === SortDirection.DESC) {
    first = -1;
    second = 1;
  } else {
    first = 1;
    second = -1;
  }
  const fieldForSort = getFieldNameCorrespondingWithSortType(selectedSortType)
  if (selectedSortType === SortType.BY_STATUS) {
    sortedAndOrderedTasks = arrayForSort.sort((a, b) => {
      if (a[fieldForSort] === b[fieldForSort]) {
        return 0
      }
      if (a[fieldForSort] === TaskStatusEnum.CREATED && b[fieldForSort] !== TaskStatusEnum.CREATED) {
        return first
      }
      if (a[fieldForSort] === TaskStatusEnum.IN_WORK && b[fieldForSort] === TaskStatusEnum.CLOSED) {
        return first
      }
      return second
    })
  } else if (selectedSortType === SortType.BY_PRIORITY) {
    sortedAndOrderedTasks = arrayForSort.sort((a, b) => {
      if (a[fieldForSort] === b[fieldForSort]) {
        return 0
      }
      if (a[fieldForSort] === RequestsPriority.LOW && b[fieldForSort] !== RequestsPriority.LOW) {
        return first
      }
      if (a[fieldForSort] === RequestsPriority.MEDIUM && b[fieldForSort] === RequestsPriority.HIGH) {
        return first
      }
      return second
    })
  } else {
    sortedAndOrderedTasks = sortBy(arrayForSort, fieldForSort)
    sortedAndOrderedTasks = orderBy(sortedAndOrderedTasks, fieldForSort, sortDirection)
  }

  // console.log(sortedTasks)
  return sortedAndOrderedTasks
}

export const formatDateTask = (label: number | string) => {
    const date = new Date(label);
    // console.log(date)
    return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    }).format(date)
}

const Requests: React.FC = () => {
  const theme = useTheme()
  const {themeStretch} = useSettings();
  const [selectedViewType, setSelectedViewType] = useState<RequestsViewType>(RequestsViewType.BOARD)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDisplayModal, setShowDisplayModal] = useState(false)
  const [selectedSortType, setSelectedSortType] = useState<SortType>(SortType.BY_ID)
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.DESC)
  const [showingTask, setShowingTask] = useState<ITaskInApi | undefined>(undefined)

  const handleViewTypesClick = (viewType: RequestsViewType) => {
    setSelectedViewType(viewType)
    refetch()
  }
  const handleCreateClick = useCallback(()=>{
    setShowCreateModal(true)
  }, [setShowCreateModal])
  const handleModalClose = ()=>{
    setShowCreateModal(false)
  }
  const handleDisplayModalClose = ()=>{
    setShowDisplayModal(false)
    setShowingTask(undefined)
  }

  const {
        data,
        error,
        isFetching,
        isError,
        refetch,
    } = useGetTasksQuery(
        undefined,
        {refetchOnMountOrArgChange: true},
    )

  const sortedTasks = useMemo(() => {
    const tasks: ITaskInApi[] = data?.data || []
    return sortTasks(tasks, data, sortDirection, selectedSortType)
  }, [data, selectedSortType, sortDirection])

  const handleShowRequestClick = useCallback(
      (taskId: number)=> {
      // console.log(taskId)

      const taskForShow = sortedTasks.find((task)=>{
        return task.id ===  taskId
      })
      setShowingTask(taskForShow)
      setShowDisplayModal(true)
    },
    [sortedTasks, setShowingTask, setShowDisplayModal]
  )

  const toggleSortDirection = () => {
    if (sortDirection === SortDirection.ASC){
      setSortDirection(SortDirection.DESC)
      return
    }
    setSortDirection(SortDirection.ASC)
  }

  const handleSelectSortType = (event: SelectChangeEvent<unknown>)=>{
    setSelectedSortType(event.target.value as SortType)
  }

  const [searchParams] = useSearchParams();
  const taskId = searchParams.get('id')
  const isNewTask = searchParams.get('isNewTask')

  useMemo(() => {
    if(isDefined(sortedTasks)){
      //TODO: to useCallback
      handleShowRequestClick(Number(taskId))
    } else if(isDefined(sortedTasks)){
      handleCreateClick()
    }
  }, [taskId, isNewTask, sortedTasks, handleShowRequestClick, handleCreateClick])

  return (
    <Page title="Заявки">
      <CreateNewRequestModal refetchOnMountOrArgChangeLocationsAndCpesData={true} refetchRequestsData={refetch} modalClose={handleModalClose} showingModal={showCreateModal}/>
      <DisplayRequestModal task={showingTask} refetchRequestsData={refetch} modalClose={handleDisplayModalClose} showingModal={showDisplayModal}/>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box>
          <Box sx={{mb: 5}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Box sx={{flexGrow: 1}}>
                <Typography variant="h4" gutterBottom>
                  Заявки
                </Typography>
              </Box>
            </Box>
            <Box sx={{mb: 2}}>
              <Breadcrumbs separator={Separator} sx={{cursor: 'pointer'}}>
                <Typography sx={{textDecoration: selectedViewType === RequestsViewType.BOARD ? 'underline' : ''}}
                            role={"button"} variant="body2" onClick={() => {
                  handleViewTypesClick(RequestsViewType.BOARD)
                }}>Дашборд</Typography>
                <Typography sx={{textDecoration: selectedViewType === RequestsViewType.TABLE ? 'underline' : ''}}
                            variant="body2" onClick={() => {
                  handleViewTypesClick(RequestsViewType.TABLE)
                }}>Таблица</Typography>
                <Typography sx={{textDecoration: selectedViewType === RequestsViewType.TABLE ? 'underline' : ''}}
                            variant="body2" onClick={() => {
                  handleCreateClick()
                }}>+</Typography>
                <FormControl variant="standard" size={"medium"}
                             sx={{minWidth: 130, display: 'flex', flexDirection: 'row'}}>
                  {/*<InputLabel id="sort-type-selector-label">Сортировка</InputLabel>*/}
                  <Select
                    sx={{fontSize: theme.typography.body2.fontSize, fontWeight: theme.typography.body2.fontWeight}}
                    size={'small'}
                    labelId="sort-type-selector-label"
                    id="sort-type-selector"
                    value={selectedSortType}
                    label="Сортировка"
                    onChange={handleSelectSortType}
                  >
                    <MenuItem value={SortType.BY_STATUS}>По cтатусу</MenuItem>
                    <MenuItem value={SortType.BY_PRIORITY}>По приоритету</MenuItem>
                    <MenuItem value={SortType.BY_ID}>По номеру</MenuItem>
                    <MenuItem value={SortType.BY_TITLE}>По названию</MenuItem>
                    <MenuItem value={SortType.BY_CREATED_AT}>По дате создания</MenuItem>
                    <MenuItem value={SortType.BY_UPDATED_AT}>По дате изменения</MenuItem>
                  </Select>
                  <TableSortLabel onClick={toggleSortDirection} direction={sortDirection} active={true}/>
                </FormControl>
              </Breadcrumbs>
            </Box>
          </Box>
        </Box>
        <Box>
          {selectedViewType === RequestsViewType.TABLE &&
            <RequestsTableView tasks={sortedTasks} isError={isError} error={error} isLoadingData={isFetching}
                               showDisplayRequestModal={handleShowRequestClick}/>}
          {selectedViewType === RequestsViewType.BOARD &&
            <RequestsBoardView tasks={sortedTasks} isError={isError} error={error} isLoadingData={isFetching}
                               showAddNewModal={handleCreateClick} showDisplayRequestModal={handleShowRequestClick}
                               refetchRequests={refetch}/>}
        </Box>

      </Container>
    </Page>
  );
}
export default Requests