// import {createStyles, Theme} from '@mui/material';
import {Theme} from '@mui/material';
import {createStyles} from '@mui/styles';

import {MAP_MARKER_ICON_SIZE_PX} from "./consts";
import {getThemeTypeAwareColor} from "../../../utils/style";

const openStreetMapStyle = (theme: Theme) => createStyles({
    successText: {
        color: getThemeTypeAwareColor(theme, theme.palette.success),
    },
    errorText: {
        color: getThemeTypeAwareColor(theme, theme.palette.error),
    },
    warningText: {
        color: getThemeTypeAwareColor(theme, theme.palette.warning),
    },
    infoText: {
        color: getThemeTypeAwareColor(theme, theme.palette.info),
    },
    openStreetMapContainer: {
        height: '100%',
        minHeight: '100%',
    },
    openStreetMapCard: {
        height:'100%',
    },
    openStreetMapPlaceholder: {
        height:'100%',
        overflow: 'hidden',
        minHeight: '100%',
    },
    popupCondition: {
        display: 'flex',
        alignItems: 'center',
    },
    popupWrapper: {
        '& .leaflet-popup-content-wrapper': {
            color: `${theme.palette.text.primary}`,
            backgroundColor: `${theme.palette.background.paper}`
        }
    },
    popupCircleGreen: {
        height: '16px',
        width: '16px',
        color: theme.palette.success.main
    },
    popupCircleRed: {
        height: '16px',
        width: '16px',
        color: theme.palette.error.main,
    },
    popupCircleYellow: {
        height: '16px',
        width: '16px',
        color: theme.palette.warning.main,
    },
    popupCircleBlue: {
        height: '16px',
        width: '16px',
        color: theme.palette.info.main,
    },
    tileLayer: {
        ...(theme.palette.mode === 'dark'
            ? {
                // OSM doesn't have Dark mode tiles now. Hack through CSS instead
                // Variant 1: https://github.com/openstreetmap/openstreetmap-website/issues/2332#issuecomment-727266980
                filter: 'brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7);',
                // Variant 2: https://github.com/openstreetmap/openstreetmap-website/issues/2332#issuecomment-867821340
                // filter: 'invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%)',
            }
            : {}
        ),
    },
    locationLink: {
        color: theme.palette.info.main,
        cursor: 'pointer',
    },
    locationOnIcon: {
        height: MAP_MARKER_ICON_SIZE_PX,
        width: MAP_MARKER_ICON_SIZE_PX,
        // Use maximum contrast, thus use primary text color
        stroke: theme.palette.text.primary,
        strokeWidth: 0.5,
    }
});

export default openStreetMapStyle;
