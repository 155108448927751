import React from "react";
import {Navigate, useSearchParams} from 'react-router-dom';
import {styled} from '@mui/material/styles';
import {Box, Stack, Container, Typography} from '@mui/material';
import Page from '../../../components/Page';
import NewPasswordForm from "./NewPasswordForm";
import {PATH_DASHBOARD} from "../../../routes/path";


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));


// ----------------------------------------------------------------------

const NewPassword: React.FC = () => {
  const [searchParams] = useSearchParams();
  // пытаемся найти  uuid в адресной строке, а если не находим, то редиректим на дашборд
  const uuid = searchParams.get('uuid')
  if (!uuid) {
    return <Navigate to={PATH_DASHBOARD.general.dashboard}/>;
  }
  return (
    <RootStyle title="Изменение пароля">
      <Container maxWidth="sm">
        <Stack direction="row" alignItems="center" sx={{mb: 2}}>
          <Box sx={{flexGrow: 1}}>
            <Typography variant="h4" gutterBottom>
              Изменение пароля
            </Typography>
            {/*<Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>*/}
          </Box>
        </Stack>
        <NewPasswordForm uuid={uuid}/>
      </Container>
    </RootStyle>
  );
}
export default NewPassword
