import React, { useMemo, useState, useEffect} from "react";
import {DragDropContext, DragStart, DragUpdate, DropResult} from 'react-beautiful-dnd';
import {Stack, Divider, Box, Card, Typography} from '@mui/material';
import {useSnackbar} from "notistack";
import RequestsBoardViewColumn from "./RequestsBoardViewColumn";
import {ITaskInApi, TaskStatusEnum} from "../../../../api/Task/types";
import {useChangeTaskStatusMutation} from "src/api/Task/Task";
import {getErrorMsgByErrorForChangeTaskStatus, checkIsNewStatusIsAvailabily, getNotAvailablyChangeTaskStatusMessage } from "../utils";




interface  IRequestsBoardViewProps {
  tasks: ITaskInApi[],
  isError?: boolean,
  error?: any,
  isLoadingData?: boolean,
  showAddNewModal: Function,
  refetchRequests: Function,
  showDisplayRequestModal? : Function
}



const RequestsBoardView: React.FC<IRequestsBoardViewProps> = ({tasks, isError, error, isLoadingData,showAddNewModal, refetchRequests, showDisplayRequestModal}) => {

  const [
        changeTaskStatusMutation,
        {
            isLoading: isLoadingChangeTaskStatus,
        }
    ] = useChangeTaskStatusMutation()
  const { enqueueSnackbar } = useSnackbar();
  const [tasksForShowingOnBoard, setTasksForShowingOnBoard] = useState(tasks)
  const isColumnDropDisabledStatusesDefaultValues = {
    [TaskStatusEnum.CREATED]: false,
    [TaskStatusEnum.IN_WORK]: false,
    [TaskStatusEnum.CLOSED]: false,
  }
  const [isColumnDropDisabledStatuses, setIsColumnDropDisabledStatuses] = useState(isColumnDropDisabledStatusesDefaultValues)
  const resetIsColumnDropDisabledStatuses = () => {
    setIsColumnDropDisabledStatuses(isColumnDropDisabledStatusesDefaultValues)
  }
  const [timerIdForNotAvailablyChangeTaskStatusMessage, setTimerIdForNotAvailablyChangeTaskStatusMessage] = useState<Number | undefined>(undefined)
  const clearTimeoutForTimerIdForNotAvailablyChangeTaskStatusMessage = () => {
    if (timerIdForNotAvailablyChangeTaskStatusMessage !== undefined) {
      window.clearTimeout(timerIdForNotAvailablyChangeTaskStatusMessage as number)
      setTimerIdForNotAvailablyChangeTaskStatusMessage(undefined)
    }
  }
  useEffect(() => {
    return  () => {
      clearTimeoutForTimerIdForNotAvailablyChangeTaskStatusMessage()
    }
  })
  useEffect(()=>{setTasksForShowingOnBoard(tasks)}, [tasks])
  const groupedForColumnsTasks = useMemo(() => {
    const created =  tasksForShowingOnBoard.filter((task) => {
      return task.status === TaskStatusEnum.CREATED
    })
    const inWork = tasksForShowingOnBoard.filter((task) => {
      return task.status === TaskStatusEnum.IN_WORK
    })
    const closed = tasksForShowingOnBoard.filter((task) => {
      return task.status === TaskStatusEnum.CLOSED
    })
    return{
      created,
      inWork,
      closed
    }

  }, [tasksForShowingOnBoard])
  const onDragStart = (result: DragStart) => {
    resetIsColumnDropDisabledStatuses()
  }
  const onDragUpdate = (result: DragUpdate) => {
    // console.log(timerIdForNotAvailablyChangeTaskStatusMessage)
    if (result.destination && result.source && result.destination && result.destination.droppableId === result.source.droppableId) {
      // не даем перемещать в пределах одной колонки
      setIsColumnDropDisabledStatuses({...isColumnDropDisabledStatusesDefaultValues, [result.destination.droppableId as TaskStatusEnum]:true })
      return
    }
    // Тут проверяем можно ли поменять статус заявки со старого на новый и если нелья - блокируем колонку, в которую
    // перетаскивается заявка, чтобы она не могла туда перетащится и показываем информационное сообщение что нельзя
    // так перетаскивать
    if (result.destination && result.source && result.destination && result.destination.droppableId !== result.source.droppableId) {
      clearTimeoutForTimerIdForNotAvailablyChangeTaskStatusMessage()
      // console.log('onDragUpdate', result)
      const taskNewStatus = result.destination?.droppableId as TaskStatusEnum
      const taskCurrentStatus = result.source?.droppableId as TaskStatusEnum
      if (!checkIsNewStatusIsAvailabily(taskCurrentStatus, taskNewStatus) && !isColumnDropDisabledStatuses[taskNewStatus]) {
        // console.log({...isColumnDropDisabledStatuses, [taskNewStatus]:true })
        // блочим соответсвующию колонку, чтобы туда нельзя было дропнуть задачу в неё
        setIsColumnDropDisabledStatuses({...isColumnDropDisabledStatusesDefaultValues, [taskNewStatus]:true })
        // откладываем показ сообщения, чтобы не выводить сразу несколько,
        // когда польователь пытаеся перетянуть из последнй колонки в первую,
        // протасивая над другими колонками
        const timer = window.setTimeout(()=>{
          enqueueSnackbar(getNotAvailablyChangeTaskStatusMessage(taskCurrentStatus, taskNewStatus), {variant: 'info', autoHideDuration: 2000});
        }, 500)
        // console.log('set timeout')
        setTimerIdForNotAvailablyChangeTaskStatusMessage(timer)
      }
    }
  }
  const onDragEnd = async (result: DropResult) => {
    if (result.destination && result.source && result.destination && result.destination.droppableId !== result.source.droppableId) {
      const draggedTask = tasksForShowingOnBoard.find((task) => {
        return String(task.id) === result.draggableId;
      })
      if (draggedTask && result.destination?.droppableId) {
        setTasksForShowingOnBoard([...tasksForShowingOnBoard].map(object => {
          if (object.id === draggedTask.id && result.destination) {
            return {
              ...object,
              status: result.destination.droppableId,
            }
          } else return object;
        }))
        const taskStatus = result.destination?.droppableId as TaskStatusEnum
        const returnedFromAPIVal = await changeTaskStatusMutation({taskId: draggedTask?.id, newStatus: taskStatus})
        console.log('Change task status.obSubmit.result', returnedFromAPIVal)
        if ('error' in returnedFromAPIVal) {
          console.log('Change task status.onSubmit.error')
          try {
            // @ts-ignore
            enqueueSnackbar(getErrorMsgByErrorForChangeTaskStatus(returnedFromAPIVal.error.data.errors[0]), {
              variant: 'warning',
              autoHideDuration: 5000
            });
          } catch (e) {
            enqueueSnackbar('Ошибка при обновлении статуса заявки', {variant: 'error', autoHideDuration: 5000});
          }
          // enqueueSnackbar('Ошибка при обновлении статуса задачи', {variant: 'error', autoHideDuration: 5000});
        } else {
          console.log('Change task status.onSubmit.success')
        }
        await refetchRequests()
      }
    }
  }

  return (
    <>
      {isError ?
        <Box>
          <Card sx={{minHeight: '100px'}}>
            <Stack direction={'row'} alignItems={'center'} sx={{p: 3}}>
              <Typography variant="body1">Произошла ошибка загрузки данных
                заявок: {JSON.stringify(error)}</Typography>
            </Stack>
          </Card>
        </Box>
        :
        <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate}  onDragEnd={onDragEnd}>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent={'space-between'}
            spacing={3}
            sx={{height: 'calc(100% - 32px)', overflowY: 'hidden'}}
          >
            <RequestsBoardViewColumn isDropDisabled={isColumnDropDisabledStatuses[TaskStatusEnum.CREATED]}
                                     isLoadingData={isLoadingData || isLoadingChangeTaskStatus}
                                     type={TaskStatusEnum.CREATED}
                                     key={TaskStatusEnum.CREATED}
                                     tasks={groupedForColumnsTasks.created} showAddNewModal={showAddNewModal}
                                     showDisplayRequestModal={showDisplayRequestModal}/>
            <Divider orientation="vertical" flexItem/>
            <RequestsBoardViewColumn isDropDisabled={isColumnDropDisabledStatuses[TaskStatusEnum.IN_WORK]}
                                     isLoadingData={isLoadingData || isLoadingChangeTaskStatus}
                                     type={TaskStatusEnum.IN_WORK}
                                     key={TaskStatusEnum.IN_WORK}
                                     tasks={groupedForColumnsTasks.inWork}
                                     showDisplayRequestModal={showDisplayRequestModal}/>
            <Divider orientation="vertical" flexItem/>
            <RequestsBoardViewColumn isDropDisabled={isColumnDropDisabledStatuses[TaskStatusEnum.CLOSED]}
                                     isLoadingData={isLoadingData || isLoadingChangeTaskStatus}
                                     type={TaskStatusEnum.CLOSED}
                                     key={TaskStatusEnum.CLOSED}
                                     tasks={groupedForColumnsTasks.closed}
                                     showDisplayRequestModal={showDisplayRequestModal}/>

          </Stack>

        </DragDropContext>}
    </>
  );
}
export default RequestsBoardView