import React from "react";
import {Box, Drawer} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";




interface IStyledDrawerProps {
  showingModal: boolean,
  modalClose: Function,
}

const StyledDrawer: React.FC<IStyledDrawerProps> = ({showingModal, modalClose, children}) => {
  return (
    <Drawer
      PaperProps={{ sx: { width: { xs: 1, sm: 480 } } }}
      anchor={'right'}
      open={showingModal}
      onClose={() => {
        modalClose()
      }}
    >
      <Box sx={{px: 3, pt: 2, pb: 2, overflow: 'auto'}}>
          <IconButton sx={{p: 0, position:'fixed', right: '0.5em', top:'0.5em'}} onClick={() => {
                modalClose()
              }}>
                <CloseIcon/>
              </IconButton>
          {children}
      </Box>
    </Drawer>
  )
}


export default StyledDrawer