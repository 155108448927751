import React, {useMemo} from "react";
import {Box, Card, CardActionArea, Grid, Stack, Typography, Button, CardProps, Skeleton} from "@mui/material";
import {Link} from "react-router-dom";
import { styled } from '@mui/material/styles';

import {useGetTasksQuery} from "src/api/Task/Task";
import {ITaskInApi, TaskStatusEnum} from "src/api/Task/types";
import {isDefined} from "src/utils/object";
import {PATH_DASHBOARD} from "src/routes/path";

import {sortTasks} from "../Requests/Requests";
import {SortDirection, SortType} from "../Requests/types";
import StyledCardForBoard from "../Requests/RequestsBoardView/StyledCardForBoard";
import RequestCard from "../Requests/RequestCard";
import RequestsBoardViewCardSkeleton from "../Requests/RequestsBoardView/RequestsBoardViewCardSkeleton";
import {IClientForCurrentUser} from "../../../api/Client/types";
import {FetchBaseQueryError} from "@reduxjs/toolkit/dist/query/react";
import {SerializedError} from "@reduxjs/toolkit";

const RowStyle = styled('div')({
    display: 'flex',
    justifyContent: 'space-between'
});

interface IRequestsCard extends CardProps {
    clientData: IClientForCurrentUser | undefined,
    clientIsLoading: boolean;
    clientIsError: boolean;
    clientError: FetchBaseQueryError | SerializedError | undefined,
}

const RequestsCard: React.FC<IRequestsCard> = ({
    clientData,
    clientIsLoading,
    clientIsError,
    clientError
}) => {

    console.log(clientData)

    const {
        data,
        error,
        isFetching,
        isError,
    } = useGetTasksQuery(
        undefined,
        {refetchOnMountOrArgChange: true},
    )

    const sortedTasks = useMemo(() => {
        const tasks: ITaskInApi[] = data?.data || []
        return sortTasks(tasks, data, SortDirection.ASC, SortType.BY_PRIORITY)
            .filter((task) => task.status !== TaskStatusEnum.CLOSED)
    }, [data])

    let tasksForShow: ITaskInApi[] = []
    if(isDefined(sortedTasks)){
        if(sortedTasks.length < 4){
            tasksForShow = [...sortedTasks]
        } else {
            tasksForShow = sortedTasks.slice(0, 3)
        }
    }

    return(
        <Grid container>
            {
                isError &&
                //@ts-ignore
                <div>Ошибка сервера {error?.status}</div>
            }
            <Grid item xs={12}>
                <Typography gutterBottom variant="h4" sx={{ pb: { xs: 2, xl: 2 }, maxWidth: 480}}>
                    Заявки
                </Typography>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item container xs={12} md={7} spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Link
                            to={{
                                pathname: PATH_DASHBOARD.general.requests,
                                search: `?isNewTask=${true}`,
                            }}
                            style={{ textDecoration: 'none' }}
                        >
                            <Box sx={{width: '100%', minWidth: '200px'}}>
                                <Card>
                                    <CardActionArea
                                        sx={{ minHeight:'100px'}}
                                    >
                                        <Stack
                                            height={'100%'}
                                            direction={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            textAlign={'center'}
                                        >
                                            <Typography height={'100%'} variant={'h3'}>
                                                +
                                            </Typography>
                                        </Stack>
                                    </CardActionArea>
                                </Card>
                            </Box>
                        </Link>
                    </Grid>
                    {
                        isFetching
                            ? Array.from(Array(3).keys()).map((count, index) => {
                                return (
                                    <Grid item xs={12} md={6} key={index}>
                                        <RequestsBoardViewCardSkeleton key={count}/>
                                    </Grid>
                                );
                            })
                            : tasksForShow.map((task, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                {/*//TODO: наверное по-другому сделать*/}
                                <Link
                                    to={{
                                        pathname: PATH_DASHBOARD.general.requests,
                                        search: `?id=${task.id}`,
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <StyledCardForBoard>
                                        <Box
                                            width={1}
                                            sx={{cursor: 'pointer'}}
                                            // sx={{ minHeight:'100px'}}
                                        >
                                            <RequestCard task={task} />
                                        </Box>
                                    </StyledCardForBoard>
                                </Link>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card sx={{ p: 3 }} style={{background:'#F4F6F8'}}>

                        <div>
                            {
                                clientIsLoading
                                    ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                    : <Typography variant="subtitle2" gutterBottom style={{fontWeight: 700}}>
                                        {`${clientData?.tariff?.title! ? clientData?.tariff?.title! : 'Тариф пока не выбран' }`}
                                    </Typography>
                            }
                        </div>

                        <Stack spacing={2}>
                            <div style={{display:"flex"}}>
                                <Typography variant="h3">
                                    Баланс&nbsp;
                                </Typography>
                                <Typography variant="h3">
                                    {
                                        clientIsLoading
                                            ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                            : <div style={{fontWeight: 700}}>
                                                {`${typeof(clientData?.balance!) === 'number' ? clientData?.balance! + ' ₽' : 'отсутствует' }`}
                                            </div>
                                    }
                                </Typography>
                            </div>

                            <RowStyle>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Номер договора:&nbsp;
                                </Typography>
                                <Typography variant="body2">
                                    {
                                        clientIsLoading
                                            ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                            : <div>
                                                00003/2220
                                            </div>
                                    }
                                </Typography>
                            </RowStyle>

                            <RowStyle>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Конец расчетного счета:&nbsp;
                                </Typography>
                                <Typography variant="body2">
                                    {
                                        clientIsLoading
                                            ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                            : <div>
                                                01.10.2022
                                            </div>
                                    }
                                </Typography>
                            </RowStyle>

                            <RowStyle>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Не хватает:&nbsp;
                                </Typography>
                                <Typography variant="body2">
                                    {
                                        clientIsLoading
                                            ? <Skeleton variant='text' width='80%' style={{minWidth:'150px'}}/>
                                            : <div style={{ color: 'red' }}>
                                                -4500₽
                                            </div>
                                    }
                                </Typography>
                            </RowStyle>

                            <Stack direction="row" spacing={1.5}>
                                <Link
                                    to={{ pathname: PATH_DASHBOARD.general.user.tariffs }}
                                    style={{ textDecoration: 'none', width: '100%' }}
                                >
                                    <Button
                                        fullWidth
                                        variant="contained"
                                    >
                                        Оплатить
                                    </Button>
                                </Link>
                            </Stack>
                        </Stack>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RequestsCard