export interface IUser {
    id: number
    email: string,
    username: string
}

export interface IUserRegistration {
    id: number
    email: string,
    verified: boolean
}

// export interface IUserApiResponse {
//     data: IUser,
// }

export interface IUserApiRegistrationResponse {
    data: IUserRegistration,
}

export interface ILoginProps {
    email: string
    password: string
}

export interface IUserRegistrationProps {
    email: string
    password: string,
    phone: string,
    name: string,
    referral_code?: string
}

export interface IOTPCodeProps {
    code: string
}

export interface IOTPCodeApiResponse {
    data: IOTPCodeApiResponseData,
}
export interface IOTPCodeApiResponseData {
    valid: boolean
    account: string
}

export interface IResetPasswordApiResponse {
    data: IResetPasswordApiResponseData,
}
export interface IResetPasswordApiResponseData {
    status: String
}

export interface IResetPasswordProps {
    email: string
    domain: string
}

export interface IConfirmPasswordApiResponse {
    data: IConfirmPasswordApiResponseData,
}
export interface IConfirmPasswordApiResponseData {
    status: String
}

export interface IConfirmPasswordProps {
    uuid: string
    password: string
}


export interface IChangePasswordForLoginedUserApiResponse {
    data: IChangePasswordForLoginedUserApiResponseData,
}

export interface IChangePasswordForLoginedUserApiResponseData {
    status: String
}

export interface IChangePasswordForLoginedUserProps {
    oldPassword: string
    newPassword: string
}


export enum NotificationTransportType {
    email = 'email',
}

export enum ClientNotificationEventTypeEnum {
    needPayment = 'needPayment',
    taskResponse = 'taskResponse',
    monthlyNewsletter = 'monthlyNewsletter'
}

export interface ISubscriptions {
    [ClientNotificationEventTypeEnum.needPayment]: NotificationTransportType[],
    [ClientNotificationEventTypeEnum.taskResponse]: NotificationTransportType[],
    [ClientNotificationEventTypeEnum.monthlyNewsletter]: NotificationTransportType[],
}