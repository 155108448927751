import {Stack, Box, Skeleton} from "@mui/material/";
import React, {useMemo} from "react";
import {QRCodeSVG} from 'qrcode.react';
import {useTheme} from "@mui/material/styles";
import {INetegisDetails} from 'src/api/NetegisDetails/types'


interface IQRWithDetailsForPaymentProps {
  details?: INetegisDetails,
  isLoadingDetailsData: boolean,
  isLoadingDetailsDataError: boolean,
}

const QRWithDetailsForPayment: React.FC<IQRWithDetailsForPaymentProps> = ({
                                                                            details,
                                                                            isLoadingDetailsData,
                                                                            isLoadingDetailsDataError
                                                                          }) => {
  const QRCodeWidth = 150
  const theme = useTheme()
  const stringForQRCode = useMemo(() => {
    // по мотивам статьи https://vc.ru/finance/254051-priem-platezhey-po-qr-kodam-bez-komissiy
    const name = details?.name
    const inn = details?.inn
    const kpp = details?.kpp
    const checkingAccount = details?.checkingAccount
    const correspondentAccount = details?.correspondentAccount
    const bik = details?.bik
    const bankName = details?.bankName
    if (name && inn && kpp && checkingAccount && correspondentAccount && bik && bankName) {
      return `ST00012|Name=${name}|PersonalAcc=${checkingAccount}|BankName=${bankName}|BIC=${bik}|CorrespAcc=${correspondentAccount}|PayeeINN=${inn}|KPP=${kpp}`
    }
    return undefined

  }, [details])
  return (
    <Box sx={{mt: 2}}>
      {isLoadingDetailsDataError ?
        <Box> Ошибка при загрузке данных о реквизитах для QR кода </Box>
        :
        isLoadingDetailsData ?
          <Stack sx={{width: QRCodeWidth}} direction={"column"}>
            <Skeleton variant="rectangular" width={QRCodeWidth} height={QRCodeWidth}/>
          </Stack>
          :
          !stringForQRCode ?
            <Box>Ошибка при генерации QR кода. Недостаточно данных</Box>
            :
            <Stack sx={{width: QRCodeWidth}} direction={"column"}>
              <QRCodeSVG size={QRCodeWidth} value={stringForQRCode} bgColor={theme.palette.background.default}
                         fgColor={theme.palette.text.primary} includeMargin={false} level={'Q'}/>
            </Stack>
      }
    </Box>

  )
}


export default QRWithDetailsForPayment