import React from "react";
import {styled} from '@mui/material/styles';
import {Box, Stack, Container, Typography} from '@mui/material';
import Page from '../../../components/Page';
import RegistrationForm from "./RegistrationForm";
import {useSearchParams} from "react-router-dom";
import { useParams } from 'react-router';


// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({theme}) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

// ----------------------------------------------------------------------

const Registration: React.FC = () => {
  const [searchParams] = useSearchParams();
  let referralCode = undefined
  // далее смотрим не передали ли реферальный код из короткого роута
  // /reg/тут_код
  const {referral_code: referralCodeFromRouter} = useParams()
  // далее смотрим наличие реферального кода в url в виде параметра
  // /auth/registration?referral_code=тут_код
  const referralCodeFromGetParamUrl = searchParams.get('referral_code')
  if (referralCodeFromGetParamUrl) {
    referralCode = referralCodeFromGetParamUrl;
  } else if (referralCodeFromRouter) {
    referralCode = referralCodeFromRouter
  }

  return (
    <RootStyle title="Регистрация">
      <Container maxWidth="sm">
        <Stack direction="row" alignItems="center" sx={{mb: 2}}>
          <Box sx={{flexGrow: 1}}>
            {
              referralCode ?
                <Typography variant="h4" gutterBottom>
                  Вы регистрируетесь через партнерский код
                </Typography>
                :
                <Typography variant="h4" gutterBottom>
                  Регистрация
                </Typography>
            }
            {/*<Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>*/}
          </Box>
        </Stack>
        <RegistrationForm referralCode={referralCode}/>
      </Container>

    </RootStyle>
  );
}
export default Registration
