import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import 'simplebar/src/simplebar.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import ThemeConfig from 'src/external/minimal/theme'
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { CollapseDrawerProvider } from './external/minimal/contexts/CollapseDrawerContext';
import NotistackProvider from './external/minimal/components/NotistackProvider';


ReactDOM.render(

      <HelmetProvider>
          <Provider store={store}>
              <ThemeConfig>
                <CollapseDrawerProvider>
                  <BrowserRouter>
                    <NotistackProvider>
                      <App/>
                      </NotistackProvider>
                  </BrowserRouter>
                </CollapseDrawerProvider>
              </ThemeConfig>
          </Provider>
      </HelmetProvider>
,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
