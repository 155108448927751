import React from "react";
import {Box, Chip} from '@mui/material';
import {RequestsType} from "../types";
import {ITypeChipProps} from './types'


const TypeChip: React.FC<ITypeChipProps> = ({type, caption, selected, clickCallback,sx}) => {
  const clickHandler = () => {
    if (clickCallback) {
      return clickCallback(type)
    }
  }
  const getCaption = (type: RequestsType) => {
    if (type === RequestsType.MAKE_BETTER) {
      return 'Сделать лучше'
    } else if (type === RequestsType.NETWORK_PROBLEMS) {
      return 'Проблемы с сетью'
    } else if (type === RequestsType.ADD_NEW_ADDRESS) {
      return 'Добавить новый адрес'
    } else if (type === RequestsType.ADD_NEW_CPE) {
      return 'Новое устройство'
    } else if (type === RequestsType.OTHER) {
      return 'Другое'
    }
  }
  return (
    <Box sx={{...sx}}>
      <Chip
        color={selected ? 'primary' : 'default'}
        size="small" label={caption ? caption : getCaption(type)}
        sx = {clickCallback ? { cursor: 'pointer'} : {cursor: 'inherit'}}
        onClick={clickCallback ? clickHandler : () => {
        }}/>
    </Box>
  );
}
export default TypeChip