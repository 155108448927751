import {Typography, Box, Skeleton, Paper} from "@mui/material/";
import React, {useMemo} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {INetegisDetails} from 'src/api/NetegisDetails/types'


interface ITableWithDetailsForPaymentProps {
  details?: INetegisDetails,
  isLoadingDetailsData: boolean,
  isLoadingDetailsDataError: boolean,
}

const TableWithDetailsForPayment: React.FC<ITableWithDetailsForPaymentProps> = ({
                                                                                  details,
                                                                                  isLoadingDetailsData,
                                                                                  isLoadingDetailsDataError
                                                                                }) => {
  const paymentsDetailsForTable = useMemo(() => {
    return [
      {caption: 'Наименование получателя', value: details?.name},
      {caption: 'Банк получателя', value: details?.bankName},
      {caption: 'ИНН', value: details?.inn},
      {caption: 'КПП', value: details?.kpp},
      {caption: 'Расч. счет', value: details?.checkingAccount},
      {caption: 'Корр. счет', value: details?.correspondentAccount},
      {caption: 'БИК', value: details?.bik},
    ]
  }, [details])
  return (
    <Box sx={{mt: 2}}>
      {isLoadingDetailsDataError ?
        <Box> Ошибка при загрузке данных о реквизитах </Box>
        :
        <TableContainer sx={{}} component={Paper}>
          <Table padding={'normal'} sx={{minWidth: 150, maxWidth: 1}} aria-label="simple table">
            <TableBody>
              {
                paymentsDetailsForTable.map((tableRowData, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell sx={{p: 0, pl: 0}} align="left">{tableRowData.caption}</TableCell>
                      {isLoadingDetailsData ?
                        <TableCell sx={{pt: 0, pb: 0, width: '150px'}} align="right"><Typography
                          variant={'body1'} sx={{width: '150px'}}><Skeleton/></Typography></TableCell>
                        :
                        <TableCell sx={{pt: 0, pb: 0}} align="right">{tableRowData.value}</TableCell>
                      }
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Box>

  )
}


export default TableWithDetailsForPayment