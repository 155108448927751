import {emptySplitApi} from "../base";
import {IClientTariffsApiResponse, IChangeTariffProps, IChangeTariffInApiResponse} from "./types";



export const clientTariffApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getTariffs: build.query<IClientTariffsApiResponse, void>({
            query: () => ({
                url: '/tariff',
            }),
        }),
        changeTariff: build.mutation<IChangeTariffInApiResponse, IChangeTariffProps>({
            query: ({tariffId}) => ({
                url: `/tariff`,
                method: 'PATCH',
                body: {
                    tariffId: tariffId,
                }
            }),
        }),
    }),
})

export const {
    useGetTariffsQuery,
    useChangeTariffMutation
} = clientTariffApi
