import {TCpeId, TCpeInterfaceId, TSimCardId, TWiredUplinkId} from "../../types/entityIds";
import {MobileRadioStatus} from "../ClientMobileUplink/types";

export interface IGetDataInterfaceProps {
    interfaceId: number,
    target: string
}

export type IDataInterfaceResponse = string

interface IBaseInterface {
    id: TCpeInterfaceId
    discoveredCpeId: TCpeId
    localName: string
    linkup: boolean
}

export interface IMobileInterface extends IBaseInterface {
    type: 'MOBILE'
    mobileRadioStatus: MobileRadioStatus
    imei: string
    discoveredSimCardId: TSimCardId | null
}

export interface IWiredInterface extends IBaseInterface {
    type: 'WIRED'
    macAddress: string
    discoveredWiredUplinkId: TWiredUplinkId | null
}

export enum MobileInterfaceError {
    low_rssi = 'low_rssi',
    not_registered = 'not_registered',
    no_ip = 'no_ip',
    no_traffic = 'no_traffic',
    low_traffic = 'low_traffic',
    sim_error = 'sim_error',
    sim_not_inserted = 'sim_not_inserted',
}

export interface IMobileInterfaceAggStatus {
    id: TCpeInterfaceId
    linkup: boolean
    mobileRadioStatus: MobileRadioStatus
    error: MobileInterfaceError | null
}

export interface IWiredInterfaceAggStatus {
    id: TCpeInterfaceId
    linkup: boolean
}
