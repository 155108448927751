import {emptySplitApi} from "../base";
import {IBalanceHistoryApiResponse, IGetBalanceHistoryProps} from "./types";



export const netegisDetailstApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getBalanceHistory: build.query<IBalanceHistoryApiResponse, IGetBalanceHistoryProps>({
            query: ({timeStampFromSec, timeStampToSec}) => ({
                url: '/balance_history',
                params: {
                    start: timeStampFromSec,
                    stop: timeStampToSec,
                }
            }),
        }),
    }),
})

export const {
    useGetBalanceHistoryQuery,
} = netegisDetailstApi
