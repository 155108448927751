import React from "react";
import {Box, Card, CardActionArea, Stack} from '@mui/material';
import {styled, useTheme} from "@mui/material/styles";
import {ITaskInApi, TaskStatusEnum} from "src/api/Task/types";
import {formatDateTask} from '../Requests';
import {isDefined} from "src/utils/object";
import PriorityChip from "../PriorityStatusesChips/PriorityChip";
import {getStatusForDisplay} from '../utils'
import StatusRectangles from './../StatusRectangles'

interface IRequestsTableViewRowProps {
  task: ITaskInApi,
  showDisplayRequestModal?: Function
}

const CardActionAreaRequestsTableStyled = styled(CardActionArea)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 1, 1.5, 1),
  fontSize: '0.8em'
}));

const RequestsTableViewRow: React.FC<IRequestsTableViewRowProps> = ({task, showDisplayRequestModal}) => {
  const theme = useTheme()

  const rowClick = () => {
    if (showDisplayRequestModal) {
      showDisplayRequestModal(task.id)
    }
  }


  return (
    <Card sx={{mb: 2}}>
      <CardActionAreaRequestsTableStyled onClick={rowClick}>
        <Stack direction={"row"} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>
          <Box sx={{
            color: theme.palette.text.secondary,
            minWidth: '10%',
            mr: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}>Заявка
            №{task.id}</Box>
          <Box sx={{minWidth: 'fit-content', mr: 1, textOverflow: 'ellipsis', overflow: 'hidden'}}>
            <PriorityChip priority={task.priority}/>
          </Box>
          <Box sx={{width: '50%', textOverflow: 'ellipsis', overflow: 'hidden'}}>{task.title}</Box>
          <Stack direction={'column'} justifyContent={'center'} sx={{minWidth: '10%', textOverflow: 'ellipsis', overflow: 'hidden'}}>
            <Box sx={{
              color: theme.palette.text.secondary,
              mr: 1,
              wordWrap: 'normal',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }}>
              <Stack direction={'column'} justifyContent={'center'} sx={{ textOverflow: 'ellipsis', overflow: 'hidden',  wordWrap: 'normal'}}>
                <Box sx={{textOverflow: 'ellipsis', overflow: 'hidden',  wordWrap: 'normal'}}>
                  <span>{getStatusForDisplay(task.status as TaskStatusEnum)}&nbsp;</span>
                  {task.status === TaskStatusEnum.CREATED ?
                    (isDefined(task.createdAt)
                      ? <span>{formatDateTask(task.createdAt)}</span>
                      : <span>Неопределено</span>)
                    :
                    (isDefined(task.updatedAt)
                      ? <span>{formatDateTask(task.updatedAt as string)}</span>
                      : <span>Неопределено</span>)
                  }
                  <StatusRectangles sx={{mt: '0.3em'}} status={task.status as TaskStatusEnum}/>
                </Box>

              </Stack>
            </Box>
          </Stack>

          <Box sx={{color: theme.palette.text.secondary, textAlign: 'right', wordWrap: 'normal'}}>
            {
              isDefined(task.createdAt)
                ? <span>{formatDateTask(task.createdAt)}</span>
                : <span>Неопределено</span>
            }
          </Box>
          <StatusRectangles status={task.status as TaskStatusEnum}/>
        </Stack>
      </CardActionAreaRequestsTableStyled>
    </Card>
  );
}
export default RequestsTableViewRow