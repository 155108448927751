import {isDefined} from "./object";

export const generateArrWithSize = (num: number | undefined): number[] => {
    if(typeof num === 'number'){
        if(num > 0){
            return Array.from(Array(num).keys())
        }
    }
    return []
}

export const defaultIfUndef = <T,Y>(value: T, defaultStr: Y): T | Y => {
    if(isDefined(value)){
        return value
    }
    return defaultStr
}

// const parseNumericValue = (value: any) => {
//     if (value == null) {
//         return null;
//     }
//
//     return Number(value);
// };
//
// export const getGridNumericOperators = (): GridFilterOperator[] => [
//     {
//         label: '=',
//         value: '=',
//         getApplyFilterFn: (filterItem: any) => {
//             if (filterItem.value?.text == null || Number.isNaN(filterItem.value?.text)) {
//                 return null;
//             }
//             return ({ value }): boolean => {
//                 //@ts-ignore
//                 return parseNumericValue(value) === filterItem.value?.text;
//             };
//         },
//         InputComponent: GridFilterInputValue,
//         InputComponentProps: { type: 'status' },
//     },
//     {
//         label: '!=',
//         value: '!=',
//         getApplyFilterFn: (filterItem: GridFilterItem) => {
//             if (filterItem.value?.text == null || Number.isNaN(filterItem.value?.text)) {
//                 return null;
//             }
//
//             return ({ value }): boolean => {
//                 return parseNumericValue(value) !== filterItem.value?.text;
//             };
//         },
//         InputComponent: GridFilterInputValue,
//         InputComponentProps: { type: 'status' },
//     },
//     {
//         label: '>',
//         value: '>',
//         getApplyFilterFn: (filterItem: GridFilterItem) => {
//             if (filterItem.value?.text == null || Number.isNaN(filterItem.value?.text)) {
//                 return null;
//             }
//
//             return ({ value }): boolean => {
//                 if (value == null) {
//                     return false;
//                 }
//
//                 return parseNumericValue(value)! > filterItem.value?.text;
//             };
//         },
//         InputComponent: GridFilterInputValue,
//         InputComponentProps: { type: 'status' },
//     },
//     {
//         label: '>=',
//         value: '>=',
//         getApplyFilterFn: (filterItem: GridFilterItem) => {
//             if (filterItem.value?.text == null || Number.isNaN(filterItem.value?.text)) {
//                 return null;
//             }
//
//             return ({ value }): boolean => {
//                 if (value == null) {
//                     return false;
//                 }
//
//                 return parseNumericValue(value)! >= filterItem.value?.text;
//             };
//         },
//         InputComponent: GridFilterInputValue,
//         InputComponentProps: { type: 'status' },
//     },
//     {
//         label: '<',
//         value: '<',
//         getApplyFilterFn: (filterItem: GridFilterItem) => {
//             if (filterItem.value?.text == null || Number.isNaN(filterItem.value?.text)) {
//                 return null;
//             }
//
//             return ({ value }): boolean => {
//                 if (value == null) {
//                     return false;
//                 }
//
//                 return parseNumericValue(value)! < filterItem.value?.text;
//             };
//         },
//         InputComponent: GridFilterInputValue,
//         InputComponentProps: { type: 'status' },
//     },
//     {
//         label: '<=',
//         value: '<=',
//         getApplyFilterFn: (filterItem: GridFilterItem) => {
//             if (filterItem.value?.text == null || Number.isNaN(filterItem.value?.text)) {
//                 return null;
//             }
//
//             return ({ value }): boolean => {
//                 if (value == null) {
//                     return false;
//                 }
//
//                 return parseNumericValue(value)! <= filterItem.value?.text;
//             };
//         },
//         InputComponent: GridFilterInputValue,
//         InputComponentProps: { type: 'status' },
//     },
//     {
//         value: 'isEmpty',
//         getApplyFilterFn: () => {
//             return ({ value }): boolean => {
//                 return value == null;
//             };
//         },
//     },
//     {
//         value: 'isNotEmpty',
//         getApplyFilterFn: () => {
//             return ({ value}): boolean => {
//                 return value != null;
//             };
//         },
//     },
// ];
