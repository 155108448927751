export enum FieldName {
    oldPassword = "old_password",
    newPassword = 'new_password',
    confirmPassword = 'confirm_password',
}

export interface FormData {
    [FieldName.oldPassword]: string,
    [FieldName.newPassword]: string
    [FieldName.confirmPassword]: string
}
