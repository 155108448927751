import React, {useEffect, useState} from 'react'
import {Autocomplete, Skeleton} from "@mui/material";
import {Grid} from "@mui/material";
import { withStyles } from '@mui/styles';
import {useForm, useFormState} from "react-final-form";
import {useDebounce} from "react-use";

import {TextField} from "mui-rff";
import {USER_INPUT_DEBOUNCE_MS} from "src/defs/ui";
import {useGetDaDataOrganizationsMutation} from "src/api/DaData/DaData";
import {Loader} from "src/components/MUI/Loader/Loader";
import {isDefined} from "src/utils/object";
import {IdaDataOrganizationsApiResponseProps} from "src/api/DaData/types"

import style from './styles'
import {OrganizationsProps, OrganizationAutoCompleteProps} from "./types";

const OrganizationAutoComplete: React.FC<OrganizationAutoCompleteProps> = ({
    name,
    required,
    classes,
    label,
    disabled
}) => {
    const [
        daDataRequest,
        {
            error,
            isError,
            isLoading,
            startedTimeStamp,
        }
    ] = useGetDaDataOrganizationsMutation()

    const [organizations, setOrganizations] = useState<OrganizationsProps[]>([])
    const [valueChanged, setValueChanged] = useState(false)

    const state = useFormState()
    const currentValue = state.values[name]

    // Используется для того, чтобы при именении инпута не показывать предыдущие значения
    useEffect(
        () => (
            setValueChanged(true)
        ),
        [currentValue],
    )

    const form = useForm()

    const request = (value:string) => {
        if (value !== '' && value !== undefined) {
            daDataRequest(value)
                .then((val) => {
                    if ('error' in val) {
                        console.log('daData.error')
                    } else {
                        console.log('daData.ok')
                        if (val.data.data.length > 0){
                            setOrganizations(val.data.data
                                .map((item: IdaDataOrganizationsApiResponseProps) => ({
                                    name: item.name,
                                    address: item.address,
                                    data: item,
                                }))
                            )
                            setValueChanged(false)
                        } else {
                            setOrganizations([{name: undefined, address:undefined, data:{}}])
                            setValueChanged(false)
                        }
                    }
                }
                )
        }
    }

   useDebounce(
        () => request(currentValue),
        USER_INPUT_DEBOUNCE_MS,
        [currentValue]
    )

    const setValuesOnFields = (value: OrganizationsProps ) => {
        form.batch(() => {
            form.change(name, value.data[name])
            form.change('inn', value.data.inn)
            form.change('kpp', value.data.kpp)
            form.change('ogrn', value.data.ogrn)
            form.change('address', value.data.address)
        })
    }

    return (
        <>
            {
                currentValue !== undefined
                || (state.modified || {})[name]
                    ? <Autocomplete
                        defaultValue={
                            {
                                name: state.values[name],
                                address: state.values.address,
                                data: {
                                    inn: state.values.inn,
                                    kpp: state.values.kpp,
                                    ogrn: state.values.ogrn,
                                    address: state.values.address,
                                },
                            }
                        }
                        options={
                            (isLoading
                                || !isDefined(currentValue)
                                || currentValue === ''
                                || valueChanged
                            )
                                ? [] as OrganizationsProps[]
                                : organizations
                        }
                        filterOptions={(options) => options}
                        autoComplete={false}
                        freeSolo={true}
                        onChange = {(_, value, reason) => {
                            return(
                                reason === 'selectOption'
                                && setValuesOnFields(value as OrganizationsProps)
                            )
                        }}
                        getOptionLabel={
                            (option) => typeof(option) === 'string'
                                ? option
                                : (option.name || 'неизвестно')
                        }
                        getOptionDisabled={(option) => option.name === undefined}
                        renderOption={(props,option) => {
                          // console.log('props', props)
                          // console.log('options', option)
                            if(option.name !== undefined) {
                              return (
                                // без этого {...props} не работает клие по элементу списка, видимо там передается
                                // функция onClick
                                // @ts-ignore
                                  <div {...props}>
                                    <Grid sx={{mb: 1}}  key={`${option?.name}${option.address}`} container direction='column' >
                                        <Grid  item className={classes.gridItemAddress}>
                                            {option.address}
                                        </Grid>
                                        <Grid item>
                                            {option?.name}
                                        </Grid>
                                    </Grid>
                                  </div>

                                )
                            } else {
                                return (
                                    <div>
                                        Ничего не найдено
                                    </div>
                                )
                            }
                        }}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    name={name}
                                    required={required}
                                    label={label}
                                    disabled={disabled === undefined ? false : disabled}
                                />
                                {
                                    isError
                                        // @ts-ignore Property does not exist on type
                                        ? <div>Произошла ошибка загрузки списка организаций {JSON.stringify(error)}</div>
                                        : <Loader
                                            isLoading={isDefined(currentValue) && isLoading}
                                            startedTimeStamp={startedTimeStamp}
                                            loaderType='determinate'
                                        />
                                }
                            </>
                        )}
                    />
                    : <Skeleton variant={'rectangular'} width='100%'/>
            }
        </>
    )
}

export default withStyles(style)(OrganizationAutoComplete)

