import {emptySplitApi} from "../base";
import {IChangeClientProps, IClient, IClientWithCounters, IDataClients, IClientForCurrentUser} from "./types";
import {IHttpResponseBase} from "../types";

const clientApi = emptySplitApi.injectEndpoints({
    endpoints: (build) => ({
        getClient: build.query<IHttpResponseBase<IClient>, void>({
            query: () => '/client',
        }),
        getClientsWithCounters: build.query<IHttpResponseBase<IClientWithCounters[]>, void>({
            query: () => ({
                url: '/client',
                params: {
                    withCounters: true,
                    withProblem: true,
                },
            }),
            // FIXME: types
            transformResponse: (resp: IHttpResponseBase<any>) => ({
                ...resp,
                data: resp.data.map((client: any) => ({
                    ...client,
                    // aggStatus: client.id === 116
                    //     ? {
                    //         locations: [
                    //             {
                    //                 id: 116,
                    //                 cpe:[
                    //                     {
                    //                         wiredUplinks: [
                    //                             {
                    //                                 id: 23,
                    //                                 interface: {
                    //                                     id: 21,
                    //                                     linkup: true,
                    //                                 },
                    //                                 assigned: true,
                    //                             }
                    //                         ],
                    //                         mobileUplinks: [
                    //                             {
                    //                                 id: 33,
                    //                                 interface: {
                    //                                     id: 2,
                    //                                     linkup: true,
                    //                                     radioStatus: MobileRadioStatus.connected,
                    //                                     error: null
                    //                                 },
                    //                                 assigned: true
                    //                             },
                    //                             {
                    //                                 id: 34,
                    //                                 interface: {
                    //                                     id: 22,
                    //                                     linkup: true,
                    //                                     radioStatus: MobileRadioStatus.connected,
                    //                                     error: null
                    //                                 },
                    //                                 assigned: true
                    //                             },
                    //                         ],
                    //                         managementLinkup: true,
                    //                         error: null,
                    //                     }
                    //                 ]
                    //             }
                    //         ]
                    //     }
                    //     : [],
                }))
            }),
        }),
        changeClient: build.mutation<IHttpResponseBase<IClient>, IChangeClientProps>({
            query: (values) => ({
                url: `/client`,
                method: 'PUT',
                body: values,
            })
        }),
        getDashboardDataClients: build.query<IHttpResponseBase<IDataClients>, void>({
            query: () => '/client/stats',
        }),
        getClientDataForCurrentUser: build.query<IHttpResponseBase<IClientForCurrentUser>, void>({
            query: (id) => `/client`,
        }),
    }),
})

export const {
    useGetClientQuery,
    useGetClientsWithCountersQuery,
    useChangeClientMutation,
    useGetDashboardDataClientsQuery,
    useGetClientDataForCurrentUserQuery
} = clientApi
