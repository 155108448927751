import React from 'react';

import {
  Stack,
  Card
} from '@mui/material';
import Page from "../../../../components/Page";
import NotificationsForm from "./NotificationsForm"






const  Notifications: React.FC = () => {

  return (
    <Page title="Уведомления">

      <Card sx={{minHeight: '100px'}}>
        <Stack direction={'column'} alignItems={'left'} sx={{p: 3}}>
          {/*<Typography variant="h2">Уведомления</Typography>*/}
          <NotificationsForm/>
        </Stack>
      </Card>

    </Page>

  );

}

export default Notifications
