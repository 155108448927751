import {Form} from "react-final-form";
import {makeRequired, makeValidate, showErrorOnBlur, TextField} from "mui-rff";
import * as Yup from 'yup';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
  Stack,
  Box, Alert
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useSnackbar} from "notistack";
import {FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION} from "../../../defs/validation";
import {FFORMS_SUBSCRIPTION} from "../../../defs/forms";
import {FieldName, FormData} from "./types";
import {canStepNextInWizard} from "../../../utils/forms";
import {useResetPasswordMutation} from "../../../api/User/User";
import {PATH_DASHBOARD} from "../../../routes/path";
import {getErrorMsgByErrorForResetPassword} from "../utils";
import {DOMAIN_FOR_RESET_PASSWORD_LINKS_GENERATION_ON_THE_BACKEND} from '../../../api/constants'
import {IResetPasswordProps} from '../../../api/User/types'
import GoBackLink from "../../../components/GoBackLink";



Yup.setLocale(FORM_FIELDS_VALIDATION_ERRORS_LOCALIZATION)

const schema: Yup.SchemaOf<FormData> = Yup.object().shape({
  [FieldName.email]: Yup.string().email().required()
})

const validate = makeValidate(schema)
const required = makeRequired(schema)

const ResetPasswordForm: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
      const [
    resetPassword,
    {
      error: resetPasswordError,
      isError: resetPasswordIsError,
      isLoading: resetPasswordIsLoading
    }
  ] = useResetPasswordMutation()

  const fields = [
    <TextField
      fullWidth
      label='Email'
      type='email'
      name={FieldName.email}
      required={required.email}
      size='small'
      showError={showErrorOnBlur}
    />,
  ]

  const onSubmit = async (values: FormData) => {
    console.log('resetPassword req')
    // navigate(PATH_AUTH.otpEnter, { replace: true })
    const dataForSendToAPI: IResetPasswordProps = {
      email: values[FieldName.email],
      domain: DOMAIN_FOR_RESET_PASSWORD_LINKS_GENERATION_ON_THE_BACKEND
    }
    console.log('resetPassword.onSubmit', dataForSendToAPI)
    const returnedFromAPIVal = await resetPassword(dataForSendToAPI)
    console.log('resetPassword.obSubmit.result', returnedFromAPIVal)
    if ('error' in returnedFromAPIVal) {
      console.log('resetPassword.onSubmit.error')
    } else {
      console.log('resetPassword.onSubmit.success')
      enqueueSnackbar('На электронную почту отправлено письмо со ссылкой для сброса пароля', {
        variant: 'success',
        autoHideDuration: 5000
      });
      navigate(PATH_DASHBOARD.general.dashboard, {replace: false})
    }

  }



  return <Form<FormData>
    onSubmit={onSubmit}
    validate={validate}
    subscription={FFORMS_SUBSCRIPTION}
    render={(props) => {
      return <form
        onSubmit={props.handleSubmit}
        noValidate
      >

        <Stack spacing={3}>
          {(resetPasswordError || resetPasswordIsError) &&
            <Alert severity="error">{getErrorMsgByErrorForResetPassword(resetPasswordError)}</Alert>}
          {fields.map((field) => (
            <div
              key={field.props.name}
              // xs={12}
            >
              {field}
            </div>
          ))}
        </Stack>

        <Box  sx={{my: 2}}>
          <GoBackLink/>
        </Box>
        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={resetPasswordIsLoading}
          disabled={
            !canStepNextInWizard(props)
          }

        >
          Сбросить
        </LoadingButton>
      </form>
    }}
  />

}
export default ResetPasswordForm
