import React from "react";
import {Card, CardActionArea, Skeleton, Typography, Stack} from '@mui/material';
import {styled} from "@mui/material/styles";

interface IRequestsTableViewRowSkeletonProps {
}

const CardActionAreaRequestsTableStyled = styled(CardActionArea)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1.5, 1, 1.5, 1),
}));

const RequestsTableViewRowSkeleton: React.FC<IRequestsTableViewRowSkeletonProps> = () => {

  return (
    <Card sx={{mb: 2}}>
      <CardActionAreaRequestsTableStyled>
        <Stack direction={"row"} width={'100%'}>
          <Typography variant={'body1'} width={'100%'}>
            <Skeleton width={'100%'}/>
          </Typography>
        </Stack>
      </CardActionAreaRequestsTableStyled>
    </Card>
  );
}
export default RequestsTableViewRowSkeleton