import {useTheme} from "@mui/material/styles";
import {Box, Stack, Typography} from "@mui/material";
import PriorityChip from "./PriorityStatusesChips/PriorityChip";
import {isDefined} from "../../../utils/object";
import {formatDateTask} from "./Requests";
import {ITaskInApi} from "../../../api/Task/types";

type RequestCardProps = {
    task: ITaskInApi,
};

export default function RequestCard({task}: RequestCardProps) {
    const theme = useTheme()

    return (
        <>
            <Stack width={1} spacing={1} direction={'row'} flexWrap={'wrap'} alignItems={'center'}>
                <Box sx={{
                    color: theme.palette.text.secondary,
                    pl: 1,
                    pt: 0.5,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }}>Заявка №{task.id}</Box>
                <PriorityChip sx={{pt: 0.5,}} priority={task.priority}/>
            </Stack>

            <Typography
                variant="body1"
                sx={{
                    pl: 1,
                    pt: 1,
                    pb: 1,
                    transition: (theme) =>
                        theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.shortest
                        }),
                }}
            >
                {task.title}
            </Typography>

            <Box sx={{color: theme.palette.text.secondary, pl: 1, pt: 1, pb: 0.5}}>
                {
                    isDefined(task.createdAt)
                        ? <span>{formatDateTask(task.createdAt)}</span>
                        : <span>Не определено</span>
                }
            </Box>
        </>
    );
}