import {BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError} from "@reduxjs/toolkit/query/react";

import {loggedOut} from "src/redux/User/actions";
import {BASE_URL} from "./constants";


const baseQuery = fetchBaseQuery({baseUrl: BASE_URL})
export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.status === 401) {
        // TODO: just to learn about api.util.*
        // @ts-ignore Property 'util' does not exist on type 'BaseQueryApi'
        // api.dispatch(api.util.updateQueryData(
        //     'getUser', undefined, (draft: any) => {
        //         draft.data = undefined
        //     }
        // ))
        api.dispatch(loggedOut())
    } else if (
        result.error
        && (result.error.status < 600)
        && (500 <= result.error.status)
    ) {
        result.error = 'Ошибка сервера' as any
    } else if (
        result.error?.status === 'FETCH_ERROR'
    ) {
        result.error = 'Подключение отсутствует' as any
    }
    console.log(result)
    return result
}

export const emptySplitApi = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: () => ({}),
})
