import React, {useMemo, useState} from "react";
import {Box, Stack, Typography, Card, TablePagination, Collapse} from '@mui/material';
import {TransitionGroup} from 'react-transition-group';
import FlipMove from "react-flip-move";
import {ITaskInApi} from "src/api/Task/types";
import RequestsTableViewRow from "./RequestsTableViewRow";
import RequestsTableViewRowSkeleton from "./RequestsTableViewRowSkeleton";


interface IRequestsTableViewProps {
  tasks: ITaskInApi[],
  isError?: boolean,
  error?: any,
  isLoadingData?: boolean,
  showDisplayRequestModal?: Function
}

const RequestsTableView: React.FC<IRequestsTableViewProps> = ({
                                                                tasks,
                                                                isError,
                                                                error,
                                                                isLoadingData,
                                                                showDisplayRequestModal
                                                              }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const slicedTasksList = useMemo(()=>{
    return tasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }, [tasks, page, rowsPerPage ])
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(parseInt(event.target.value, 10))
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <Box>
      {
        isError ?
          <Box>
            <Card sx={{minHeight: '100px'}}>
              <Stack direction={'row'} alignItems={'center'} sx={{p: 3}}>
                <Typography variant="body1">Произошла ошибка загрузки данных
                  заявок: {JSON.stringify(error)}</Typography>
              </Stack>
            </Card>
          </Box>
          :
          isLoadingData ?
            <Box>
              {Array.from(Array(rowsPerPage).keys()).map((count) => {
                return (
                  <RequestsTableViewRowSkeleton key={count}/>
                );
              })}
            </Box>
            :
            <Box>
              {slicedTasksList.length !== tasks.length ?
                <TransitionGroup>
                  {slicedTasksList.map((task) => {
                    return <Collapse key={task.id}><RequestsTableViewRow
                      showDisplayRequestModal={showDisplayRequestModal}
                      key={task.id} task={task}/></Collapse>
                  })}
                </TransitionGroup>
                :
                <FlipMove>
                  {tasks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((task) => {
                    return <Box key={task.id}><RequestsTableViewRow showDisplayRequestModal={showDisplayRequestModal}
                                                                    key={task.id} task={task}/></Box>
                  })}
                </FlipMove>
              }
              <TablePagination
                rowsPerPageOptions={[{value: 5, label: '5'}, {value: 10, label: '10'}, {
                  value: 25,
                  label: '25'
                }, {value: tasks.length, label: 'Все'}]}
                component="div"
                count={tasks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, page) => setPage(page)}
                onRowsPerPageChange={(e) => handleChangeRowsPerPage(e as React.ChangeEvent<HTMLInputElement>)}
                labelRowsPerPage=''
                labelDisplayedRows={
                  ({from, to, count}) => {
                    return '' + from + '-' + to + ' из ' + count
                  }}
              />
            </Box>
      }

    </Box>
  );
}
export default RequestsTableView