import React from "react";
import {Box, Grid, Stack, Alert, Typography, Skeleton} from "@mui/material";
import Edit from '@mui/icons-material/Edit';
import {useSnackbar} from "notistack";
import {Form} from "react-final-form";
import {LoadingButton} from '@mui/lab';
import {FFORMS_SUBSCRIPTION} from "src/defs/forms";
import {canStepNextInWizard} from "src/utils/forms";
import {FormData} from "../../formFields/ClientFields/types";
import UserGeneralFormFields, {validate} from "../../formFields/ClientFields/UserGeneralFormFields";
import {useChangeClientMutation, useGetClientDataForCurrentUserQuery, useGetClientQuery} from "../../../../api/Client/Client";

const UserGenralFormFields: React.FC<{}> = () => {
    const { enqueueSnackbar } = useSnackbar();

    const {
        // data: clientDataForCurrentUser,
        isFetching: isFetchingCrrentUserClientId
    } = useGetClientDataForCurrentUserQuery(undefined, {refetchOnMountOrArgChange: true})

    const {
        data,
        error,
        isError,
        isLoading,
    } = useGetClientQuery(undefined, {
        skip: isFetchingCrrentUserClientId,
        refetchOnMountOrArgChange: true,
    })

    const [
        changeClient,
        {
            data: dataAfterChange,
            isLoading: isChanging,
        },
    ] = useChangeClientMutation()

    const onSubmit = async (values: FormData) => {
        const returnedFromAPIVal = await changeClient(values)
        if ('error' in returnedFromAPIVal) {
            console.log('Client.onSubmit.error')
             enqueueSnackbar('Ошибка при сохранении данных', {variant: 'error', autoHideDuration: 2000});
        } else {
            enqueueSnackbar('Данные успешно изменены', {variant: 'success', autoHideDuration: 2000});
            console.log('Client.onSubmit.ok')
        }

    }

    return (
        <div>
            {
                (isLoading || isFetchingCrrentUserClientId) ?
                  <Box>
                      <Typography variant="h1"><Skeleton/> </Typography>
                      <Typography variant="h1"><Skeleton/> </Typography>
                      <Typography variant="h1"><Skeleton/> </Typography>
                      <Typography variant="h1"><Skeleton/> </Typography>

                  </Box>
                  :
                              <Form<FormData>
                initialValues={
                    dataAfterChange
                        ? dataAfterChange.data
                    : data
                        ? data.data
                    : undefined
                }
                onSubmit={onSubmit}
                validate={validate}
                subscription={{
                    ...FFORMS_SUBSCRIPTION,
                    values: true,
                    dirty: true,
                }}
                render={(props) => {
                    const {
                        dirty,
                        handleSubmit,
                    } = props


                    return (<form
                        onSubmit={handleSubmit}
                        noValidate
                    >
                        {isError
                            // @ts-ignore
                            && <Alert sx={{mb: 2}} severity="error">Произошла ошибка {error?.status}</Alert>
                        }
                        <div>
                            <UserGeneralFormFields disableFields={isChanging} />
                        </div>
                          <Box  sx={{mt: 3}}>
                              <Grid container direction="row-reverse">
                                  <Stack direction="row" alignItems="center">
                                      {dirty && <>
                                          <div>Есть изменения</div>
                                          <Edit fontSize='small'/>
                                      </>}
                                      <LoadingButton
                                        sx={{ml: 1}}
                                        variant='contained'
                                        color='primary'
                                        type='submit'
                                        loading={isChanging}
                                        disabled={
                                          !canStepNextInWizard(props)
                                          || !dirty
                                        }
                                      >
                                          Сохранить
                                      </LoadingButton>
                                  </Stack>

                              </Grid>

                          </Box>
                    </form>
                    )
                }}
            />}
        </div>
    )
}

export default UserGenralFormFields
