import React from 'react';
import CreateNewRequestForm from "./CreateNewRequestForm";
import StyledDrawer from "../../../components/StyledDrawer";


interface CreateNewRequestModalProps {
  showingModal: boolean,
  modalClose: Function,
  refetchOnMountOrArgChangeLocationsAndCpesData: boolean
  refetchRequestsData?: Function,
  cpeId?: number,
}


const CreateNewRequestModal: React.FC<CreateNewRequestModalProps> = ({showingModal, modalClose, refetchOnMountOrArgChangeLocationsAndCpesData=true, refetchRequestsData, cpeId}) => {
  return (
    <StyledDrawer showingModal={showingModal} modalClose={modalClose}>
      <CreateNewRequestForm
          refetchOnMountOrArgChangeLocationsAndCpesData={refetchOnMountOrArgChangeLocationsAndCpesData}
          cpeId={cpeId}
          refetchRequestsData={refetchRequestsData
              ? refetchRequestsData
              : undefined
          }
          modalClose={modalClose}
      />
    </StyledDrawer>
  )

}
export default CreateNewRequestModal
