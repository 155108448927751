import {Container, Grid} from '@mui/material';
import React, {useState} from "react";
import useSettings from '../../../external/minimal/hooks/useSettings';
import Page from '../../../components/Page';
import StatusBlock from "./StatusBlock/StatusBlock";
import {StatusesOfDevices} from "./types";
import OpenStreetMapCard from "../../../components/Maps/OpenStreetMapCard/OpenStreetMapCard";


const Devices: React.FC = () => {
  const {themeStretch} = useSettings();
  const [selectedTypeOfDevicesStatus, setSelectedTypeOfDevicesStatus] = useState<StatusesOfDevices>(StatusesOfDevices.ALL);

  const selectTypeOfDevicesStatusHandler = (typeOfSelectedTypeOfDevicesStatus: StatusesOfDevices) => {
    if (typeOfSelectedTypeOfDevicesStatus === selectedTypeOfDevicesStatus || typeOfSelectedTypeOfDevicesStatus === undefined) {
      setSelectedTypeOfDevicesStatus(StatusesOfDevices.ALL)
    } else {
      setSelectedTypeOfDevicesStatus(typeOfSelectedTypeOfDevicesStatus)
    }
  }

  return (
    <Page title="Устройства">
      <Container maxWidth={themeStretch ? false : 'xl'}>


        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <StatusBlock selectTypeOfDevicesStatusCallback={selectTypeOfDevicesStatusHandler}/>
          </Grid>
        </Grid>
        <Grid container sx={{mt: 1}} spacing={3} >
          <Grid item xs={12} md={12} sx={{minHeight: '65vh'}}>
            <OpenStreetMapCard showLocationsWithNoCpesAsAllOk selectedDevicesStatus={selectedTypeOfDevicesStatus}/>
            {/*<Typography variant={'h3'}>Тут будет карта отфильтрованая для  {selectedTypeOfDevicesStatus} устройств</Typography>*/}
          </Grid>
        </Grid>
      </Container>

    </Page>
  );
}
export default Devices
