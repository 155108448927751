export enum RequestsViewType {
  TABLE = 'table',
  BOARD = 'board'
}

export enum RequestsPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH'
}

export enum RequestsType {
  MAKE_BETTER = 'MAKE_BETTER',
  NETWORK_PROBLEMS = 'NET_PROBLEMS',
  ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS',
  ADD_NEW_CPE = 'ADD_NEW_CPE',
  OTHER = 'OTHER'
}


export enum SortType {
  BY_TITLE = 'byTitle',
  BY_ID = 'byId',
  BY_CREATED_AT = 'byCreatedAt',
  BY_UPDATED_AT = 'byUpdatedAt',
  BY_STATUS = 'byStatus',
  BY_PRIORITY = 'byPriority'
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

export enum FieldName {
    title = 'title',
    text = 'text',
    assignedToCpeId = 'assignedToCpeId'

}

export interface FormData {
    [FieldName.title]: string
    [FieldName.text]: string
    [FieldName.assignedToCpeId]: number | undefined
}